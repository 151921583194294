
import AutoCard from "pages/onboarding/OptionsCards/AutoCard";
import BottomSpacer from "components/uicomponents/BottomSpacer";
import { BudgetContextTwo } from "contexts/BudgetContextTwo";
import BudgetEvenLogo from '../landingpage/landingimages/budgeteven.svg'
import { Grid,Button} from '@mui/material';
import React,{useContext} from 'react'
import { useNavigate,Navigate} from "react-router";

export default function UpdatedPricingPage(){


    const navigate = useNavigate();
    const {isAuthenticated} = useContext(BudgetContextTwo)


     if(isAuthenticated){
      return (
        <Grid container direction="column" justifyContent='center' alignContent='center' mb={25}>

         <Grid container direction='row' justifyContent='center' mt={15}>
         <Grid item>
         <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
         </Grid>
         </Grid>

       <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:15}}>
    <AutoCard handlePurchase={() => navigate('../checkout')}  />
       </div>
      
       <Grid container direction='row' justifyContent='center'>
         <Grid item>
          <Button variant="contained" onClick={() => navigate('../checkout')}>Subscribe $4.99 per month</Button>
         </Grid>
      </Grid>

      <BottomSpacer />

     </Grid>
     )
     }
     else if(!isAuthenticated){
      return(
        <Navigate to='/' replace={true} />
      )
    }

    
      
     
      
        
    
 
  
  
}