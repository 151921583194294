import BudgetContextTwoProvider from "contexts/BudgetContextTwo";
import { budgetTheme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {Elements} from '@stripe/react-stripe-js';
import { Helmet } from "react-helmet";
import {loadStripe} from '@stripe/stripe-js';
import routes from "./routes";
import { StyledEngineProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import { testMode } from "utils/GlobalConstans";
import { AltDataProvider } from "contexts/AltDataContext";


//maybe make a floating calculator on web so you can tell $12k- what etc.
//check the account aggregation dates and if they aren't up to date, refresh accounts in the background
//revisit the onboarding steps and the differences between mobile and web
//revisit selecting a custom line item when onboarding
//keep patrick@budgeteven and then create a separate test account
//add more loading messages to the web onboarding
//revisit when tracking transactions that it scans similar payees to track
//make a better loading screen, maybe with some rive animations
//fix the description column on the cash flow web

//const stripePromise = loadStripe('pk_live_9Jl79qB8plEOK32AKBCYWcVc00bwjBcger')

const stripePromise = testMode ? loadStripe('pk_test_9mYyFvIKISb1MhUlTf4M0bVn00LnEcwL5p') : loadStripe('pk_live_9Jl79qB8plEOK32AKBCYWcVc00bwjBcger')


//*****REVIEW******* */
//The userObject created with web and mobile for handling the tutorials
//And the steps

//*****CASEY OLD *****/
//9ECGMuSbWJYZuXFojEhoa8BVbR83
//5024526242

//******NEW TEST ACCOUNTS***** */
//p_live_semi_test@budgeteven.com
//p_live_auto_test@budgeteven.com
//m_live_semi_test@budgeteven.com


//******CHECKS***** */
//make sure test mode is off OnBoardCheckout.js UpdatedCheckOutInterior.js
//make sure stripe is live


//bugs
//Might need to automatically change the spent value to reflect the transactions recorded (happened on ATM transfers)
//Make an option for new transactions to delete once, or auto-delete
//Reach out to Finicity about account view 2.0

//TO DO
//Make a separate route for upgrading and downgrading subscriptions
//Review the Stripe cancellation process
//Update the loading animations and stuff

const App = () => {


  const allPages = useRoutes(routes);
  const appTheme = budgetTheme({theme: "light",direction: "ltr",responsiveFontSizes: true})
  const toasterOptions = {style: {fontWeight: 500,fontFamily: "'Montserrat', sans-serif"}}

  return <StyledEngineProvider injectFirst>

          <Helmet>
            <title>Budget Even</title>
            <meta name='description' content='Welcome to the Budget Even landing page.  Budget Even offers a simple and correct way to plan
            your monthly budget.  You can create and customize your own individual budget groups and budget line items.  Track your expenses by
            adding transactions manually, or sign up for a premium account to automatically download transactions from your credit cards and other
            financial accounts.  Premium users can also simplify their budget and transaction tracking by creating Auto-track rules.  Simply select a 
            transaction, select a budget group and budget line item, and save the rule.  With a rule saved, you can simply click to have our software do
            the heavy lifting and transaction tracking for you.  Further, you can keep track of your net worth, plan your cash flow, and analyze your budget.  
            Budget Even offers a number of tools to help you keep your Budget Even, and pave the way to sustainable monthly savings.  Budget Even is also
            available on the App Store, and the Google Play Store for budgeting on the go. '>
            </meta>
            <meta name='keywords' content='Budget Even, Monthly Budget Planner, Cash flow planning, Net worth tracking, Transaction Tracking'/>
          </Helmet>
      <ThemeProvider theme={appTheme}>
          <BudgetContextTwoProvider>
          <AltDataProvider>

        
              <Elements stripe={stripePromise}>
                <CssBaseline />
                  <Toaster toastOptions={toasterOptions} />
                      {allPages}
                </Elements>
                </AltDataProvider>
          </BudgetContextTwoProvider>
        </ThemeProvider>
    </StyledEngineProvider>

   
};

export default App;