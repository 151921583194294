

import { Backdrop,useTheme,Grid } from '@mui/material';
import { Ellipsis } from 'react-spinners-css';
import { getAuth } from 'firebase/auth';
import { getFirestore,updateDoc,arrayUnion,doc } from 'firebase/firestore';
import { getFunctions,httpsCallable } from 'firebase/functions';
import { useNavigate,useLocation } from 'react-router';
import React, { useEffect, useState } from 'react'
import { getCurrentMonthAndYearString,updatedGetLastMonthAndYearString } from 'utils/Helpers';

const db = getFirestore();
const functions = getFunctions();

export default function NoBudgetPagePremium({route,navigation}) {
    

    const theme = useTheme()
    const navigate = useNavigate();
    const location = useLocation();

    const [emptyBudgetMonth,setEmptyBudgetMonth] = useState(location.state.emptyBudgetMonth)
  


    useEffect(() => {
        createNewBudget()
    },[])


    async function createNewBudget(){

        let monthsWithBudgets = location.state.localMonthsArray
        let currentMonth = getCurrentMonthAndYearString();
        let lastMonth =  updatedGetLastMonthAndYearString();
        let currentMonthIndex = monthsWithBudgets.findIndex(obj => obj === currentMonth)
        let lastMonthIndex = monthsWithBudgets.findIndex(obj => obj === lastMonth)

      

   
       
            
            if(currentMonthIndex !== -1){
                //copy currentMonthIndex
                copyMonthNoExistingPods(currentMonth)
            }
            if(currentMonthIndex === -1 && lastMonthIndex !== -1){
                copyMonthNoExistingPods(lastMonth)
            }
            else {
           
                const lastMonthAvailable = monthsWithBudgets[monthsWithBudgets.length - 1];
                copyMonthNoExistingPods(lastMonthAvailable)
            }
        
        



    }


    async function copyMonthNoExistingPods(monthSelected){
        let uid = getAuth().currentUser.uid;
        let copyMonth = httpsCallable(functions,'copyBudgetFromAnotherMonth');
      
        let copyResult = await copyMonth({monthToCopy: monthSelected, newMonth: emptyBudgetMonth}).then(function(result) {
           
          return result;
        }).catch(function(error){
      
          return error;
        })
         
    
        if(copyResult !== false){
    
        let docRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
        await updateDoc(docRef,{mArray: arrayUnion(emptyBudgetMonth)})
        // await firestore().collection('EuklesUsersData').doc(uid).collection('MonthsWithBudgets').doc('months').update({
        //   mArray: firestore.FieldValue.arrayUnion(emptyBudgetMonth)
        // }).then(res => {
        //   return true;
        // }).catch(e => {
        //   return false;
        // })
      
     
        navigate('/dashboard/budgethome',{state:{needsToRefresh: false,connectionsErrorsArray: []}})
        }
       
    }












        return(
            <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
            <Grid container direction='column'  justifyContent='center' alignItems='center'>
              <Grid item>
              <Ellipsis color={theme.palette.primary.main} />
              </Grid>
          
            </Grid>
            
            
            </Backdrop> 
        )
      
    
  
}