import {doc, getFirestore,setDoc,getDoc,updateDoc} from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getFunctions,httpsCallable } from 'firebase/functions';
import { generateRandomString,getCurrentMonthAndYearString } from "./Helpers";
import { monthStringsList,getCurrentDayDigit } from "./Helpers";
import { uploadError } from "./FirebaseAnalyticsMethods";

const TAG = 'UserMethods.js'

export async function checkOnboardingStatusHelper(){
     
    try{
        const db = getFirestore();
        const topUserUid = getAuth().currentUser.uid;
        let docRef = doc(db,'EuklesUsers',topUserUid)
                  
        let onBoardingResult = await getDoc(docRef).then((doc) => {
    
          if(doc.exists){
    
          let isUpdatedApp = false;
          let isPremiumUser = doc.data().isPremiumUser
          let finicityId = doc.data().finCustomerId;
          let lastScanDigit = 'nil'
    
          if(typeof doc.data().isUpdatedApp !== 'undefined'){
            isUpdatedApp = doc.data().isUpdatedApp
          }
    
          if(typeof doc.data().lastScanDigit !== 'undefined'){
            lastScanDigit = doc.data().lastScanDigit
          }
    
          let currentStep = '-47'
          if(typeof doc.data().currentStep !== 'undefined'){
            currentStep = doc.data().currentStep
          }
          let lastTransactionScan = 'nil'
          if(typeof doc.data().lastTransactionScanEpoch !== 'undefined'){
            lastTransactionScan = doc.data().lastTransactionScanEpoch
          }
    
          let budgetMethod = 'Manual'
          if(typeof doc.data().budgetMethod !== 'undefined'){
            budgetMethod = doc.data().budgetMethod
          }
    
          let autoCurrentStep = 'nil'
          if(typeof doc.data().autoCurrentStep !== 'undefined'){
           autoCurrentStep = doc.data().autoCurrentStep
          }
    
          return {currentStep: currentStep,autoCurrentStep:autoCurrentStep,
             budgetMethod: budgetMethod, isUpdatedApp: isUpdatedApp,lastScanDigit: lastScanDigit, error: false, docExists:true, errorMessage: '',isPremiumUser: isPremiumUser, 
          finicityId: finicityId, lastTransactionScanEpoch:lastTransactionScan}
          }
          else {
            return false;
          }
        }).catch((err) => {
      
          return false;
        })
    
        return onBoardingResult;
    }catch(e){
        uploadError(e.message,`${TAG}, checkOnboardingStatusHelper`)
    }
 
}

export async function createFinCustomerIdHelper(){

    try{
        const functions = getFunctions();

        let createFinMethod = httpsCallable(functions,'createFinCustomer');
        let userName = generateRandomString(12)
        let customerId = await createFinMethod({username: userName}).then((res) => {
             return res.data.customerId;
        }).catch((err) => {
            return 'error';
        });
    
        return customerId;
    }catch(e){
        uploadError(e.message,`${TAG}, createFinCustomerIdHelper`)
    }
  
                
  
}


export async function createMonthlySavingsGoal(){
    try{
        const db = getFirestore();
        const userUid = getAuth().currentUser.uid;
        let mDocRef = doc(db,'EuklesUsersData',userUid, 'MonthlySavingsGoal', 'goal');

        let create = await setDoc(mDocRef,{value: 100.00}).then((res) => {
            return true;
        }).catch((e) => {
            return false;
        })
        return create;
    }catch(e){
        uploadError(e.message,"function is createMonthlySavingsGoal, file is UserMethods.js")
    }
   
}

export async function createNewUserObject(){

    try{
  
        let canSendMessage = false
        let currentDay = getCurrentDayDigit();
        let currentEpoch = Math.floor(Date.now()/1000);
        let date = new Date();
        let db = getFirestore();
        let fcmTokenArray = []
        let month = date.getMonth();
      
        let plat = 'web'
        let topUserUid = getAuth().currentUser.uid;
        let user = getAuth().currentUser;
        let year = date.getFullYear();
    
        let monthYearString = monthStringsList[month] + ' ' + year;

         let userObject = {
           
             budgetMethod:'Manual',
             completedOnboarding: false,
             completedBudgetToolTips:false,
             completedMobileTransactionTips:true,
             creationTime: user.metadata.creationTime,
             creationEpoch: currentEpoch,
             currentStep:0,
             email: user.email,      
             isPremiumUser: false,
             isUpdatedApp:true,
             fcmTokens: fcmTokenArray,       
             finCreationDate:'nil',
             finCustomerId: 'nil',
             finCustomerUrl: 'nil',
             finUrlUpdateTime: 'nil',
             lastScanDigit: currentDay,
             lastSignInEpoch: currentEpoch,       
             lastSignInTime: user.metadata.lastSignInTime, 
             lastTransactionScanEpoch:'nil',
             noCashFlowSet:true,
             notificationsAllowed:canSendMessage,     
             signUpPlatForm: plat,
             startDate: monthYearString,      
             subscribed: false,  
             subscriptionMethod:'none',      
             userUid: user.uid,
             updatedAccountsFormat:true,
             viewedNewUserAlert: false
     
             
         }
     
        
      
     let docRef = doc(db,'EuklesUsers',topUserUid)
     let creation =  await setDoc(docRef,userObject).then(res => {
        return {error: false, errorMessage:''}
    }).catch((e) => {
        return {error: true, errorMessage: e}
    })

    
    
    
     let altDoc = doc(db,'EuklesUsersData',topUserUid)
     await setDoc(altDoc,{userUid: topUserUid})
     
     

     
     return creation;
     
    }catch(e){
        uploadError(e.message,"function is createNewUserObject, file is UserMethods.js")
    }

 
  
 }


 //remove this later since replacing with getUserProfile. Note was made on 9/03/2024
 export async function getUserData(){
    const db = getFirestore();
    const userUid = getAuth().currentUser.uid;
    let docRef = doc(db,'EuklesUsers',userUid);
    let userObject =  await getDoc(docRef).then(doc => {
        return doc.data()
    }).catch(e => {
        return false
    })
    return userObject;
 }

 export async function getUserProfile(){
    const db = getFirestore();
    const userUid = getAuth().currentUser.uid;
    let docRef = doc(db,'EuklesUsers',userUid);
    let userObject =  await getDoc(docRef).then(doc => {
        return doc.data()
    }).catch(e => {
        return false
    })
    return userObject;
 }


 export async function handleAddContactToSendGrid(){

    try{
        let functions = getFunctions();
        let email = getAuth().currentUser.email;
     
        let addContactToSendGrid = httpsCallable(functions,'addContactToSendGrid');
      let addContact =  await addContactToSendGrid({email:email}).then(function(result){
      
            return true
            }).catch(function(error){
          
                return false
            });
      
      
       return addContact
        
    }catch(e){
        uploadError(e.message,'UserMethods.js, handleAddContactToSendGrid')
    }

  
  }


  export async function updateUserDocument(userObject){

    try{
        const db = getFirestore();
        const topUserUid = getAuth().currentUser.uid;
        let userDoc = doc(db,'EuklesUsers',topUserUid)
        const userUpdated = await updateDoc(userDoc,userObject).then(res => {
            return true;
        }).catch(e => {
            return false;
        })
        return userUpdated;
    }catch(e){
        uploadError(e.message,`${TAG}, updateUserDocument`)
    }
 
  }

  export async function handleSetUpNewUser(budgetMethod,finCustomerId,isPremium){
     
    let uid = getAuth().currentUser.uid;
    const db = getFirestore();
    let monthYear = getCurrentMonthAndYearString();

    let mArray = [monthYear]
    let monthDocRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
    await setDoc(monthDocRef,{mArray})

    let monthlySavingSGoal = doc(db,'EuklesUsersData',uid,'MonthlySavingsGoal','goal')
    await setDoc(monthlySavingSGoal,{value:100})

    let userDocRef = doc(db,'EuklesUsers',uid)
    await updateDoc(userDocRef,{
      onBoardingSelection: budgetMethod,
      currentStep:-47,
      finCustomerId:finCustomerId,
      budgetMethod:budgetMethod,
      uid: uid,
      isPremiumUser:isPremium,
      noCashFlowSet: true,
      queryMonth: monthYear
    })
  

    let incomeDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Income')
    await setDoc(incomeDocRef,{
      ['Paychecks']: {
        plannedValue:0.00,
        spentValue:0.00,
        podName:'Income'
    }
    })

    let savingsDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Savings')
    await setDoc(savingsDocRef,{
      ['Savings']: {
        plannedValue:0.00,
        spentValue:0.00,
        podName:'Savings'
    }
    })

    let foodDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Food')
 
      await setDoc(foodDocRef,{
        ['Groceries']: {
          plannedValue:0.00,
          spentValue:0.00,
          podName:'Food'
      },
      ['Dining Out']: {
        plannedValue:0.00,
        spentValue:0.00,
        podName:'Food'
    }
      })

      let transportationDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Transportation')
 
      await setDoc(transportationDocRef,{
        ['Car Payment']: {
            plannedValue:0.00,
            spentValue:0.00,
            podName:'Transportation'
        },
        ['Insurance']: {
          plannedValue:0.00,
          spentValue:0.00,
          podName:'Transportation'
      },
      ['Gas']: {
        plannedValue:0.00,
        spentValue:0.00,
        podName:'Transportation'
    }
      })

      let housingDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Housing')
 
      await setDoc(housingDocRef,{
        ['Mortgage']: {
            plannedValue:0.00,
            spentValue:0.00,
            podName:'Housing'
        },
        ['Insurance']: {
          plannedValue:0.00,
          spentValue:0.00,
          podName:'Housing'
      },
      ['Maintenance']: {
        plannedValue:0.00,
        spentValue:0.00,
        podName:'Housing'
    },
  
      })

      return true;
    
      
  }


