import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTheme,Typography} from "@mui/material";




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDeleteModal({handleDelete,handleCancel}) {
 
        const theme = useTheme();



  return (
    <div>

      <Dialog
        open={true}
        TransitionComponent={Transition}
     
      
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title" 
                                    style={{textAlign:'center', overflowX:'hidden', overflowY:'hidden', 
                                    backgroundColor: theme.palette.primary.dialogBackground, color:'white', fontWeight:'bold', maxHeight:120}}>
                                              
                                    
            <Typography variant='h4' style={{fontWeight:'700'}}>Remove Account</Typography>

            </DialogTitle>
          
            <DialogContent style={{display:'flex', paddingTop:25, flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
                <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>Are you sure you want to remove this account?</Typography>
                <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>Tap below to continue to the Finicity Portal to remove the account.</Typography>

                <Button onClick={() => handleDelete()} variant='outlined' style={{backgroundColor: theme.palette.primary.main, marginTop:25, width: 200, color:'white', fontWeight:'bold'}}    
>
Yes
</Button>
<DialogActions>







<Button onClick={() => handleCancel()} variant='text' style={{ marginTop:25, width: 200, color:'black'}}>    

Cancel
</Button>

</DialogActions>
</DialogContent>

      </Dialog>
    </div>
  )



}