
import Axios from 'axios';
import { getAuth } from "firebase/auth";
import { getCurrentMonthAndYearString } from "./Helpers";
import { getDoc,getFirestore,updateDoc,doc,setDoc } from "firebase/firestore";
import {httpsCallable,getFunctions } from "firebase/functions";
import { uploadError } from "./FirebaseAnalyticsMethods";
import { getUserData, updateUserDocument } from './UserMethods';


export async function cancelStripeSubscriptionCompletely(){

  const db = getFirestore();
  const userUid = getAuth().currentUser.uid;

  let docRefSub = doc(db,'EuklesUsers',userUid);
  let details = await getDoc(docRefSub).then(doc => {
      return {subscriptionId: doc.data().stripeSubscriptionId,stripeCustomerId: doc.data().stripeCustomerId,finCustomerId: doc.data().finCustomerId}
  })
  
  const customerData = {subscriptionId: details.subscriptionId}

  let stripeCancellation = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/cancelStripeSubscription  ', customerData ).then(res => {
    return res.data;
    }).catch(e => {
      
     return false;
    })
    return stripeCancellation;
}

export async function createFullAutoSubscriptionRevised(){

  try{
    const userUid = getAuth().currentUser.uid;
    const userData = await getUserData();

    const customerData = {customerId: userData.stripeCustomerId, uid: userUid}
    let stripeSubscription = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription', customerData ).then(res => {
  
     return res.data;
     }).catch(error => {
   
       return error;
     })
  
     if(stripeSubscription.encounteredError){
    
      return false
     }
     else if(!stripeSubscription.encounteredError) {
      await updateUserDocument({stripeSubscriptionId:stripeSubscription.subscriptionId})

        return true;
     }
  }catch(e){
    uploadError(e.message,'StripeCancellationProcess,createFullAutoSubscription')
  }

}

export async function createSemiAutoSubscriptionRevised(){

  try{
    const userUid = getAuth().currentUser.uid;
    const userData = await getUserData();

    const customerData = {customerId: userData.stripeCustomerId, uid: userUid}
    let stripeSubscription = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionAnnualPayment', customerData ).then(res => {
  
     return res.data;
     }).catch(error => {
   
       return error;
     })
  
     if(stripeSubscription.encounteredError){
    
      return false
     }
     else if(!stripeSubscription.encounteredError) {
      await updateUserDocument({stripeSubscriptionId:stripeSubscription.subscriptionId,budgetMethod:'Semi-Auto'})

        return true;
     }
  }catch(e){
    uploadError(e.message,'StripeCancellationProcess,createFullAutoSubscription')
  }

}


async function createFullAutoSubscription(customerId){

  try{
    const userUid = getAuth().currentUser.uid;
    const customerData = {customerId: customerId, uid: userUid}
    let stripeSubscription = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionTest', customerData ).then(res => {
  
     return res.data;
     }).catch(error => {
   
       return error;
     })
  
     if(stripeSubscription.encounteredError){
    
      return false
     }
     else if(!stripeSubscription.encounteredError) {
      
   
        return {success:true, subscriptionId: stripeSubscription.subscriptionId}
   
  
       
     }
  }catch(e){
    uploadError(e.message,'StripeCancellationProcess,createFullAutoSubscription')
  }

}

async function deleteFinCustomer(finCustomerId){

  try{
    const userUid = getAuth().currentUser.uid;
    const db = getFirestore();
    const functions = getFunctions();
    let deleteFinMethod = httpsCallable(functions,'deleteFinCustomer');



    await deleteFinMethod({finCustomerId: finCustomerId}).then((res) => {
            
        return res.data.name;
    }).catch((err) => {
        console.log('encountered an error creating customer')
    });
 
    let emptyBudgetMonth = getCurrentMonthAndYearString();
    let docRef = doc(db,'EuklesUsers', userUid)
    await updateDoc(docRef,{finCustomerId:'nil',queryMonth:emptyBudgetMonth})
  }catch(e){
    uploadError(e.message,'StripeCancellationProcess,deleteFinCustomer.js')
  }



}

async function deleteStripeCustomer(stripeCustomerId){

  try{
    const customerData = {customerId: stripeCustomerId}

    let stripeDeletion = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/deleteStripeSubscription  ', customerData ).then(res => {
  
                 return res.data;
                 }).catch(e => {
                   
                   
                  return 'error';
                 })

                 if(stripeDeletion.succesfulCancel){
                
                }
                else if(!stripeDeletion.succesfulCancel){
      
                }
  }catch(e){
    uploadError(e.message,'StripeCancellationProcess, deleteStripeCustomer')
  }

}

export async function downgradeToAutoCancellation(){

  try{
    const db = getFirestore();
    const userUid = getAuth().currentUser.uid;
  
    let docRefSub = doc(db,'EuklesUsers',userUid);
    let details = await getDoc(docRefSub).then(doc => {
        return {subscriptionId: doc.data().stripeSubscriptionId,stripeCustomerId: doc.data().stripeCustomerId,finCustomerId: doc.data().finCustomerId}
    })
    
      const customerData = {subscriptionId: details.subscriptionId}
      let docRef = doc(db,'EuklesUsers',userUid);
      let date = new Date().toISOString().slice(0,10)
      await updateDoc(docRef,{upgradingToAuto:true,date:date})
    
    let stripeCancellation = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/cancelStripeSubscription', customerData ).then(res => {
      return res.data;
    }).catch(e => {
 
     return e;
    })

  return stripeCancellation;
   
  }catch(e){
   
    uploadError(e.message,'StripeCancellationProcess, downgradeToAutoCancellation')
  }

}

export async function getPaymentMethodDetails(paymentMethodId){

  try{
    const customerData = {paymentMethod: paymentMethodId}
    let paymentObject = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/retrievePaymentDetails ', customerData ).then(res => {
                 return res.data;
                 }).catch(error => {
  
                   return error;
                 })
       
                 let four = ''
                 if(typeof paymentObject.paymentDetails.lastFour !== 'undefined'){
                  let s = 'xxxx'
                  four = s.concat(paymentObject.paymentDetails.lastFour);
                 }
                
      return {lastFour: four, cardBrand:paymentObject.paymentDetails.brand}
  }catch(e){
    uploadError(e.message,'StripeMethods.js, getPaymentMethodDtails')
  }
  

}


export async function getSubscriptionDetails(id){
      
  try{
    const customerData = {subscriptionId: id}
    let stripeSubscriptionDetails = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/retrieveSubscriptionDetails ', customerData ).then(res => {
                 return res.data;
                 }).catch(error => {
  
                   return error;
                 })
                 console.log('stripe details ', stripeSubscriptionDetails)
                 let billDateNext = ''
                 
                 if(typeof stripeSubscriptionDetails.nextBillDate !== 'undefined'){
                  billDateNext = new Date(stripeSubscriptionDetails.nextBillDate * 1000).toISOString();
                  billDateNext = billDateNext.slice(0,10)
                 }
  return billDateNext
  }catch(e){
    uploadError(e.message,'StripeMethods.js, getSubscriptionDetails')
  }

}


async function handleStartBudgetFromScratch(){

  try{
    let emptyBudgetMonth = getCurrentMonthAndYearString();
    const db = getFirestore();
    const uid = getAuth().currentUser.uid;
    let docRef = doc(db,'EuklesUsersData',uid,emptyBudgetMonth,'Income')
    await setDoc(docRef,{'Paycheck':{plannedValue:0.00, spentValue:0.00, podName:'Income'}}).then(res => {
      return true;
    }).catch(e => {
      return false
    })
  
  let savingsDoc = doc(db,'EuklesUsersData',uid,emptyBudgetMonth,'Savings')
  await setDoc(savingsDoc,{'Savings': {
      plannedValue: 0.00,
      spentValue: 0.00,
      podName:'Savings'
  }}).then(res => {
      return true;
    }).catch(e => {
     
      return false;
    })

    let goalDoc = doc(db,'EuklesUsersData',uid,'MonthlySavingsGoal','goal')
    await setDoc(goalDoc,{ value: 100.00})
  
      let mArray = [emptyBudgetMonth]
      let monthsRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
      await setDoc(monthsRef,{mArray})
  }catch(e){
    uploadError(e.message,'StripeCancellationProcess, handleStartBudgetFromScratch.js')
  }

  
   
 
    
     }

 async function processStripeCancellationDatabase(){
      try{
        let uid = getAuth().currentUser.uid
        const db = getFirestore();
        let docRef = doc(db,'EuklesUsers', uid);
        await updateDoc(docRef,{
            isPremiumUser: false, subscriptionMethod: 'none', budgetMethod:'Manual',switchedFromAuto:true,
        stripeCustomerId:'nil', stripePaymentMethodId:'nil', stripeSubscriptionId:'nil'
        })
        
      }catch(e){
        uploadError(e.message,'StripeCancellationProcess,processTripeCancellationDatabase.js')
      }
   
  
  
  }

export async function submitCancellationRequest(){

    try{
      const db = getFirestore();
      const userUid = getAuth();
  
      let docRefSub = doc(db,'EuklesUsers',userUid);
      let details = await getDoc(docRefSub).then(doc => {
  
          return {subscriptionId: doc.data().stripeSubscriptionId,stripeCustomerId: doc.data().stripeCustomerId,finCustomerId: doc.data().finCustomerId}
      })
      const customerData = {subscriptionId: details.subscriptionId}
      
  
   
        let docRef = doc(db,'EuklesUsers',userUid);
        await updateDoc(docRef,{tappedCancel:true})
        let docRefStripe = doc(db,'StripeCancellations',userUid);
        let date = new Date().toISOString().slice(0,10)
        await updateDoc(docRefStripe,{stripeCancel:true,date:date})
  
  
      let obj = []
  
      let stripeCancellation = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/cancelStripeSubscription  ', customerData ).then(res => {
    
                   return res.data;
                   }).catch(e => {
                
                     
                    return 'error';
                   })
          if(stripeCancellation.succesfulCancel){
     
           let del = await deleteStripeCustomer(details.stripeCustomerId);
           let process = await processStripeCancellationDatabase();
           let delFin = await deleteFinCustomer(details.finCustomerId);
           let budget = await handleStartBudgetFromScratch();
           obj.push(del,process,delFin,budget)
          }
          else if(!stripeCancellation.succesfulCancel){
        
          }
  
   
  
          return {object:obj,finished:true}
    }catch(e){
      uploadError(e.message,'StripeCancellationProcess, submitCancellationRequest.js')
    }
     
    }



    export async function updateStripePaymentInformation(data){
    
      try{
        let stripeUpdate = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/handleAttachStripePaymentMethod', data).then(res => {
          return res.data;
      
          }).catch(() => {
             
            return false;
          });
  
          return stripeUpdate;
      }catch(e){
        uploadError(e.message,'StripeMethods.js, updateStripePaymentInformation')
      }
  

       
     }

     export async function updateStripeDefaultPaymentMethod(data){

      try{
        await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/handleSetDefaultStripePaymentMethod', data).then(res => {
          return res.data;
      
          }).catch(error => {
            return error;
          });
      }catch(e){
        uploadError(e.message,'StripeMethods, updateStripeDefaultPaymentMethod')
      }
 
     }






export async function upgradeFromSemiAutoToAutoSubscriptionCancelStepOne(){

  try{
    const db = getFirestore();
    const userUid = getAuth().currentUser.uid;
  
    let docRefSub = doc(db,'EuklesUsers',userUid);
    let details = await getDoc(docRefSub).then(doc => {
        return {subscriptionId: doc.data().stripeSubscriptionId,stripeCustomerId: doc.data().stripeCustomerId,finCustomerId: doc.data().finCustomerId}
    })
    
      const customerData = {subscriptionId: details.subscriptionId}
      let docRef = doc(db,'EuklesUsers',userUid);
      let date = new Date().toISOString().slice(0,10)
      await updateDoc(docRef,{upgradingToAuto:true,date:date})
    

  
    let stripeCancellation = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/cancelStripeSubscription', customerData ).then(res => {
      return res.data;
    }).catch(e => {
 
     return e;
    })

  return stripeCancellation;
   
  }catch(e){
    console.log('stripe cancellation catch error ', e.message)
    uploadError(e.message,'StripeCancellationProcess, upgradeFromSemiAutoToAutoSubscription')
  }

}

export async function upgradeFromSemiAutoToAutoSubscription(){

  try{
    const db = getFirestore();
    const userUid = getAuth().currentUser.uid;
  
    let docRefSub = doc(db,'EuklesUsers',userUid);
    let details = await getDoc(docRefSub).then(doc => {
        return {subscriptionId: doc.data().stripeSubscriptionId,stripeCustomerId: doc.data().stripeCustomerId,finCustomerId: doc.data().finCustomerId}
    })
    
      const customerData = {subscriptionId: details.subscriptionId}
      let docRef = doc(db,'EuklesUsers',userUid);
      let date = new Date().toISOString().slice(0,10)
      await updateDoc(docRef,{upgradingToAuto:true,date:date})
    
    let newSubResult = false
    let stripeResult = false
  
  
    let stripeCancellation = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/cancelStripeSubscription', customerData ).then(res => {
      return res.data;
    }).catch(e => {
      console.log('stripe cancellation error ', e)
     return e;
    })
    console.log('stripe cancellation ', stripeCancellation)

  
              
           
  
                 if(stripeCancellation.succesfulCancel){
                  stripeResult = true
                  let sub = await createFullAutoSubscription(details.stripeCustomerId)
                    if(sub.success){
                      newSubResult = true
                     
                    }
                 }
                 else {
                 
                 }
                 
      return {stripeResult: stripeResult,newSubResult: newSubResult}
  }catch(e){
    console.log('stripe cancellation catch error ', e.message)
    uploadError(e.message,'StripeCancellationProcess, upgradeFromSemiAutoToAutoSubscription')
  }

}




