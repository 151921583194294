import { Box,useTheme,AppBar,Container,useMediaQuery,styled,Typography,Toolbar,Grid,CardContent,Card } from '@mui/material';
import BottomShareCallOut from '../components/BottomShareCallOut';
import BottomSpacer from 'components/uicomponents/BottomSpacer';

import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import React,{useEffect} from 'react'
import ShareButtons from '../components/ShareButtons';
import { Small } from 'components/uicomponents/Typography';
import { useNavigate } from "react-router-dom";
import useWindowSize from 'hooks/useWindowSize';



const Wrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  padding: "0 1.5rem",
  paddingTop: "1rem"
}));


export default function HowToStartABudget() {

    const theme = useTheme();
    const MenuList = styled("ul")(({theme}) => ({margin: 0,padding: 0,listStyle: "none",[theme.breakpoints.down("sm")]: { "& a": {display: "block",marginTop: "1rem"}}}));
  
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"))
    
    const { height} = useWindowSize()
    const navigate = useNavigate();

    const Wrapper = styled(Box)(() => ({
      backgroundImage: "url('/static/landing-page/blogpagebackground.svg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "repeat",
      position:'absolute',
      top:0,
      right:0,
      width:'100%',
      height:'100%'
    }));

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    function determineMaxWidth(){
      if(isSmall){
        return 500
      }
      else if(downMd){
        return 500
      }
      else if(isLarge){
        return 800
      }
      else {
        return 800
      }
      
    }

    function determineColumnWidth(){
      return 100
      // if(isSmall){
      //   return 500
      // }
      // else if(downMd){
      //   return 500
      // }
      // else if(isLarge){
      //   return 800
      // }
      // else {
      //   return 800
      // }
      
    }

    function todaysDate(){
      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
    
      const formattedDate = `${month}-${day}`;
      return formattedDate
    }
 
  return (


<div>


    <Wrapper>

             <AppBar position="fixed" sx={{boxShadow: 2,minHeight: 64,justifyContent: "center",backgroundColor: "white"}}>
      <Container>
        <Toolbar sx={{justifyContent: "space-between",pl:0,pr:0}}>
        <div onClick={() => navigate('./')} ><img src={BudgetEvenLogo} width={200} alt="Logo" /></div>
    
              {downMd || height < 400 ? null : <MenuList style={{display:'flex', flexDirection: 'row', padding:15}}>
      

              <Typography    onClick={() => navigate('/login')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Login</Typography>
              <Typography    onClick={() => navigate('/register')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Sign up</Typography>
             <Typography  style={{fontWeight:'bold',marginLeft:10, marginRight:10, color: theme.palette.primary.main, cursor:'pointer'}}>How it works</Typography>
          
              <Typography  style={{fontWeight:'bold',marginLeft:10, marginRight:10, color: theme.palette.primary.main, cursor:'pointer'}}>Pricing</Typography>
              <Typography to="Learn" onClick={() => navigate('/blog')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Learn</Typography>
              <Typography to="Free Tools" onClick={() => navigate('/blog')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Free Tools</Typography>
            </MenuList>}
            </Toolbar>
          </Container>
    </AppBar>

    <Grid container justifyContent='center' display='flex' flexDirection='column' alignContent='center' alignItems='center'> 
    <Grid item>
    <Typography variant='h3' style={{textAlign:"center",fontWeight:'bold',color:'#1A77CD',marginTop:100}}>How to Start a Monthly Budget</Typography>
    </Grid>
  
      <Grid item mt={2} style={{textAlign:'center'}}>
          <Small style={{textAlign:'center'}} justifyContent='center'  mb={3} fontSize={16} fontWeight={600} color="text.disabled">
            Starting a budget can be overwhelming.  Let's break it down into some easy steps.
          </Small>
      </Grid>


    </Grid>

<Container style={{maxWidth:determineMaxWidth()}}>
        <div style={{fontWeight:500,marginTop:25}}>
            Have you ever found yourself asking, where on earth did my money go last month?&nbsp;&nbsp;You're not alone.&nbsp;&nbsp;And for the folks out there with young children, the problem only gets worse.

        </div>

        <div style={{fontWeight:500,marginTop:10}}>
        Luckily, Budget Even is here to help.&nbsp;&nbsp;Forget about connecting outside accounts and syncing transactions for now, let's start with the basics.
          </div>


        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Step One:  <span style={{fontWeight:600,fontSize:14,color:'black'}}>Define your expected income for the upcoming month.</span></span>

        </div>
        <div style={{marginTop:25,fontWeight:500}}>
            Your expected income will be the basis for which you can start planning your monthly budget. &nbsp;If you receive a salary, this should be a pretty easy question to answer.  Just take a look at your bank account from the prior month, add up all of your 
            deposited paychecks, and voila, you have your expected income for the month.
       
            For variable income, or commission based income, look back at the past six months of your paychecks, add up all of your deposited paychecks, and then divide by 6 to arrive at a rough estimate.
       
          
            To continue with our example, let's take the national average salary in America of around $64,000.  Monthly, that amount equates to $5,333 and factoring in tax withholding, and some additional items like 401(k) contributions and health insurance premiums,
            let's settle on an 80% net deposit of around $4,266.
          
            Utilizing Budget Even, we can then enter $4,266 as our planned Income for the month.
         
        </div> 
        <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex',flexDirection:'row',marginTop:25}}>
        <Card style={{width:600,height:175}}>
          <CardContent>
            <Typography variant='h5'>Income</Typography>
            <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.primary.main, color:'white', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Description

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Planned

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
          
            Received

          </Grid>

{downMd ? null: <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             Remaining

          </Grid>}

      
    
        </Grid>


        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              Paychecks

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              $4,266.00

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $0.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $4,266.00

          </Grid>}
   

    
        </Grid>
          </CardContent>
        </Card>
        </div>
     
        
        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Step Two:  <span style={{fontWeight:600,fontSize:14,color:'black'}}>Plan your expenses.</span></span>

        </div>

  
      
        <div style={{marginTop:10,fontWeight:500}}>
            Effective budgeting requires organization.  Our preferred method is to organize our expenses by groups with line items.  
            For example, "Food and Beverage" can be the name of a budget group, with line items of "Groceries","Dining Out", and "Coffee".

         

      
            Once you've completed the "Food and Beverage" group, create a few more groups like "Housing","Transportation", and "Entertainment."
            For our scenario, it'll look like this:

            {/* display charts */}

            <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex',flexDirection:'row',marginTop:25}}>
        <Card style={{width:600,height:225}}>
          <CardContent>
            <Typography variant='h5'>Food and Beverage</Typography>
            <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.primary.main, color:'white', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Description

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Planned

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
          
            Spent

          </Grid>

{downMd ? null: <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             Remaining

          </Grid>}

      
    
        </Grid>


        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Groceries

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              $0.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $0.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $0.00

          </Grid>}
   

    
        </Grid>

        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Dining Out

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              $0.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $0.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $0.00

          </Grid>}
   

    
        </Grid>
          </CardContent>
        </Card>
        </div>

            <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
            Your turn.  If you utilize credit cards, pull up your statements from the prior month.  Ditto for your bank account statements.
        </div>

            
            <div style={{marginTop:10,fontWeight:500}}>
            Take a look at your statements, get a calculator out, and add up all of your grocery expenditures.  For our example, let's say
            we spent $500 total on groceries last month and $150 on dining out.  So, we'll input those values in the corresponding line items.
            </div>

            <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex',flexDirection:'row',marginTop:25}}>
        <Card style={{width:600,height:225}}>
          <CardContent>
            <Typography variant='h5'>Food and Beverage</Typography>
            <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.primary.main, color:'white', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Description

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Planned

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
          
            Spent

          </Grid>

{downMd ? null: <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             Remaining

          </Grid>}

      
    
        </Grid>


        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Groceries

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $500.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $0.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $0.00

          </Grid>}
   

    
        </Grid>

        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Dining Out

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              $150.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $0.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $0.00

          </Grid>}
   

    
        </Grid>
          </CardContent>
        </Card>
        </div>

            <div style={{marginTop:25,fontWeight:500}}>
            Continue going through your statements and adding up totals for your Transportation group and your Housing group.  Gas, for example, 
            should be a line item in your Transportation group if you drive a car.  Along those lines, you'll probably need to add an insurance line item,
            a maintenance line item, and if applicable, a car payment line item. 
            </div>
            
            <div style={{marginTop:10,fontWeight:500}}>
              Now, for your personal budget, continue looking through your statements and create your 
              own custom groups.  Do you have kids?  Create a kid group.  Pets?  Create a pet group.  Take some time to organize you budget, name your line items, 
              and adjust values as you see fit.

              
            </div>

          

              <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Step Three:  <span style={{fontWeight:600,fontSize:14,color:'black'}}>Review your budget daily and track your expenses.</span></span>

        </div>
        <div style={{marginTop:10,fontWeight:500}}>
       
                Set aside 10-15 minutes every day to record your expenditures.  Bought groceries yesterday?  Did you fill up your car with gas?  Add entries for each 
                transaction to their corresponding line item.  Using Budget Even, all you need to do is tap the add transaction button, enter in the transaction details, hit submit and 
                the transaction will be recorded.  
               </div>
               <div style={{marginTop:25,fontWeight:500,display:'flex',flexDirection:'row',justifyContent:'center'}}>
               <Grid container style={{marginTop: 10, width:400, padding:10, color:'black', border: '2px solid #FD396D', borderRadius:5}}>
              <Grid container direction='row'>
              
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} style={{marginLeft:3}}>
                      <Typography style={{fontWeight:'700', color: theme.palette.primary.main}}>{todaysDate()}</Typography>
                     
                      </Grid>
              
                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{marginLeft:'auto', textAlign:'right'}}>
                      <Typography style={{fontWeight:'bold',color: theme.palette.error.main}}> 
                      {new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(62.47)}</Typography>
                     
                      </Grid>
                      
              </Grid>
              
              <Grid container direction='row' style={{padding:3}}>
                  
              
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <Typography variant='caption' 
                       style={{color: theme.palette.text.primary, fontWeight:'bold'}}>Kroger</Typography>
                         
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <Typography variant='caption'
                       style={{color: theme.palette.text.primary, fontWeight:'bold'}}>Group Name: Food and Beverage</Typography>
                     
                      </Grid>
            
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <Typography variant='caption'
                       style={{color: theme.palette.text.primary, fontWeight:'bold'}}>Line Item: Groceries</Typography>
                     
                      </Grid>
            
                    
              </Grid>
              
              
              </Grid>
              </div>

          
               <div style={{marginTop:25,fontWeight:500}}>
       
       By reviewing and recording your transactions daily, you'll start becoming more aware of how much money you are actually spending.  
      </div>


      <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Step Four:  <span style={{fontWeight:600,fontSize:14,color:'black'}}>Stay Budget Even</span></span>

        </div>
        <div style={{marginTop:10,fontWeight:500}}>
              If you had planned $500 for groceries in a given month but you spent $550, your budget is no longer even as you have overspent by $50.  
               </div>

               <div style={{marginTop:10,fontWeight:500}}>
       
       To correct the overspent line item, you'll need to look through the rest of your budget to identify any line item, or line items, that have excess
       funds available to reallocate.  For example, let's say you only spent $100 on dining out, but had planned to spend $150.
      </div>


      <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex',flexDirection:'row',marginTop:25}}>
        <Card style={{width:600,height:225}}>
          <CardContent>
            <Typography variant='h5'>Food and Beverage</Typography>
            <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.primary.main, color:'white', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Description

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Planned

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
          
            Spent

          </Grid>

{downMd ? null: <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             Remaining

          </Grid>}

      
    
        </Grid>


        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Groceries

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              $500.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
          $550.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,color:'red',fontWeight: 600,minWidth: determineColumnWidth()}}>
           -$50.00

          </Grid>}
   

    
        </Grid>

        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Dining Out

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $150.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $100.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $50.00

          </Grid>}
   

    
        </Grid>
          </CardContent>
        </Card>
        </div>

      <div style={{marginTop:10,fontWeight:500}}>
       
      So, knowing there is
       an extra $50 in the Dining Out budget, you can reallocate that amount to Groceries. The dining out budget goes to $100 and the grocery budget increases
       to $550 to return to budget even.
      </div>

      <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex',flexDirection:'row',marginTop:25}}>
        <Card style={{width:600,height:225}}>
          <CardContent>
            <Typography variant='h5'>Food and Beverage</Typography>
            <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.primary.main, color:'white', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Description

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Planned

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
          
            Spent

          </Grid>

{downMd ? null: <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             Remaining

          </Grid>}

      
    
        </Grid>


        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Groceries

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              $550.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
          $550.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
           $00.00

          </Grid>}
   

    
        </Grid>

        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
            Dining Out

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $100.00
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $100.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $00.00

          </Grid>}
   

    
        </Grid>
          </CardContent>
        </Card>
        </div>

      <div style={{marginTop:25,fontWeight:500}}>
  The main takeaway from this exercise is that you now know that you cannot spend anymore money on dining out for the rest of the month.  Throughout
  the month, you'll need to continually reallocate adjust your budget according to your expenditures.
      </div>


      <div style={{marginTop:25,fontWeight:500}}>
  Once you have the hang of budgeting, reallocating, and tracking your transactions, create a "Savings" group and commit at least 10% of your net income
  each month to a savings account.  Automate your savings contribution if possible, the less you think about it, the easier it is!
      </div>

      <div style={{marginTop:25,fontWeight:500,fontStyle:'italic'}}>
            -Patrick Lysaght, Founder and Creator of Budget Even
            <div style={{marginTop:10}}>
            <ShareButtons url='https://www.budgeteven.com/learn/howtostartabudget' title='How To Start a Budget' />
            </div>
          
      </div>
        


                    </div>

         
        
    </Container>
  
    <BottomSpacer />
</Wrapper>

    </div>
  

 
   
  )
}