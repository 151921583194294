
import { getAuth } from "firebase/auth";
import { getBudgetPodsByMonth } from "utils/BudgetMethods";
import { getFirestore,updateDoc,doc,setDoc } from "firebase/firestore";
import React, { useState, useEffect,} from 'react'
import { Button,useTheme,Typography,Select,MenuItem,Divider} from "@mui/material";
import { useLocation,useNavigate } from "react-router";
import LightTextField from "components/uicomponents/LightTextField";
import { getCurrentMonthAndYearString } from "utils/Helpers";
import { Small } from "components/uicomponents/Typography";
import { uploadError } from "utils/FirebaseAnalyticsMethods";


const db = getFirestore();

export default function EditRuleForce(){

    const theme = useTheme();
 
    const location = useLocation();
    const navigate = useNavigate();
    const USER_UID = getAuth().currentUser.uid
   

   const [showAddLineItemOnly,setShowAddLineItemOnly] = useState(false);
   const [showAddBudgetGroupAndLineItem, setShowAddBudgetGroupAndLineItem] = useState(false)
   const [selectedLineItemDescription,setSelectedLineItemDescription] = useState('')
   const [selectedPodTitle,setSelectedPodTitle] = useState('')
   const [localPodsArray, setLocalPodsArray] = useState([])

   const [editLineDescription,setEditLineDescription] = useState('')
   const [editGroupName,setEditGroupName] = useState('')

  const [rulesToEdit,setRulesToEdit] = useState(location.state.rules)


   useEffect(() => {
    getBudgetPodsHome()
},[])

useEffect(() => {
  if(selectedPodTitle === 'Auto-Delete'){
    setSelectedLineItemDescription('Auto-Delete')
  }
},[selectedPodTitle])




function determineLineItemDisplay(){
 
try{
  if(selectedPodTitle === 'Auto-Delete'){
      
    return(

  
      <div style={{display:'flex', flexDirection:'column', marginTop:15}}>
  
  <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                Select Line Item
                                </Small>
  
                                <Select  value='Auto-Delete'
                        style={{minWidth:400}}
                          fullWidth
                          disableUnderline = {true}
                          //onChange={(e) =>  handleLineItemSelection(e.target.value)}
                      
                                    >
                     <MenuItem value='Auto-Delete'>Auto-Delete</MenuItem>         
                          </Select>
  
  
  
  </div>
        
    )
  }
  else {

    
  let podIndex = localPodsArray.findIndex(obj => obj.title === selectedPodTitle)
  if(podIndex !== -1){
    return(

  
      <div style={{display:'flex', flexDirection:'column', marginTop:15}}>
  
  <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                Select Line Item
                                </Small>
  
                                <Select  value={selectedLineItemDescription}
                        style={{minWidth:400}}
                          fullWidth
                          disableUnderline = {true}
                          onChange={(e) =>  handleLineItemSelection(e.target.value)}
                      
                                    >
                           <MenuItem value='Create New Line Item'>Create New Line Item</MenuItem>                 
            {localPodsArray[podIndex].data.map((item,i) => {
                  return(
                    <MenuItem key={i.toString()} value={item.description}>{item.description}</MenuItem>
                  )
            })}
                       
                          </Select>
  
  
  
  </div>
        
    )
  }
}
}catch(e){
  uploadError(e.message,`EditRuleForcs.js,determineLineItemDisplay`)
}

  
}

function handleBudgetCategorySelection(value){
  if(value === 'Create New Group'){
    setShowAddBudgetGroupAndLineItem(true)
  }
  else{
    setSelectedPodTitle(value)
  }
}

function handleLineItemSelection(value){
if(value === 'Create New Line Item'){
  setShowAddLineItemOnly(true)
}
else {
  setSelectedLineItemDescription(value)
}
}

async function getBudgetPodsHome(){
  try{
    let monthToUse = getCurrentMonthAndYearString();
    let podsArray = await getBudgetPodsByMonth(monthToUse)
    podsArray.sort((a,b) => a.title.localeCompare(b.title));
    let incomeIndex = podsArray.findIndex(obj => obj.title === 'Income');
    let incomeObject = podsArray[incomeIndex];
    podsArray.splice(incomeIndex, 1);
    podsArray.unshift(incomeObject);
    setLocalPodsArray(previousState => [...podsArray])
  }catch(e){
    uploadError(e.message,'EditRuleForce.js,getBudgetPodsHome')
  }

  
  }
  

async function handleSaveAndCloseAddLineItemOnly(){

  try{
    let currentArray = localPodsArray
    let podPosition = currentArray.findIndex(obj => obj.title === selectedPodTitle)
    currentArray[podPosition].data.push({description:editLineDescription,plannedValue:0.00,spentValue:0.00,podName:selectedPodTitle})
    setLocalPodsArray(previousState => [...currentArray])
  
   
  
    let uid = getAuth().currentUser.uid;
    let monthToUse = getCurrentMonthAndYearString();
  let podRef = doc(db,'EuklesUsersData',uid,monthToUse,selectedPodTitle)
    await updateDoc(podRef,{[editLineDescription]:{
      plannedValue:0.00,
      spentValue:0.00,
      podName: selectedPodTitle
    }})
  
    setShowAddLineItemOnly(false)
  }catch(e){
    uploadError(e.message,'EditRuleForce,handleSaveAndCloseAddLineItemOnly')
  }

}

async function handleSaveAndCloseAddLineItemAndGroup() {

  try{
    let currentArray = localPodsArray

    currentArray.push({title:editGroupName,data:[{description:editLineDescription,plannedValue:0.00,spentValue:0.00,podName: editGroupName}]})
    setLocalPodsArray(previousState => [...currentArray])
    let uid = getAuth().currentUser.uid;
    let monthToUse = getCurrentMonthAndYearString();
  let podRef = doc(db,'EuklesUsersData',uid,monthToUse,editGroupName)
    await setDoc(podRef,{[editLineDescription]:{
      plannedValue:0.00,
      spentValue:0.00,
      podName: editGroupName
    }})
    setShowAddBudgetGroupAndLineItem(false)
  }catch(e){
    uploadError(e.message,'EditRuleForce,handleSaveAndCloseAddLineItemGroup')
  }

}


async function submitRuleChange(){

  try{
    let docId = rulesToEdit[0].docId
let docRef = doc(db,'EuklesUsersData',USER_UID,'TrackingRules',docId)
if(selectedPodTitle === 'Auto-Delete'){
  await updateDoc(docRef,{
    andLineItem: 'Delete',
    thenTrackToPod: 'Delete'
  })
}
else {

  await updateDoc(docRef,{
    andLineItem: selectedLineItemDescription,
    thenTrackToPod: selectedPodTitle
  })
}


let currentArray = rulesToEdit
currentArray.splice(0,1)
if(currentArray.length >= 1){
    setRulesToEdit(previousState => [...currentArray])
}
else {
    navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
}
  }catch(e){
    uploadError(e.message,'EditRulesForce,submitRuleChange')
  }



}







  if(showAddLineItemOnly){
    return(
        <div style={{display:'flex',flexDirection:'column', flex:1,height:'100%', justifyContent:'center',height:500, alignContent:'center',backgroundColor:'white'}}>
       
        <div style={{display:'flex', flexDirection:'column', alignContent:'center',marginTop:25,alignItems:'center', justifyContent:'center'}}>
          <Typography variant='h6' color={theme.palette.primary.dialogBackground} fontWeight='bold' display="block" mb={1}>
          Enter Line item Description
        </Typography>
         <LightTextField fullWidth style={{width:400,marginBottom:25}} name="orderNo"
            InputProps={{disableUnderline:true}}
         
            inputProps={{style: {fontWeight:'bold'}}}
            onChange={(e) => setEditLineDescription(e.target.value)}
            value={editLineDescription}
              
                /> 

<Button disabled={editLineDescription === '' ? true : false} onClick={() => handleSaveAndCloseAddLineItemOnly()} style={{margin: '0 auto', display: "flex"}} variant='contained'>Submit</Button>
<Button onClick={() => setShowAddLineItemOnly(false)} style={{margin: '0 auto', display: "flex",color:'red'}} variant='text'>Cancel</Button>
</div>
        </div>
    )
}

if(showAddBudgetGroupAndLineItem){
    return(       <div style={{display:'flex',flexDirection:'column', flex:1,height:500, justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
  
    <div style={{display:'flex', flexDirection:'column',marginTop:25, alignContent:'center',alignItems:'center', justifyContent:'center'}}>
        <Typography variant='h6' color={theme.palette.primary.dialogBackground} fontWeight='bold' display="block" mb={1}>
        Enter Budget Group title
      </Typography>
      <Typography variant='caption'>*Alphanumeric characters only</Typography>
       <LightTextField fullWidth style={{width:400}} name="group"
          InputProps={{disableUnderline:true}}
       
          inputProps={{style: {fontWeight:'bold'}}}
          onChange={(e) => setEditGroupName(e.target.value)}
          value={editGroupName}
            
              /> 
      </div>
      <div style={{display:'flex', flexDirection:'column',marginTop:25, alignContent:'center',alignItems:'center', justifyContent:'center'}}>
          <Typography variant='h6' color={theme.palette.primary.dialogBackground} fontWeight='bold'  display="block" mb={1}>
          Enter Line item Description
        </Typography>
         <LightTextField fullWidth style={{width:400,marginBottom:25}} name="lineItem"
            InputProps={{disableUnderline:true}}
         
            inputProps={{style: {fontWeight:'bold'}}}
            onChange={(e) => setEditLineDescription(e.target.value)}
            value={editLineDescription}
            //onBlur={() => evaluateGroupTitleAndLineDescription()}
              
                /> 
        </div>
      
        <Button disabled={editLineDescription === '' || editGroupName === '' ? true : false } onClick={() => handleSaveAndCloseAddLineItemAndGroup()} style={{margin: '0 auto', display: "flex"}} variant='contained'>Submit</Button>
        <div style={{marginTop:25}}>
        <Button onClick={() => setShowAddBudgetGroupAndLineItem(false)} style={{margin: '0 auto', display: "flex",color:'red'}} variant='text'>Cancel</Button>
        </div>


        </div>
    )
}
else {
    return(
        <div style={{display:'flex', flex:1,flexDirection:'column',backgroundColor:'white',height:500, borderRadius:5}}>

               
            <div style={{display:'flex', marginTop:50,alignSelf:'center', flexDirection:'column',backgroundColor:theme.palette.primary.dialogBackground.dialogBackground, alignContent:'center',alignItems:'center', justifyContent:'center'}}>
            <Typography style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold',marginLeft:10, marginRight:10, marginBottom:15, textAlign:'center', marginTop:10}}>Select a new Budget Group and a Budget Line item to update the tracking settings for the selected payee.</Typography>
            </div>
      
          <div style={{display:'flex', flexDirection:'column', alignContent:'center',alignItems:'center', justifyContent:'center'}}>
            
          <Divider style={{width:400, height:3,backgroundColor:'black'}}/>
                      <div style={{height:1, backgroundColor: theme.palette.primary.navy, width:'auto', maxWidth:500}}></div>
              
                      {rulesToEdit.map((item,i) => {
                        if(i === 0){
                          return (           
                         
                            <div key={i.toString()} style={{marginTop:5, marginBottom:5, paddingLeft:5, paddingRight:5}}>
                         
                        <Typography style={{fontWeight:'bold'}}>Category: {item.transactionCategory} </Typography> 
                       <Typography  style={{fontWeight:'bold'}}>Payee Name: {item.transactionPayeeName}</Typography> 
                        <Typography  style={{fontWeight:'bold'}}>Alt. Description: {item.transactionBestRep}</Typography> 
                       
                            </div>
                          )
                        }
                        else {
                          return null
                        }
                    
                      })}
                     <Divider style={{width:400, height:3,backgroundColor:'black'}}/>
       

    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

<Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                              Select Budget Group
                              </Small>

                              <Select  value={selectedPodTitle}
                      style={{minWidth:400}}
                        fullWidth
                        disableUnderline = {true}
                        onChange={(e) =>  handleBudgetCategorySelection(e.target.value)}
                    
                                  >
                         <MenuItem value='Create New Group'>Create New Group</MenuItem> 
                         <MenuItem value='Auto-Delete'>Auto-Delete</MenuItem>                  
          {localPodsArray.map((item,i) => {
                return(
                  <MenuItem key={i.toString()} value={item.title}>{item.title}</MenuItem>
                )
          })}
                     
                        </Select>



</div>
      
             
  

   
  
                          {selectedPodTitle === '' ? null : determineLineItemDisplay()}


                       
                        {selectedPodTitle !== '' && selectedLineItemDescription !== '' ?  <Button  mode='contained' 
                        onClick={() => submitRuleChange()}
                        labelStyle={{fontWeight:'bold'}} style={{alignSelf:'center', color: theme.palette.primary.navy, marginTop:15}}>Next</Button> : null}
                        </div>
  
                         
                     

              
             </div>
      )
}
}