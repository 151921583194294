

export const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID
};



export const firebaseConfig = {
    apiKey: "AIzaSyCFH_owBLRmCuBQrWFMthFX2U8qG9DYd48",
  // authDomain: "budgetbettereukles.firebaseapp.com",
    authDomain: "budgeteven.com",
    databaseURL: "https://budgetbettereukles.firebaseio.com",
    projectId: "budgetbettereukles",
    storageBucket: "budgetbettereukles.appspot.com",
    messagingSenderId: "664780111300",
    appId: "1:664780111300:web:cd2328d8813c1674247891",
    measurementId: "G-MVS96H2MZB"
   };