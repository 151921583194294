
import { Card, CardHeader, CardContent, List, ListItem,ListItemIcon, ListItemText,ListSubheader, Button,useTheme } from '@mui/material'
import React from 'react'

import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';


export default function ManualCard({handlePurchase}) {
    const theme = useTheme();
    let first = "Create a custom monthly budget by creating your own Budget Groups and Line Items."
let second = ` For example, create a "Food" group with line items of "Dining Out" and "Groceries".  Then, enter a "Planned" amount for each line item, and try to stay under that amount!`

  return (
    <Card style={{width:400}}>
    <CardHeader titleTypographyProps={{fontWeight:'bold',color:theme.palette.primary.dialogBackground}} 
    title="Budget Manually" 
    subheader="Free"
    subheaderTypographyProps={{fontWeight:'bold',color: theme.palette.primary.main}}
    
    />
    <CardContent>
      <List>
        <ListSubheader style={{fontWeight:'bold',color:'black',marginTop:-25}}>How does it work?</ListSubheader>
        <ListItem>
        
           <ListItemIcon>
            <LooksOneIcon style={{fontSize:20,color:theme.palette.primary.yellow}} fontSize='small' />
           </ListItemIcon>
          <ListItemText 
          primaryTypographyProps={{fontWeight:'bold',color:theme.palette.primary.dialogBackground}}
          secondaryTypographyProps={{color:'black',fontWeight:'400'}}
          primary="Create a Custom Monthly Budget"  
          secondary={first + second} />
        </ListItem>
        <ListItem>
        
        <ListItemIcon>
         <LooksTwoIcon style={{fontSize:20,color:theme.palette.primary.yellow}} fontSize='small' />
        </ListItemIcon>
       <ListItemText 
       primaryTypographyProps={{fontWeight:'bold',color:theme.palette.primary.dialogBackground}}
       secondaryTypographyProps={{color:'black',fontWeight:'400'}}
       primary="Track your Transactions" 
       secondary="As you spend and receive money, record your transactions by entering the amount, date, and payee."/>
     </ListItem>
     <ListItem>
        
        <ListItemIcon>
         <Looks3Icon style={{fontSize:20,color:theme.palette.primary.yellow}} fontSize='small' />
        </ListItemIcon>
       <ListItemText 
       primaryTypographyProps={{fontWeight:'bold',color:theme.palette.primary.dialogBackground}}
       secondaryTypographyProps={{color:'black',fontWeight:'400'}}
       primary="Net Worth and More" 
       secondary="Track your net worth by entering in your assets and liabilities.  Review and analyze your budget, and start building savings!" />
     </ListItem>




      
      </List>
    </CardContent>
    <div style={{ textAlign: 'center', padding: '16px' }}>
      <Button onClick={() => handlePurchase()} variant="contained" color="primary">
       Select this Option
      </Button>
    </div>
  </Card>
  )
}