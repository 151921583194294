import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box,useTheme,AppBar,Container,useMediaQuery,styled,Typography,Toolbar,Grid,CardContent,Card,Button } from '@mui/material';
import BottomShareCallOut from '../components/BottomShareCallOut';
import BottomSpacer from 'components/uicomponents/BottomSpacer';

import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import React,{useEffect} from 'react'
import ShareButtons from '../components/ShareButtons';
import { Small } from 'components/uicomponents/Typography';
import { useNavigate } from "react-router-dom";
import useWindowSize from 'hooks/useWindowSize';





export default function BudgetingAnnualExpenses() {

    const theme = useTheme();
    const MenuList = styled("ul")(({theme}) => ({margin: 0,padding: 0,listStyle: "none",[theme.breakpoints.down("sm")]: { "& a": {display: "block",marginTop: "1rem"}}}));
  
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"))
    
    const { height} = useWindowSize()
    const navigate = useNavigate();

    const Wrapper = styled(Box)(() => ({
      backgroundImage: "url('/static/landing-page/blogpagebackground.svg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "repeat",
      position:'absolute',
      top:0,
      right:0,
      width:'100%',
      height:'100%'
    }));

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    function determineMaxWidth(){
      if(isSmall){
        return 500
      }
      else if(downMd){
        return 500
      }
      else if(isLarge){
        return 800
      }
      else {
        return 800
      }
      
    }

    function determineColumnWidth(){
      return 100
      // if(isSmall){
      //   return 500
      // }
      // else if(downMd){
      //   return 500
      // }
      // else if(isLarge){
      //   return 800
      // }
      // else {
      //   return 800
      // }
      
    }

    function todaysDate(){
      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
    
      const formattedDate = `${month}-${day}`;
      return formattedDate
    }
 
  return (


<div>


    <Wrapper>

             <AppBar position="fixed" sx={{boxShadow: 2,minHeight: 64,justifyContent: "center",backgroundColor: "white"}}>
      <Container>
        <Toolbar sx={{justifyContent: "space-between",pl:0,pr:0}}>
        <div onClick={() => navigate('./')} ><img src={BudgetEvenLogo} width={200} alt="Logo" /></div>
    
              {downMd || height < 400 ? null : <MenuList style={{display:'flex', flexDirection: 'row', padding:15}}>
      

              <Typography    onClick={() => navigate('/login')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Login</Typography>
              <Typography    onClick={() => navigate('/register')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Sign up</Typography>
             <Typography  style={{fontWeight:'bold',marginLeft:10, marginRight:10, color: theme.palette.primary.main, cursor:'pointer'}}>How it works</Typography>
          
              <Typography  style={{fontWeight:'bold',marginLeft:10, marginRight:10, color: theme.palette.primary.main, cursor:'pointer'}}>Pricing</Typography>
              <Typography to="Learn" onClick={() => navigate('/blog')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Learn</Typography>
              <Typography to="Free Tools" onClick={() => navigate('/blog')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Free Tools</Typography>
            </MenuList>}
            </Toolbar>
          </Container>
    </AppBar>

    <Grid container justifyContent='center' display='flex' flexDirection='column' alignContent='center' alignItems='center'> 
    <Grid item>
    <Typography variant='h3' style={{textAlign:"center",fontWeight:'bold',color:'#1A77CD',marginTop:100}}>Budgeting Annual Expenses</Typography>
    </Grid>
  
      <Grid item mt={2} style={{textAlign:'center'}}>
          <Small style={{textAlign:'center'}} justifyContent='center'  mb={3} fontSize={16} fontWeight={600} color="text.disabled">
            Let's talk about how to budget for annual, semi-annual, and quarterly expenses in our monthly budget.
          </Small>
      </Grid>


    </Grid>

<Container style={{maxWidth:determineMaxWidth()}}>
        <div style={{fontWeight:500,marginTop:25}}>
            Perhaps this is obvious to some, but for the new budgeters out there have no fear...this is a simple one.

        </div>



        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Annual Expenses<span style={{fontWeight:600,fontSize:14,color:'black'}}></span></span>

        </div>
        <div style={{marginTop:25,fontWeight:500}}>
            Annual expenses will likely be large and if you don't plan for them throughout the year, it can hurt your budget significantly.  An easy way to account
            for one-time expenses is to take the total annual amount and divide it by 12.  Once you have that amount, enter it in as a line item within your budget.
            Then, every month, add a transaction for that amount.  Even though you are not literally paying it, you are essentially "booking" the 1/12th of the expenses
            ahead of time.  This way, when it comes time to pay the annual bill, it won't be a total budget buster.  
         
        </div> 

        <div style={{marginTop:25,fontWeight:500}}>
            For example, let's say you own a house and need to budget for an annual real estate tax bill.  We'll say your bill is $6,000 annually which means that 
           you'll need to budget for $500 per month: $6,000/12 = $500. 
         
        </div> 

   
        <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex',flexDirection:'row',marginTop:25}}>
        <Card style={{width:600,height:175}}>
          <CardContent>
            <Typography variant='h5'>Housing</Typography>
            <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.primary.main, color:'white', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Description

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
              Planned

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              minWidth: determineColumnWidth()
            }}>
          
            Spent

          </Grid>

{downMd ? null: <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             Remaining

          </Grid>}

      
    
        </Grid>


        <Grid container direction='row' justifyContent='center' style={{color:'black', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              Real Estate Taxes

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
              $500.00

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
          
           $0.00

          </Grid>

{downMd ? null : <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{fontSize: 12,fontWeight: 600,minWidth: determineColumnWidth()}}>
             $500.00

          </Grid>}
   

    
        </Grid>
          </CardContent>
        </Card>
        </div>
     
 
  
      
        <div style={{marginTop:10,fontWeight:500}}>
        Use the same strategy for semi-annual or quarterly bills and expenses.  For semi-annual expenses, multiply the expense by 2 to get the annual expenses, divide it by 12 and make entry in your budget accordingly.
        For quarterly expenses, multiply by 4 and then divide by 12.

            
            <div style={{marginTop:10,fontWeight:500}}>
           Each month, use Budget Even to add a "phantom" transaction.  That is, add a transaction as if you spent $500 to account for the annual expense within your monthly budget.  
            </div>

            <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex',flexDirection:'row',marginTop:25}}>
          <Button variant='outlined' style={{backgroundColor:"#FD396D",borderColor:'white',color:'white'}} startIcon={<AddCircleIcon style={{color:'white'}}/>}>Add Transaction</Button>
        </div>

        <div style={{marginTop:10,fontWeight:500}}>
           By using this process, you can keep those large one-time expenses part of your monthly budget.  
            </div>

      <div style={{marginTop:25,fontWeight:500,fontStyle:'italic'}}>
            -Patrick Lysaght, Founder and Creator of Budget Even
            <div style={{marginTop:10}}>
            <ShareButtons url='https://www.budgeteven.com/learn/howtostartabudget' title='How To Start a Budget' />
            </div>
          
      </div>
        


                    </div>

         
        
    </Container>
  
    <BottomSpacer />
</Wrapper>

    </div>
  

 
   
  )
}