import { Box, styled } from "@mui/material";
import DashboardSidebar from "./DashboardSideBar";
import { Fragment} from "react";
import { Outlet } from "react-router";




const Wrapper = styled(Box)(({
  theme,
  show
}) => ({
  width: `calc(100% - ${show ? "320px" : "80px"})`,
  paddingLeft: "3rem",
  paddingRight: "3rem",
  transition: "all 0.3s",
  marginLeft: show ? 320 : 80,
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginLeft: 0,
    paddingLeft: "2rem",
    paddingRight: "2rem"
  }
}));
const InnerWrapper = styled(Box)(({
  theme
}) => ({
  [theme.breakpoints.up("lg")]: {
    maxWidth: 1500,
    margin: "auto"
  }
}));

const DashboardLayout = ({children}) => {


  return <Fragment>
      <DashboardSidebar />

      <Wrapper >
        <InnerWrapper>
  
          {children || <Outlet />}
        </InnerWrapper>
      </Wrapper>
    </Fragment>
};

export default DashboardLayout;