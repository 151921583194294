
import AutoCard from "../OptionsCards/AutoCard";
import BottomSpacer from "components/uicomponents/BottomSpacer";
import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import { Grid,useTheme,Button} from '@mui/material';
import { Helmet } from "react-helmet";
import { H3} from "components/uicomponents/Typography";
import ManualCard from "../OptionsCards/ManualCard";
import OnBoardingMenu from "../MenuPopover/OnBoardingMenu";
import './onboardingstyles.css'
import React,{useState,useEffect} from 'react'
import { useNavigate} from "react-router";
import { uploadError } from "utils/FirebaseAnalyticsMethods";
import { handleSetUpNewUser, updateUserDocument } from "utils/UserMethods";


export default function GetStarted (){

 
  const navigate = useNavigate();


    const theme = useTheme();
    const [value, setValue] = useState('Auto');
   
    
    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    useEffect(() => {
      
      updateStep();
      }, []);


      function GoogleConversion() {
        return (
          <div>
            {/* Your other component content */}
      
            {/* Add the script using Helmet */}
            <Helmet>
              <script>
                {`
                  gtag('event', 'conversion', {'send_to': 'AW-939462200/KrDdCOj0kocZELic_L8D'});
                `}
              </script>
            </Helmet>
          </div>
        );
      }

      async function handleGoManual(){
        
        try{
          await handleSetUpNewUser('Manual','nil',false);
          navigate("/mainloading",{state:{needsToRefresh:false}});            
        }catch(e){
          uploadError(e.message,'GetStarted, handleGoManual')
        }
      }
    

      function handleGoFullAuto(){
        navigate('../onboardcheckout',{state:{comingFrom: 'OnBoarding', planType:'Auto'}})
      }

      async function updateStep(){
        await updateUserDocument({currentStep:1})
      }



        return (
         <Grid container direction="column" justifyContent='center' alignContent='center' mb={25}>
          <div style={{position:'absolute',top:15,right:25}}>
            <OnBoardingMenu navigate={navigate} />
          </div>

          <Grid container direction='row' justifyContent='center' mt={5}>
          <Grid item>
          <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
          </Grid>
          
          </Grid>
      

          <Grid container direction='row' justifyContent='center'>
          <Grid item>
          <H3 style={{maxWidth:400,marginBottom:50,fontWeight:'bold', textAlign:'center',color:theme.palette.primary.fiverGrayText}}>Select an Option to Continue</H3>
          </Grid>
          </Grid>

       
          <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <Button onClick={() => setValue('Auto')} style={{fontWeight:'bold',width:150}} variant={value === 'Auto' ? 'contained' : 'outlined'}>Auto</Button>
           
                <Button onClick={() => setValue('Manual')} style={{fontWeight:'bold',width:150}} variant={value === 'Manual' ? 'contained' : 'outlined'}>Manual</Button>
          </div>
       
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:15}}>
          
          {value === 'Auto' ? <AutoCard handlePurchase={handleGoFullAuto}  /> : null}
       
      
          {value === 'Manual' ? <ManualCard handlePurchase={handleGoManual} /> : null}

        </div>
        {value === 'Auto' ?    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:25}}>
        <Button variant="contained" style={{fontWeight:'bold'}} onClick={() => handleGoFullAuto()}>Subscribe $4.99 per month</Button>
       </div> : null}
     
<GoogleConversion />
 
    <BottomSpacer />
      
      </Grid>
      )
      
     
      
        
    
 
  
  
}