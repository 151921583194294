import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTheme,Typography} from "@mui/material";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpgradeSuccessDialog({handleContinue}) {
 
        const theme = useTheme();


  return (
    <div>

      <Dialog
        open={true}
        TransitionComponent={Transition}
     
      
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title" 
                                    style={{textAlign:'center', overflowX:'hidden', overflowY:'hidden', 
                                    backgroundColor: theme.palette.primary.dialogBackground, color:'white', fontWeight:'bold', maxHeight:120}}>
                                              
                                    
            <Typography variant='h4' style={{fontWeight:'700'}}>Success!</Typography>

            </DialogTitle>
          
            <DialogContent style={{display:'flex', paddingTop:25, flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
                <Typography style={{fontWeight:'bold',textAlign:'center',color:theme.palette.primary.fiverGrayText}}>You are now subscribed to Budget Even Auto</Typography>
                <Typography style={{fontWeight:'bold',textAlign:'center',color:theme.palette.primary.fiverGrayText}}>After your accounts are connected, a budget will be created for you based upon your income and spending last month.</Typography>
                <Button onClick={() => handleContinue()} variant='outlined' style={{backgroundColor: theme.palette.success.main, marginTop:25, width: 200, color:'white', fontWeight:'bold'}}    
>
Continue
</Button>
<DialogActions>





</DialogActions>
</DialogContent>

      </Dialog>
    </div>
  )



}