
import "nprogress/nprogress.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";

import './styles.css'
import './index.css'
import 'intro.js/introjs.css'




ReactDOM.render( <LocalizationProvider dateAdapter={AdapterDateFns}>
    <BrowserRouter><App /></BrowserRouter></LocalizationProvider>, document.getElementById("root"));
         
  
