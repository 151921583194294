import { collection, doc, getFirestore, setDoc, getDoc,arrayUnion, getDocs,updateDoc,increment,  addDoc,deleteDoc, writeBatch,query,where, deleteField} from "firebase/firestore";
import { getFunctions,httpsCallable } from "firebase/functions";
import { getAuth } from 'firebase/auth';
import { getTodaysEpoch} from "./Helpers";
import { uploadError } from "./FirebaseAnalyticsMethods";
import { auth0Config } from "config";







const db = getFirestore();



export async function addSingleTrackingRule(transaction,podTitle,lineItemDescription,transactionCategory){
  let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
    transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
    const userUid = getAuth().currentUser.uid;


    let docRef = collection(db,'EuklesUsersData',userUid, 'TrackingRules')
   const addRule = await addDoc(docRef,trackingRule).then(res => {
      return true;
    }).catch(e => {
      return false;
    })
    return addRule;
}

export function calculateOverSpent(localPodsArray){

  let overSpentItems = [];
  for(let i = 0; i < localPodsArray.length; i++){
    let pod = localPodsArray[i];

    for(let w = 0; w < pod.data.length; w++){
      let net = parseFloat(pod.data[w].plannedValue) + parseFloat(pod.data[w].spentValue);
      net = net.toFixed(2)
   
      if(net < 0 && pod.title !== 'Income'){
        overSpentItems.push({podName: pod.title, lineItemDescription: pod.data[w].description, plannedValue: pod.data[w].plannedValue, spentValue: pod.data[w].spentValue})
        
      }
    }
  }

  return overSpentItems
  // setOverSpentCount(localOverSpent)
  
  // let docRef = doc(db,'EuklesUsers', userUid);
  // await updateDoc(docRef,{overSpentCount: localOverSpent})
  
}


export function calculateTotalsHelper(podsArray){
       
  try{
 
    let plannedIncome = 0.00;
    let plannedExpenses = 0.00;

    let actualIncome = 0.00;
    let actualExpenses = 0.00;

    let totalPlannedSavings = 0.00
    let totalActualSavings = 0.00

    for(let i = 0; i < podsArray.length; i++){
        let pod = podsArray[i];
       
        for(let b =0; b < pod.data.length; b++){
          if(pod.title !== 'Savings'){
            if(pod.title === 'Income'){
              plannedIncome = parseFloat(plannedIncome) + parseFloat(pod.data[b].plannedValue)
              actualIncome = parseFloat(actualIncome) + parseFloat(pod.data[b].spentValue)

          }
          else {
              plannedExpenses = parseFloat(plannedExpenses) + parseFloat(pod.data[b].plannedValue)
              actualExpenses = parseFloat(actualExpenses) + parseFloat(pod.data[b].spentValue)
          }
          }
          else {
              totalPlannedSavings = parseFloat(totalPlannedSavings) + parseFloat(pod.data[b].plannedValue)
              totalActualSavings = parseFloat(totalActualSavings) + parseFloat(pod.data[b].spentValue)
          }
      
           
        }


    }
    if(isNaN(plannedExpenses)){
      plannedExpenses = 0.00
    }

    return {plannedExpenses,plannedIncome,actualExpenses,actualIncome,totalPlannedSavings,totalActualSavings}

  
  }catch(e){
    uploadError(e.message,'calculate totals BudgetHomePageAlt.js')
  }



}

export async function deleteBudgetGroupHelper(month,podName){

  let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,month,podName);
  await deleteDoc(docRef)
  return true;
}

export async function deleteBudgetGroupTrackingRules(podName){

  let podCollectionRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'TrackingRules')
    
  let q = query(podCollectionRef,where("thenTrackToPod","==",podName))
  let rules =  await getDocs(q).then(querySnapshot => {
    let localArray = []
    querySnapshot.forEach(doc => {
      localArray.push(doc.id)
    })
    return localArray
  }).catch(e => {
    return []
  })

  const batch = writeBatch(db)
  for(let i = 0; i < rules.length; i++){
    let docRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'Trackingrules',rules[i])
    //const docRef = db.collection('EuklesUsersData').doc(uid).collection('Transactions').doc(String(transactions[i].docId)); 
    batch.delete(docRef); 
  }
  await batch.commit();

}

export async function handleAddBudgetGroupHelper(podName,description,month,plannedValue){

  try{
    const userUid = getAuth().currentUser.uid;
    let docRef = doc(db,'EuklesUsersData', userUid,month, podName)
    await setDoc(docRef,{
      [description]: {
        plannedValue: parseFloat(plannedValue),
        spentValue: 0.00,
        podName: podName
      }
    })
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, handleAddBudgetGroupHelper')
  }

}

export async function handleAddLineItemHelper(month,podName,description,plannedValue){
  try{
    const userUid = getAuth().currentUser.uid
    let docRef = doc(db,'EuklesUsersData',userUid, month, podName)
    let add = await updateDoc(docRef,{
      [description]: {
        plannedValue: parseFloat(plannedValue),
        spentValue: 0.00,
        podName: podName
      }
    }).then(() => {
      return true;
    }).catch(() => {
      return false;
    })
    return add

  }catch(e){
    uploadError(e.message,'BudgetMethods.js, handleAddLineItemHelper')
  }



}

export async function handleCopyBudgetMonth(monthToCopy,emptyBudgetMonth){
  try{
    const functions = getFunctions();
    let copyMonth = httpsCallable(functions,'copyBudgetFromAnotherMonth');
     await copyMonth({monthToCopy: monthToCopy, newMonth: emptyBudgetMonth}).then(function(result) {
      return result;
    }).catch(function(error){
      return error;
    })
  
    let monthsDoc = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'MonthsWithBudgets','months')
  let monthUpdate = await updateDoc(monthsDoc,{
    mArray: arrayUnion(emptyBudgetMonth)
  })
  return monthUpdate;
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, handleCopyBudgetMonth')
  }

}

export async function handleCreateScratchBudget(monthYear){
  try{
    const uid = getAuth().currentUser.uid
    let incomeDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Income')
    await setDoc(incomeDocRef,{
      ['Paychecks']: {
        plannedValue:0.00,
        spentValue:0.00,
        podName:'Income'
    }
    })
  
    let savingsDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Savings')
    await setDoc(savingsDocRef,{
      ['Savings']: {
        plannedValue:0.00,
        spentValue:0.00,
        podName:'Savings'
    }
    })

    let monthsDoc = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'MonthsWithBudgets','months')
    let monthUpdate = await updateDoc(monthsDoc,{
      mArray: arrayUnion(monthYear)
    })

    return monthUpdate;
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, handleCreateScratchBudget')
  }


}

export function isLastCharAlphanumeric(str) {
  // Regular expression that matches any letter or number at the end of a string
  const regex = /[a-zA-Z0-9]$/;

  // Test if the last character matches the pattern
  return regex.test(str);
}


export async function deleteLineItemHelper(month,podName,lineDescription){
  try{
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,month,podName);
   let del = await updateDoc(docRef, {[lineDescription]: deleteField()}).then(() => {
    return true;
   }).catch(() => {
    return false
   })
   return del;
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, handleAddLineItemHelper')
  }



}

export async function getBudgetPodsByMonth(monthToUse){
  
  try{
    let uid = getAuth().currentUser.uid;
    let podRef = collection(db,'EuklesUsersData',uid,monthToUse)
    let podsArray = await getDocs(podRef).then(function(querySnapshot){
        if(querySnapshot.size <= 0){
           
            return false;
        }
        else if(querySnapshot.size > 0){
    
            let podArray = [];
           
            querySnapshot.forEach(function(doc){
               
                let localList = []
                let keys = Object.keys(doc.data());
                let fullData = doc.data();
             
         
                
                //*********LINE ITEM MAP********* */
                keys.map((name, i) => {
                  let p = Number(fullData[keys[i]].plannedValue)
                  p = Number(p.toFixed(2));
                  let s =  Number(fullData[keys[i]].spentValue)
                  s = Number(s.toFixed(2));
               
                    let lineItem = {
                        description: keys[i],
                        plannedValue: p,
                        spentValue: s,
                        podName: doc.id,
                        checked: false
                    }
                    localList.push(lineItem)
                    return null;
                })
             //************END LINE ITEM MAP************* */
    
             
                let budgetObject = {
                    title: doc.id,
                    data: localList,
                    checked: false
                }
                
                   
                        podArray.push(budgetObject)
            
    
            })
            return podArray;
        }
    }).catch((e) => {
      console.log('error ',e);
      return [];
    })
    
    
    podsArray.sort((a,b) => a.title.localeCompare(b.title));
    let incomeIndex = podsArray.findIndex(obj => obj.title === 'Income');
    let incomeObject = podsArray[incomeIndex];
    podsArray.splice(incomeIndex, 1);
    podsArray.unshift(incomeObject);
    
    
    let savingsIndex = podsArray.findIndex(obj => obj.title === 'Savings')
    if(savingsIndex === -1){
    
      let description = 'Savings'
      let docRef = doc(db,'EuklesUsersData', uid,monthToUse, 'Savings')
      await setDoc(docRef,{
        [description]: {
          plannedValue: parseFloat(0.00),
          spentValue: 0.00,
          podName: 'Savings'
        }
      })
    return podsArray
    }
    return podsArray
    
  }catch(e){
    uploadError(e.message,'BudgetMethods, getBudgetPodsByMonth')
  }




}

export async function getGenericBudgetMonths(){
  let docRef = doc(db, 'GenericData','monthsAndYears')
  let monthsYears = await getDoc(docRef).then(doc => {
    return doc.data().values
  }).catch(e => {
    return []
  })
  return monthsYears
}

export async function getSavingsGoal(){
  const userUid = getAuth().currentUser.uid;
  let docRef = doc(db,'EuklesUsersData',userUid, 'MonthlySavingsGoal', 'goal');
  let theGoal= await getDoc(docRef).then((doc) => {

    return {error:false, value: doc.data().value}
  }).catch((err) => {
   
    return {error:true, value: 0.00}
  })

  if(!theGoal.error){
    return theGoal.value
    
  }
  else {
    return 100

  }
}

export async function getBudgetMonths(){
  const userUid = getAuth().currentUser.uid;
  const monthsDoc = doc(db,'EuklesUsersData',userUid,'MonthsWithBudgets','months')

  let budgetMonthsArray = await getDoc(monthsDoc).then(function(doc){
    let theArray = doc.data().mArray;
    
    return theArray;
  }).catch((error) => {
    //show alert and reload
    return [];
});
return budgetMonthsArray;
}

export async function getTrackingRulesHelper(){
  try{
    let podCollectionRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'TrackingRules')
                  

    let currentRules =  await getDocs(podCollectionRef).then(querySnapshot => {
      let localArray = []
      querySnapshot.forEach(doc => {
        localArray.push({...doc.data(),docId: doc.id})
      })
      return localArray
    }).catch(e => {
      return []
    })
    return currentRules
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, getTrackingRulesHelper')
  }
}

export async function retrieveAndDeleteTrackingRules(podName,description){
  try{

    let podCollectionRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'TrackingRules')
                  
    let q = query(podCollectionRef,where("thenTrackToPod","==",podName))
    let currentRulesWithPod =  await getDocs(q).then(querySnapshot => {
      let localArray = []
      querySnapshot.forEach(doc => {
        localArray.push({...doc.data(),docId: doc.id})
      })
      return localArray
    }).catch(e => {
      return []
    })
  
   let filteredPods = currentRulesWithPod.filter(obj => obj.andLineItem === description)
   const batch = writeBatch(db);
   for(let i = 0; i < filteredPods.length; i++){
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'TrackingRules',String(filteredPods[i].docId))
    batch.delete(docRef)
  }
  await batch.commit().then(() => {
    return true;
  }).catch(() => {
    return false;
  })
  
  return true;  
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, retrieveAndUpdateTrackingRules')
  }
}

export async function retrieveAndUpdateTrackingRules(podName,oldDescription,description){
  try{

    let podCollectionRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'TrackingRules')
                  
    let q = query(podCollectionRef,where("thenTrackToPod","==",podName))
    let currentRulesWithPod =  await getDocs(q).then(querySnapshot => {
      let localArray = []
      querySnapshot.forEach(doc => {
        localArray.push({...doc.data(),docId: doc.id})
      })
      return localArray
    }).catch(e => {
      return []
    })
  
   let filteredPods = currentRulesWithPod.filter(obj => obj.andLineItem === oldDescription)
   const batch = writeBatch(db);
   for(let i = 0; i < filteredPods.length; i++){
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'TrackingRules',String(filteredPods[i].docId))
    batch.update(docRef,{andLineItem: description})
  }
  await batch.commit().then(() => {
    return true;
  }).catch(() => {
    return false;
  })
  
  return true;  
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, retrieveAndUpdateTrackingRules')
  }
}


export async function uploadAllTransactionsToBeTracked(transactionsWithDetailsArray,podName,lineItemDescription,selectedMonth){
  try{
    const userUid = getAuth().currentUser.uid;
    const db = getFirestore();
    let todayEpoch = getTodaysEpoch();
 
    
    for(let i = 0; i < transactionsWithDetailsArray.length; i++){
       
      let transaction = transactionsWithDetailsArray[i]
    
      //let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
      let trackingMonthAndYear = selectedMonth
    
      let trackingStatus = podName === 'Delete' ? 'Deleted' : 'Tracked'
    
    
       let myObject = {...transaction,podName: podName, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}
  
       let docRef = doc(db,'EuklesUsersData',userUid, 'Transactions', String(transaction.id));

       await setDoc(docRef,myObject).then(res => {
        return true;
       }).catch(e => {
        return false;
       })
    
         
      
     }
    
     return true;
  }catch(e){
    console.log(e)
    return false;
  }



}

export async function updateLineItemPlannedValue(podName, lineItemDescription,thePlannedValue,month){

  const userUid = getAuth().currentUser.uid;
  let lineItemName = lineItemDescription.concat('.plannedValue');
  let docRef = doc(db,'EuklesUsersData',userUid,month, podName);
 let update = await updateDoc(docRef,{
    [lineItemName]: parseFloat(thePlannedValue)
}).then(() => {
  return true;
}).catch(() => {
  return false;
})
return update
}

export async function updateSingleSpentValue(transactionType,month,podName, lineDescription,transactionAmount){

  try{
    let value = transactionType === 'Savings' ? Math.abs(transactionAmount) : parseFloat(transactionAmount)
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,month, podName)
    let lineItemName = lineDescription.concat('.spentValue');
    await updateDoc(docRef,{
        [lineItemName]: parseFloat(value)
    })
    return true;
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, updateSingleSpentValue')
  }

}

export async function updateSpentValueAfterDelete(podName,lineDescription,month,value){
  try{
  
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,month, podName)
    let lineItemName = lineDescription.concat('.spentValue');
    await updateDoc(docRef,{
        [lineItemName]: parseFloat(value)
    })
    return true;
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, updateSingleSpentValue')
  }
}

export async function updateLineItemDescription(scrubbed,plannedValue,spentValue,podName,oldDescription,month){
try{
  plannedValue = Number(plannedValue).toFixed(2);
  spentValue = Number(spentValue).toFixed(2);
  let replacmentObject = {[scrubbed]:{plannedValue:parseFloat(plannedValue),spentValue:parseFloat(spentValue),podName:podName}}

  const batch = writeBatch(db);
  let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,month,podName)
  batch.update(docRef,replacmentObject)
  batch.update(docRef,{[oldDescription]: deleteField()})

  await batch.commit().then(() => {
    return true;
  }).catch(() => {
    return false
  })

  return true;
}catch(e){
  uploadError(e.message,'BudgetMethods.js, replaceBudgetPod')
}
}

export async function updateMonthlySavingsGoalHelper(value){

  try{
    const userUid = getAuth().currentUser.uid;
    const goalRef = doc(db,'EuklesUsersData',userUid,'MonthlySavingsGoal','goal')
    await updateDoc(goalRef,{value:parseFloat(value)})
  }catch(e){
    uploadError(e.message,'BudgetMethods.js, updateMonthlySavingsGoalHelper')
  }
 
 

}


export async function updateSpentValuesHelper(spentValuesArray){


try{
  spentValuesArray = spentValuesArray.filter(obj => obj.podName !== 'Delete')
  for(let i = 0; i < spentValuesArray.length; i++){
    let transactionAmount = spentValuesArray[i].amount
    let monthYear = spentValuesArray[i].transactionMonthAndYear
    let podName = spentValuesArray[i].podName
    let lineItem = spentValuesArray[i].lineItem
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid, monthYear,podName)
   //const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(monthYear).doc(podName)
    const fieldToUpdate = lineItem.concat('.spentValue')
    const fieldToUpdatePoop = lineItem.concat('.plannedValue')
    const podNameUpdate = lineItem.concat(".podName")
  
  
    let updateSpent = await updateDoc(docRef,{
      [fieldToUpdate]: increment(transactionAmount),
      [fieldToUpdatePoop]:increment(0),
      [podNameUpdate]:podName
    })
  
  
      if(updateSpent === 'firestore/not-found'){
        let b = await setDoc(docRef,{
          [lineItem]:{
            plannedValue:0.00,
            spentValue:transactionAmount,
            podName:podName
          }
        })
        return b;
  
      }
      else {
        return updateSpent
      }
  }

}catch(e){
  uploadError(e.message,'BudgetMethods.js, updateSpentValuesHelper')
}




 
}


export async function updateSpentValueAfterTracking(amount,podTitle,lineItemDescription,monthYear){


  try{
    const userUid = getAuth().currentUser.uid;
    
    const docRef = doc(db,'EuklesUsersData',userUid,monthYear,podTitle)
    const fieldToUpdate = lineItemDescription.concat('.spentValue')
    const fieldToUpdatePlanned = lineItemDescription.concat('.plannedValue')
    const podNameUpdate = lineItemDescription.concat(".podName")

    let updateSpent = await updateDoc(docRef,{
      [fieldToUpdate]: increment(amount),
      [fieldToUpdatePlanned]:increment(0),
      [podNameUpdate]:podTitle
    }).then(() => {
      return true;
    }).catch(e => {
      return {errorInDocRefUpdate: true, errorMessage: e}
    })


    if(updateSpent === 'firestore/not-found'){
      await setDoc(docRef,{[lineItemDescription]:{
        plannedValue:0.00,
        spentValue:amount,
        podName:podTitle
      }})
  
   
      }

      return updateSpent

}catch(e){

}

  
  
  
  
   
  }





