import { AltDataContext } from 'contexts/AltDataContext'
import React,{useState,useContext} from 'react'
import TinyYellow from '../../graphics/smallsingleyellow.svg'
import { useTheme,Typography, Button, DialogTitle, DialogContent, DialogActions, Dialog, Select, MenuItem  } from '@mui/material'
import { getBudgetPodsByMonth, handleCreateScratchBudget,calculateTotalsHelper, handleCopyBudgetMonth } from 'utils/BudgetMethods'
import { LoadingButton } from '@mui/lab'




export default function YouNeedABudgetDialog ({closeDialog,emptyBudgetMonthQueried,showNoBudget}){

    const [selectBudgetOption, setSelectBudgetOption] = useState('Start from scratch')
    const [loading,setLoading] = useState(false)
    const {state,dispatch} = useContext(AltDataContext)
    const theme = useTheme();

    async function copyBudgetFromAnotherMonth(monthSelected){

      if(monthSelected === 'Start from scratch'){
        setLoading(true);
        await handleCreateScratchBudget(emptyBudgetMonthQueried)
       let podsArray = await getBudgetPodsByMonth(emptyBudgetMonthQueried)
        dispatch({type:'UPDATE_PODS',payload:podsArray})
        const {plannedExpenses,plannedIncome,actualExpenses,actualIncome,totalPlannedSavings,totalActualSavings} = calculateTotalsHelper(podsArray)
      dispatch({type:'UPDATE_PLANNED_TOTALS',payload:{income:plannedIncome,expenses:plannedExpenses}})
      dispatch({type:'UPDATE_ACTUAL_TOTALS',payload:{income:actualIncome,expenses:actualExpenses}})
      dispatch({type:'UPDATE_SAVINGS_TOTALS',payload:{planned:totalPlannedSavings,actual: totalActualSavings}})
      let progress = parseFloat(totalActualSavings)/parseFloat(state.monthlySavingsGoal)
      dispatch({type:'UPDATE_MONTHLY_SAVINGS_GOAL_PROGRESS',payload:progress})
      setLoading(false)
      dispatch({type:'UPDATE_BUDGET_MONTH',payload:emptyBudgetMonthQueried})
      closeDialog();

      }
      else {
        setLoading(true)
        await handleCopyBudgetMonth(monthSelected,emptyBudgetMonthQueried);
        let podsArray = await getBudgetPodsByMonth(emptyBudgetMonthQueried)
        dispatch({type:'UPDATE_PODS',payload:podsArray})
        const {plannedExpenses,plannedIncome,actualExpenses,actualIncome,totalPlannedSavings,totalActualSavings} = calculateTotalsHelper(podsArray)
      dispatch({type:'UPDATE_PLANNED_TOTALS',payload:{income:plannedIncome,expenses:plannedExpenses}})
      dispatch({type:'UPDATE_ACTUAL_TOTALS',payload:{income:actualIncome,expenses:actualExpenses}})
      dispatch({type:'UPDATE_SAVINGS_TOTALS',payload:{planned:totalPlannedSavings,actual: totalActualSavings}})
      let progress = parseFloat(totalActualSavings)/parseFloat(state.monthlySavingsGoal)
      dispatch({type:'UPDATE_MONTHLY_SAVINGS_GOAL_PROGRESS',payload:progress})

      setLoading(false)
      dispatch({type:'UPDATE_BUDGET_MONTH',payload:emptyBudgetMonthQueried})
      closeDialog();
      setLoading(false);
      //   let copyMonth = httpsCallable(functions,'copyBudgetFromAnotherMonth');
      //   let copyResult = await copyMonth({monthToCopy: monthSelected, newMonth: emptyBudgetMonthQueried}).then(function(result) {
      //     return result;
      //   }).catch(function(error){
      //     return error;
      //   })

      //   let monthsDoc = doc(db,'EuklesUsersData',userUid,'MonthsWithBudgets','months')
      // await updateDoc(monthsDoc,{
      //   mArray: arrayUnion(selectedMonth)
      // })
        
         
      }

    
      // if(copyResult !== false){
    
      //   let monthsDoc = doc(db,'EuklesUsersData',userUid,'MonthsWithBudgets','months')
      // await updateDoc(monthsDoc,{
      //   mArray: arrayUnion(selectedMonth)
      // })
    
      // getBudgetPodsHome();
      // }
    
    
    
     
    }

    async function handleCreateBudgetSelection(){

    }

  if(!showNoBudget){
    return null
  }
  else {
    return(
      <Dialog fullWidth open={showNoBudget} style={{overflowX:'hidden'}}>
          <DialogTitle id="alert-dialog-title" style={{textAlign:'center', backgroundColor: theme.palette.primary.dark, maxHeight:100, color:'white', fontWeight:'bold'}}>
            <Typography variant='h4' style={{fontWeight:'700', paddingTop:20}}>Create a budget </Typography>
            <img src={TinyYellow} alt=''  style={{marginTop:-30}}></img>
        </DialogTitle>
      
      <DialogContent style={{display:'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center'}}>
      
        <Typography variant='body1' style={{fontWeight:'700', paddingTop:20, fontColor: theme.palette.text.primary}}> We didn't find a budget for {emptyBudgetMonthQueried}</Typography>
          <div>
          <Typography variant='body1' style={{fontWeight:'700', paddingTop:20, fontColor: theme.palette.text.secondary, marginBottom:5}}>Select an Option</Typography>
          </div>
        <div>
        <Select fullWidth disableUnderline = {true} style={{fontSize:'15px', fontWeight:'400', textAlign:'center'}}
                labelId="demo-simple-select-label"id="demo-simple-select"value={selectBudgetOption}
                onChange={(e) => setSelectBudgetOption(e.target.value)}>
                                      <MenuItem value='Start from scratch'>Start from scratch</MenuItem>
                                      {state.budgetMonthsArray.map((item,i) => {
                                              return(
                                                  <MenuItem key={i.toString()} value={item}>Copy {item}</MenuItem>
                                              )
                                            })}
        </Select>
            </div>
            </div>

         
      </DialogContent>

      <DialogContent style={{display:'flex', flexDirection: 'row', justifyContent:'center', alignItems:'center'}}>
           <DialogActions>
      
             <LoadingButton variant='contained' loading={loading}
             style={{backgroundColor: theme.palette.primary.yellow, minWidth:125, color:'white', fontWeight:'bold'}} 
             onClick={() => copyBudgetFromAnotherMonth(selectBudgetOption)} >
               Submit
             </LoadingButton>
          
           </DialogActions>
           </DialogContent>

   
    </Dialog>

  )
  }
 
}



         
      