
import Axios from 'axios';
import React,{Component, createContext} from 'react'


import { firebaseConfig } from "config";
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, FacebookAuthProvider, getAuth, OAuthProvider,  GoogleAuthProvider,  signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { collection, doc, getFirestore, setDoc, getDoc, getDocs, updateDoc, deleteDoc, deleteField, where, query } from "firebase/firestore";
import { months } from 'utils/Sampledata';
import { getFunctions, httpsCallable } from "firebase/functions";


import { getAnalytics, logEvent } from 'firebase/analytics';



export const BudgetContextTwo = createContext();

let theConnectedArray = [];

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const functions = getFunctions();
const analytics = getAnalytics();


export default class BudgetContextTwoProvider extends Component{


    constructor(props){
        super(props);
        this.state = {
            isAuthenticated: false,
            isEmailVerified: false,
            isNewUser:false,
            isPremiumUser: false,
            isUpdating: false,
            
            userUid:'',

            //******BUDGET******* */
            overSpentCount:0,
            selectedMonth: this.setCurrentMonth(),
            transactionsArrayNew: [],

            //******INVESTMENTS******** */
            assetsSyncedAccountsArray: [],
            liabilitiesArray: [],

            //********************** */

            //*****finicity***** */
            finicityLoading: false,
            finicityLoadingMessage:'',
            accountsForCashFlow: [],
            accountsForPending: [],
            stateFixAccountsArray: [],
            theStateLevelConnectedAccounts: [],
            finCustomerId: '',
            refreshing: true,
            openMobileMenu: false, 
            closeMobileMenu: false
        }

        //*************AUTH************************** */
        this.checkIfEmailIsVerified = this.checkIfEmailIsVerified.bind(this);
        this.createNewUserObject = this.createNewUserObject.bind(this);
        //this.createMonthlySavingsGoal = this.createMonthlySavingsGoal.bind(this);
        this.handleCheckIfNewUser = this.handleCheckIfNewUser.bind(this);
        this.handleSignWithEmailAndPassword = this.handleSignWithEmailAndPassword.bind(this);
        this.handleSignUpWithApple  = this.handleSignUpWithApple.bind(this);
        this.handleSignUpWithFacebook = this.handleSignUpWithFacebook.bind(this);
        this.handleSignInWithApple = this.handleSignInWithApple.bind(this);
        this.handleSignInWithFacebook = this.handleSignInWithFacebook.bind(this);
        this.handleSignInWithGoogle = this.handleSignInWithGoogle.bind(this);
        this.handleSignUpWithGoogle = this.handleSignUpWithGoogle.bind(this);
        this.getUserProfileInfo = this.getUserProfileInfo.bind(this);
        this.logOut = this.logOut.bind(this);
        this.processStripeCancellationDatabase = this.processStripeCancellationDatabase.bind(this);
        this.sendVerificationCode = this.sendVerificationCode.bind(this);
        this.signUpWithEmailAndPassword = this.signUpWithEmailAndPassword.bind(this);
        this.submitVerificationCode = this.submitVerificationCode.bind(this);
        this.updateIsPremiumUser = this.updateIsPremiumUser.bind(this);
        this.updateStripeSubscriptionId = this.updateStripeSubscriptionId.bind(this);
  //*************END AUTH************************** */

        this.handleSetOverSpentCount = this.handleSetOverSpentCount.bind(this);
        this.handleDeleteTransactions =this.handleDeleteTransactions.bind(this);
        this.updateSelectedMonth = this.updateSelectedMonth.bind(this);
        this.handleDeleteTransaction = this.handleDeleteTransaction.bind(this);
        this.handleCheckTransaction = this.handleCheckTransaction.bind(this);
        this.handleResetAccounts = this.handleResetAccounts.bind(this);
        this.handleSetManualAssets = this.handleSetManualAssets.bind(this);
        this.handleSetManualLiabilities = this.handleSetManualLiabilities.bind(this);
        this.getManualAssets = this.getManualAssets.bind(this);
        this.getManualAssetsStepTwo = this.getManualAssetsStepTwo.bind(this);
        this.getManualLiabilities = this.getManualLiabilities.bind(this);
        this.getManualAssetsStepTwo = this.getManualAssetsStepTwo.bind(this);
        this.handleDeleteSelectedTransactionsContext = this.handleDeleteSelectedTransactionsContext.bind(this);

        //FINICITY
        this.getFastFinAccounts = this.getFastFinAccounts.bind(this);
        this.getFinTransactions = this.getFinTransactions.bind(this);
        this.getRefreshedFinAccounts = this.getRefreshedFinAccounts.bind(this);
        this.retrieveInstitutionName = this.retrieveInstitutionName.bind(this);

        this.handleTransTemporary = this.handleTransTemporary.bind(this);

        this.handleMobileMenuClickOpen = this.handleMobileMenuClickOpen.bind(this);
        this.submitError = this.submitError.bind(this);
        

  
        this.toggleIsPremiumUser = this.toggleIsPremiumUser.bind(this);
        this.toggleFinicityId = this.toggleFinicityId.bind(this);
    }


    toggleIsPremiumUser(value){
        this.setState({
            isPremiumUser:value
        })
    }

    toggleFinicityId(value){
        this.setState({
            finCustomerId: value
        })
    }

        
    async  checkIfEmailIsVerified(){
        let stateRef = this;
      
        let docRef = doc(db,'EuklesUsers',stateRef.state.userUid);
        let userObject = await getDoc(docRef).then(function(doc) {
                          //let emailVerified = doc.data().isEmailVerified;
                  let isPremiumUser = doc.data().isPremiumUser;
                  let finCustomerId = doc.data().finCustomerId;
            return {isEmailVerified: true, isPremiumUser: isPremiumUser, finCustomerId: finCustomerId, error: false, errorMessage:''};
        }).catch(function(error) {

            return {isEmailVerified: true, isPremiumUser: false, finCustomerId: '', error: true, errorMessage: error};
        })


       
        if(userObject.isEmailVerified){
            stateRef.setState({
                isEmailVerified: true,
                isPremiumUser: userObject.isPremiumUser,
                finCustomerId: userObject.finCustomerId,
                isNewUser: false

            })
         stateRef.handleCheckIfNewUser();
        }
        return userObject;

    
    }

    determineEarliestEpoch() {
        let date = new Date().toISOString().slice(0,8)
        date = date.concat('01')
     
        let startEpoch = new Date(date).getTime()/1000;

     return startEpoch;
    }

   getPreviousMonth(monthDigit){
        switch(monthDigit) {
          case '01':
            return '12'
            
            case '02':
              return '01'
  
              case '03':
                return '02'
  
                case '04':
                  return '03'
  
                  case '05':
                    return '04'
  
                    case '06': 
                    return '05'
  
                    case '07':
                      return '06'
                    
                      case '08':
                        return '07'
  
                        case '09':
                          return '08'
  
                          case '10':
                            return '09'
  
                            case '11':
                              return '10'
  
                              case '12': 
                              return '11'
  
                              default:
                                return '01'
  
  
  
          }
      }

    

    async createNewUserObject(){

     
        let date = new Date();
        let month = date.getMonth();
        let year = date.getFullYear();
        
        let earliestEpoch = this.determineEarliestEpoch();
        
        const monthYearString = months[month] + ' ' + year;
        //let monthObject = {value: monthYearString}
        let currentEpoch = Math.floor(Date.now()/1000);
        
        const user = getAuth().currentUser
     
        let userObject = {
            actualExpenses:0,
            actualIncome: 0,
            actualSavings:0,
            advisorViewBudget: false,
            advisorViewInvestments: false,
            assets: 0,
            completedAddTransactionTips: false,
            completedAddRuleTips: false,
            completedBudgetToolTips: false,
            completedMobileBudgetTips:false,
            completedMobileCashFlowTips:false,
            completedMobileInvestmentTips:false,            
            completedOnboarding: false,
            completedInvestmentOnboarding: false,
            creationTime: user.metadata.creationTime,
            createionEpoch: currentEpoch,
            email: user.email,
            isEmailVerified: true,
            isPremiumUser: false,
            enableTwoFactor: false,
            euklesClient: false,
            hasFinAccounts: false,
            finCreationDate:'nil',
            finCustomerId: 'nil',
            finCustomerUrl: 'nil',
            finUrlUpdateTime: 'nil',
            lastSignInEpoch: currentEpoch,
            //lastSignInFormatted: lastSignInFormatted,   
            lastSignInTime: user.metadata.lastSignInTime,
            liabilities: 0,
            monthlySavingsGoal:0,
            netWorth: 0,      
            noCashFlowSet: true,
            plannedIncome: 0,          
            plannedExpenses:0,       
            plannedSavings:0,         
            overSpentCount: 0,        
          
            planningEnabled: false,
            requestedVerification: false,
           
            signUpPlatform:'web',
            startDate: monthYearString,
            startingEpoch: earliestEpoch,
            subscribed: false,  
            subscriptionMethod:'none',      
            userUid: user.uid,

            finishedInitialBudget: false,
            mostlyZeroesInBudget: true,
            addedTransaction: false,
            hasAddedTransaction:false,
            hasAddedBudgetGroup: false,
            hasAddedNetWorthGroup: false,
            hasAutoRules:false,
            emailNotificationsAllowed:true,
            showPaymentError:false,
            paymentErrorMessage:'',
        }


     let docRef = doc(db,'EuklesUsers', this.state.userUid);
    let creation =  await setDoc(docRef, userObject).then(res => {

        return {error: false, errorMessage:''}
    }).catch((e) => {
        return {error: true, errorMessage: e};

    })
    if(creation.error){
        this.submitError(creation.errorMessage)
    }

   
 

    try{
        logEvent(analytics, 'web_sign_up',{
            platform: 'web',
           
        })
    }catch(e){
    }    


     


    return creation;
    
   
     
    }



    // async createMonthlySavingsGoal(){
    //     let mDocRef = doc(db,'EuklesUsersData', this.state.userUid, 'MonthlySavingsGoal', 'goal');

    //    let create = await setDoc(mDocRef,{value: 0.00}).then((res) => {
    //        return true;
    //    }).catch((e) => {
    //        return false;
    //    })
    //    return create;
    // }

    async handleCheckIfNewUser(){

     
        
        this.getManualAssets();
        this.getManualLiabilities();
        
        // if(currentUser.metadata.lastSignInTime === currentUser.metadata.creationTime){
          
        //     this.setState({
        //         isNewUser: true
        //     })
        // }

        // else {
         
           
        //  this.getManualAssets();
        //     this.getManualLiabilities();
        // }

     
       

        if(this.state.isPremiumUser){
         
           let currentEpoch = Math.floor(Date.now()/1000);

          // this.getConnectedAccountNames();
          let docRef = doc(db,'EuklesUsers', this.state.userUid)
         let lastRefresh = await getDoc(docRef).then((doc) => {
            return doc.data().lastSignInEpoch;
          }).catch(()=> {
              return currentEpoch;
          })

          let net = parseInt(currentEpoch) - parseInt(lastRefresh);
          this.getRefreshedFinAccounts();
         //this.getFastFinAccounts();
          if(net > parseInt(9000)){
            //this.getConnectedAccountNames();
            this.getRefreshedFinAccounts();
          }
          else {
             //this.getConnectedAccountNames();
           //  this.getFastFinAccounts();
          }
           
        }

        let theEpoch = Math.floor(Date.now()/1000);
        let docRef = doc(db,'EuklesUsers', this.state.userUid);
        await updateDoc(docRef,{lastSignInEpoch: theEpoch})


    }

    async handleSignUpWithFacebook(){
        let stateRef = this;
        const provider = new FacebookAuthProvider();
        const auth = getAuth();
       let userObject = await signInWithPopup(auth, provider).then((result) => {
          
 
            return {authenticated: true, userEmail: result.user.email, userUid: result.user.uid ,errorMessage:'none', isNewUser: true}
        }).catch((error) => {
          
       
            const message = error.message;
       
            const email = error.email;
     
            return {authenticated: false, userEmail: email, userUid: '', errorMessage: message, isNewUser: false}
        })

        if(userObject.authenticated){
          
            stateRef.setState({
                isAuthenticated: true,
                isEmailVerified: false,
                userEmail: userObject.userEmail,
                userUid: userObject.userUid,

            })



            }

        return userObject;
    }

    async handleSignUpWithGoogle(){
        let stateRef = this;
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
       let userObject = await signInWithPopup(auth, provider).then((result) => {
          
    
            return {authenticated: true, userEmail: result.user.email, userUid: result.user.uid ,errorMessage:'none', isNewUser: true}
        }).catch((error) => {
          
    
            const message = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
         
            return {authenticated: false, userEmail: email, userUid: '', errorMessage: message, isNewUser: false}
        })

        if(userObject.authenticated){
          
            stateRef.setState({
                isAuthenticated: true,
                isEmailVerified: false,
                userEmail: userObject.userEmail,
                userUid: userObject.userUid,

            })



            }

        return userObject;
    }



    async handleSignUpWithApple (){
        let stateRef = this;
        const provider = new OAuthProvider('apple.com')
        const auth = getAuth();
       let userObject = await signInWithPopup(auth, provider).then((result) => {
            //const credential = GoogleAuthProvider.credentialFromResult(result)
            //const token = credential.accesstoken;
       
        
            return {authenticated: true, userEmail: result.user.email, userUid: result.user.uid ,errorMessage:'none', isNewUser: true}
        }).catch((error) => {
          
        
            const message = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            //const credential = GoogleAuthProvider.credentialFromError(error);
            return {authenticated: false, userEmail: email, userUid: '', errorMessage: message, isNewUser: false}
        })



        if(userObject.authenticated){
          
            stateRef.setState({
                isAuthenticated: true,
                isEmailVerified: false,
                userEmail: userObject.userEmail,
                userUid: userObject.userUid,

            })



            }

        return userObject;
    }

    async handleSignInWithFacebook(){
        let stateRef = this;
        const provider = new FacebookAuthProvider();
        const auth = getAuth();
       let userObject = await signInWithPopup(auth, provider).then((result) => {
           
            return {authenticated: true, userEmail: result.user.email, userUid: result.user.uid ,errorMessage:''}
        }).catch((error) => {
       
        
            const errorMessage = error.message;
       
            return {authenticated: false, userEmail: error.email, userUid: '', errorMessage: errorMessage}
        })

        if(userObject.authenticated){
            stateRef.setState({
                canEnter: true,
                isAuthenticated: true,
                userEmail: userObject.email,
                userUid: userObject.userUid,
                isNewUser: false
                

            })
       
            
     
            //stateRef.checkIfEmailIsVerified();
        }
        else if(!userObject.authenticated){
          
            stateRef.setState({
                showLoginError: true,
                loginErrorMessage: userObject.errorMessage, 
                isAuthenticated: false,
                userUid: ''
            })
        }
        return userObject;
    }

    async handleSignInWithGoogle(){
        let stateRef = this;
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
       let userObject = await signInWithPopup(auth, provider).then((result) => {
         
            return {authenticated: true, userEmail: result.user.email, userUid: result.user.uid ,errorMessage:''}
        }).catch((error) => {
    
          
            const errorMessage = error.message;
        
            return {authenticated: false, userEmail: error.email, userUid: '', errorMessage: errorMessage}
        })

        if(userObject.authenticated){
            stateRef.setState({
                canEnter: true,
                isAuthenticated: true,
                userEmail: userObject.email,
                userUid: userObject.userUid,
                isNewUser: false
                

            })
       
            
     
            //stateRef.checkIfEmailIsVerified();
        }
        else if(!userObject.authenticated){
          
            stateRef.setState({
                showLoginError: true,
                loginErrorMessage: userObject.errorMessage, 
                isAuthenticated: false,
                userUid: ''
            })
        }
        return userObject;
    }

    async handleSignInWithApple(){
        let stateRef = this;
        const provider = new OAuthProvider('apple.com')
        const auth = getAuth();
       let userObject = await signInWithPopup(auth, provider).then((result) => {
       
            return {authenticated: true, userEmail: result.user.email, userUid: result.user.uid ,errorMessage:''}
        }).catch((error) => {
      
   
            const errorMessage = error.message;
          
            return {authenticated: false, userEmail: error.email, userUid: '', errorMessage: errorMessage}
        })

        if(userObject.authenticated){
            stateRef.setState({
                canEnter: true,
                isAuthenticated: true,
                userEmail: userObject.email,
                userUid: userObject.userUid,
                isNewUser: false
                

            })
       
            
     
            //stateRef.checkIfEmailIsVerified();
        }
        else if(!userObject.authenticated){
          
            stateRef.setState({
                showLoginError: true,
                loginErrorMessage: userObject.errorMessage, 
                isAuthenticated: false,
                userUid: ''
            })
        }
        return userObject;
    }

    
    async handleSignWithEmailAndPassword(email, password) {
        // e.preventDefault();
        this.determineEarliestEpoch();
         let stateRef = this;
      
        
            let userObject = await signInWithEmailAndPassword(auth, email, password).then((result) => {

           
          //           stateRef.getBudgetPods();
        // stateRef.getManualAssets();
        // stateRef.getManualLiabilities();
                return {authenticated: true, userEmail: email, userUid: result.user.uid ,errorMessage:''}
            }).catch((e) => {
               // console.log('error ', e.code);
                let errorMessage = ''
                if(e.code === 'auth/wrong-password'){
                    errorMessage = 'Password is incorrect'
                }
                if(e.code === 'auth/user-not-found'){
                    errorMessage = 'User record does not exist'
                }
                if(e.code === 'auth/too-many-requests'){
                    errorMessage = e.message;
                }
             
              //  showLoginError:false,
               // loginErrorMessage: '',
               return {authenticated: false, userEmail: email, userUid: '', errorMessage: errorMessage}
            })


            if(userObject.authenticated){
                stateRef.setState({
                    canEnter: true,
                    isAuthenticated: true,
                    userEmail: email,
                    userUid: userObject.userUid,
                    isNewUser: false
                    

                })
           
                
         
                //stateRef.checkIfEmailIsVerified();
            }
            else if(!userObject.authenticated){
              
                stateRef.setState({
                    showLoginError: true,
                    loginErrorMessage: userObject.errorMessage, 
                    isAuthenticated: false,
                    userUid: ''
                })
            }

            return userObject;

     }

     async  getUserProfileInfo(){
        let stateRef = this;
      
        let docRef = doc(db,'EuklesUsers',stateRef.state.userUid);
        let userObject = await getDoc(docRef).then(function(doc) {
                          //let emailVerified = doc.data().isEmailVerified;
                  let isPremiumUser = doc.data().isPremiumUser;
                  let finCustomerId = doc.data().finCustomerId;
            return {isPremiumUser: isPremiumUser, finCustomerId: finCustomerId, error: false, errorMessage:''};
        }).catch(function(error) {

            return {isEmailVerified: true, isPremiumUser: false, finCustomerId: '', error: true, errorMessage: error};
        })


       //console.log('second user object ', userObject);
        if(!userObject.error){
            stateRef.setState({
              
                isPremiumUser: userObject.isPremiumUser,
                finCustomerId: userObject.finCustomerId,
               

            })
         stateRef.getUserAssetsLiabilitiesAndCheck();
        }
        return userObject;

    
    }

    async getUserAssetsLiabilitiesAndCheck(){
      
     
        
        this.getManualAssets();
        this.getManualLiabilities();


     
       

        if(this.state.isPremiumUser){
         
           let currentEpoch = Math.floor(Date.now()/1000);

          // this.getConnectedAccountNames();
          let docRef = doc(db,'EuklesUsers', this.state.userUid)
         let lastRefresh = await getDoc(docRef).then((doc) => {
            return doc.data().lastSignInEpoch;
          }).catch(()=> {
              return currentEpoch;
          })

          let net = parseInt(currentEpoch) - parseInt(lastRefresh);
        
         this.getFastFinAccounts();
          if(net > parseInt(9000)){
            //this.getConnectedAccountNames();
            this.getRefreshedFinAccounts();
          }
          else {
       
          }
           
        }

        let theEpoch = Math.floor(Date.now()/1000);
        let docRef = doc(db,'EuklesUsers', this.state.userUid);
        await updateDoc(docRef,{lastSignInEpoch: theEpoch})


    }

     async sendVerificationCode() {
        const user = getAuth().currentUser;
         let email = user.email;
         let uid = user.uid;
 
         const customerData = {email, uid};
  
         let configObject = {
           method: 'post',
           url: 'https://us-central1-budgetbettereukles.cloudfunctions.net/sendVerificationCode',
           headers: { 
             'Content-Type': 'application/json', 
             'Accept': 'application/json',
           
       
           },
           data : customerData
         };
       
      let codeSent = await Axios(configObject).then(function(response) {
             
            return {error: false, errorMessag:''}
          
         }).catch(function(error){
          
            return {error: true, errorMessage: error}
         })

         if(codeSent.error){
             this.submitError(codeSent.errorMessage);
         }
 
         return codeSent;
 
     }

     async logOut(){
        let stateRef = this;
      

      await auth.signOut().then((result) => {
        stateRef.setState({
            isAuthenticated: false,
            userUid: '',
            userEmail: '',
            assetsSyncedAccountsArray: [],
            liabilitiesArray: []
        })
           return true;
        }).catch((e) => {
            stateRef.setState({
                isAuthenticated: false,
                userUid: '',
                userEmail: '',
                assetsSyncedAccountsArray: [],
                liabilitiesArray: []
            })
           // console.log('sign out error ', e)
            return false;
        })

        // if(signOutResult){
       
        //    stateRef.setState({
        //        isAuthenticated: false,
        //        userUid: '',
        //        userEmail: ''
        //    })
        // }
        // else if(!signOutResult){
        //    stateRef.setState({
        //        isAuthenticated: false,
        //        userUid: '',
        //        userEmail: ''
        //    })
        // }
    }

    
    async processStripeCancellationDatabase(){
        this.setState({
            isPremiumUser: false,
          
        })
    
        


    }

   async signUpWithEmailAndPassword(email, password){
       let stateRef = this;
    
       // console.log('the email ', email);
        const auth = getAuth();
       let userObject = await createUserWithEmailAndPassword(auth, email, password).then((result) => {

           return {authenticated: true, userEmail: email, userUid: result.user.uid ,errorMessage:'none', isNewUser: true}
       }).catch((e) => {
       
           
          
            let message = 'Error creating user'
            if(e.code === 'auth/email-already-in-use'){
                message = 'Email is already in use'
            }
            if(e.code === 'auth/invalid-password'){
                message = 'Invalid password'
            }
         //  showLoginError:false,
          // loginErrorMessage: '',
          return {authenticated: false, userEmail: email, userUid: '', errorMessage: message, isNewUser: false}
       })

       if(userObject.authenticated){
          
                            stateRef.setState({
                                isAuthenticated: true,
                                isEmailVerified: false,
                                userEmail: email,
                                userUid: userObject.userUid,

                            })


       }

       else if(!userObject.authenticated && userObject.errorMessage !== 'none'){
        
        
       }



       return userObject;

 

    
   }

   async submitVerificationCode(submittedCode){

  
       let stateRef = this;
    
       const user = getAuth().currentUser;
           let uid = user.uid;

           const customerData = {uid, submittedCode}

           let configObject = {
               method: 'post',
               url: 'https://us-central1-budgetbettereukles.cloudfunctions.net/checkVerificationCode',
               headers: { 
                 'Content-Type': 'application/json', 
                 'Accept': 'application/json',
               
           
               },
               data : customerData
             };

    let  codeResult = await Axios(configObject).then(function(response){

        let canProceed = response.data.accessPermitted;
       

        return{error: false, errorMessage:'', emailVerified: canProceed}
    }).catch(function(error){

        return {error:true, errorMessage:'Incorrect code', emailVerified: false }
    })
   

    if(!codeResult.error){
        if(codeResult.emailVerified){
            stateRef.setState({
                
                isEmailVerified: true
            })

            
           
        }
    }

    

         return codeResult;    
       
   }

   async submitError(errorMessage){

  
  
 
    const user = getAuth().currentUser;
        let uid = user.uid;

        const customerData = {uid, errorMessage}

        let configObject = {
            method: 'post',
            url: 'https://us-central1-budgetbettereukles.cloudfunctions.net/reportErrorToHelp',
            headers: { 
              'Content-Type': 'application/json', 
              'Accept': 'application/json',
            
        
            },
            data : customerData
          };

 await Axios(configObject).then(function(response){

    // let canProceed = response.data.accessPermitted;
    

     return true;
 }).catch(function(error){

     return false;
 })


 return true;
}

     setCurrentMonth(){
        let date = new Date();
        let month = date.getMonth();
        let year = date.getFullYear();
    
    
        const monthYearString = months[month] + ' ' + year;
         
     
        return monthYearString;
    }




    async updateIsPremiumUser(finCustomerId){
        this.setState({
            isPremiumUser: true,
            //subscriptionMethod: 'web',
            //finCustomerId: finCustomerId
        })
        // let docRef = doc(db,'EuklesUsers', this.state.userUid);
        // await updateDoc(docRef,{isPremiumUser: true, subscriptionMethod:'web', finCustomerId: finCustomerId})
       
    }



    async updateStripeSubscriptionId(subscriptionId){
        let docRef = doc(db,'EuklesUsers', this.state.userUid);
        await updateDoc(docRef,{stripeSubscriptionId: subscriptionId})
    

    }








    ///*******BUDGET HOME******** */


    handleTransTemporary(transArray){
        this.setState({
            transactionsArrayNew: transArray
        })
    }
    handleDeleteTransaction(position){
        let currentArray = [...this.state.transactionsArrayNew]
        currentArray.splice(position,1);
        this.setState({
            transactionsArrayNew: currentArray
        })
    }

    async  handleDeleteSelectedTransactionsContext(selectedArray){
      
        for(let i = 0; i < selectedArray.length; i++){

        }
    }

    handleSetOverSpentCount(value){
        this.setState({
            overSpentCount: parseInt(value)
        })
    }

    updateSelectedMonth (value){
        this.setState({
            selectedMonth: value
        })
    }

    //********END BUDGET */


  
     //****************************INVESTMENT METHODS************************************** */

     handleSetManualAssets(assetObject){
        let currentAssetArray = this.state.assetsSyncedAccountsArray;
        currentAssetArray.push(assetObject)
       
     
    
        this.setState({
            assetsSyncedAccountsArray: currentAssetArray,
         
        })
    
    }


    
    handleSetManualLiabilities(liabilityObject){
    
      
        let currentArray= this.state.liabilitiesArray;
        currentArray.push(liabilityObject);
    
        this.setState({
          
             liabilitiesArray: currentArray
        })
    
    }

     async getManualAssets(){
        let stateRef = this;
      //  await getDocs(collection(db,'EuklesUsersData',userUid, 'ManualAssets'))
     
           
 let assets = await getDocs(collection(db,'EuklesUsersData',stateRef.state.userUid, 'ManualAssets')).then(function(querySnapshot){
            let theArray = [];
          
             if(querySnapshot.size > 0){

               
                querySnapshot.forEach(function(doc){
                   theArray.push(doc.id);
                
             // stateRef.getManualAssetsStepTwo(doc.id)
             
    
                   
    
                
    
                })
               
            }

            return theArray;
        }).catch((error) => {

            return false;
        })
    
      
       for(let i = 0; i < assets.length; i++){
           this.getManualAssetsStepTwo(assets[i])
       }
        //this.getManualAssetsStepTwo('Personal Assets')
    
    }

   
        async getManualAssetsStepTwo(docId){
        let stateRef = this;
   
       // await getDocs(collection(db,'EuklesUsersData',userUid, 'ManualAssets',docId, 'Accounts'))
         let theAssets =  await getDocs(collection(db,'EuklesUsersData', stateRef.state.userUid, 'ManualAssets',docId, 'Accounts')).then(function(querySnapshot){
                let localArray = [];
               if(querySnapshot.size <= 0){
                
                   return false;
               }
               else if(querySnapshot.size > 0){
              //    let localAssetArray = [];
                  let accountObject = {institution: docId, institutionDocId: docId, itemId: 'manual', data:[]}
                   
                   querySnapshot.forEach(function(doc){
                     let theBalance = parseFloat(doc.data().balance);
                     if(isNaN(theBalance)){
                         theBalance = 0.00;
                     }
                     let theAvailable = parseFloat(doc.data().available);
                     if(isNaN(theAvailable)){
                         theAvailable = 0.00;
                     }
                        //*****NEED TO CREATE PARENT OBJECT AND PUSH LINES IN AS DATA */
                       accountObject.data.push( {updateTime: '', 
                       account_id:'manual', 
                       avaialable: theAvailable,
                       balanceDate: '', 
                       balance: theBalance,
                       lineItemDocId: doc.id,
                       official_name: doc.data().official_name,
                       subtype: doc.data().subtype, 
                       itemId: 'manual', 
                       institution: doc.data().institution,
                       institutionLoginId: 'manual', 
                       institutionName: doc.data().institution,
                       type:'Asset'})
               
                           
                       
                      
                      
                
                   })

                   localArray.push(accountObject);
                 
                  
             
     
                   return localArray;
               }
           })
        

           let currentArray = stateRef.state.assetsSyncedAccountsArray;
           for(let i = 0; i < theAssets.length; i++){
            currentArray.push(theAssets[i]);
           }
          
           stateRef.setState({
               assetsSyncedAccountsArray: currentArray
           })
     }

     async getManualLiabilities(){
        let stateRef = this;
    
     
      
        await getDocs(collection(db,'EuklesUsersData',this.state.userUid, 'ManualLiabilities')).then(function(querySnapshot){
            if(querySnapshot.size <= 0){
                //show no budget for this month
                return false;
            }
            else if(querySnapshot.size > 0){
               
                querySnapshot.forEach(function(doc){
                   
              stateRef.getManualLiabilitiesStepTwo(doc.id)
             
    
                   
    
                
    
                })
                return true;
            }
        }).catch((error) => {
            console.log(error)
        })
    
       
    
    }
    
    async getManualLiabilitiesStepTwo(docId){
       let stateRef = this;
   
    
       await getDocs(collection(db,'EuklesUsersData',this.state.userUid, 'ManualLiabilities',docId, 'Accounts')).then(function(querySnapshot){
              if(querySnapshot.size <= 0){
                  //show no budget for this month
                  return false;
              }
              else if(querySnapshot.size > 0){
             //    let localAssetArray = [];
                 let accountObject = {institution: docId, institutionDocId: docId, itemId: 'manual', data:[]}
    
                  querySnapshot.forEach(function(doc){
                    
                       //*****NEED TO CREATE PARENT OBJECT AND PUSH LINES IN AS DATA */
                      accountObject.data.push( {updateTime: '', 
                      account_id:'manual', 
                      avaialable: parseFloat(doc.data().avaialable),
                      balanceDate: '', 
                      balance: parseFloat(doc.data().balance), 
                      lineItemDocId: doc.id,
                      official_name: doc.data().official_name,
                      subtype: doc.data().subtype, 
                      itemId: 'manual', 
                      institution: doc.data().institution,
                      institutionLoginId: 'manual', 
                      institutionName: doc.data().institution,
                      type:'Liabilities'})
              
                          
                      
                     
                     
               
                  })
                
                     let currentArray = stateRef.state.liabilitiesArray;
                     currentArray.push(accountObject);
                     stateRef.setState({
                         liabilitiesArray: currentArray
                     })
                 
            
    
                  return true;
              }
          })
    }

   async handleResetAccounts(institutionLoginId, id){

  
  let institutionId = String(id)

    const theUserUid = this.state.userUid;
      
        const q = query(collection(db,'EuklesUsersData', theUserUid, 'ConnectedAccountsD'), where("institutionId", "==", institutionId))
        const querySnapshot = await getDocs(q)
        let matchingDocs = []
        querySnapshot.forEach((doc) => {
            matchingDocs.push(doc.id)
        })

        let cashFlowRef = doc(db,'EuklesUsersData', theUserUid)
        let primaryAccountId = await getDoc(cashFlowRef).then((doc) => {
            if(typeof doc.data().primaryAccountId === 'undefined'){
                return {exists: false, accountId:'nil', accountName: 'nil'}
            }
            else {
                return {exists: true, accountId: doc.data().primaryAccountId, accountName: doc.data().accountName}
            }
        }).catch((e) => {

            return {exists: false, accountId: 'nil', accountName: 'nil'}
        })

       let cashFlowAccountId = 'nil'
        if(primaryAccountId.exists){
                cashFlowAccountId = primaryAccountId.accountId;
        }

       
        for(let i = 0; i < matchingDocs.length; i++){
           
            let docId = String(matchingDocs[i])

            let docRef = doc(db,'EuklesUsersData', theUserUid, 'ConnectedAccountsD', docId)
            await deleteDoc(docRef)


            if(cashFlowAccountId !== 'nil'){
                if(docId === String(primaryAccountId)){
                    let dDoc = doc(db,'EuklesUsersData',theUserUid)
                    await updateDoc(dDoc,{
                        primaryAccountId: deleteField(),
                        primaryAccountName: deleteField()
                    })
                }
            }
          


            
        }




        //const finAccountsArray = await getDocs(collection(db,'EuklesUsersData', userUid, 'ConnectedAccountsD'))
    }


      //****************************END INVESTMENT METHODS************************************** */
      async getFastFinAccounts(){
 
        let stateRef = this;
        stateRef.setState({
            finicityLoading: true,
            finicityLoadingMessage:'Please wait...',
            refreshing: false
        })
   
   
        let id = String(stateRef.state.finCustomerId)
        let retrieveBalances = httpsCallable(functions,'getFastFinAccounts');
  
        let finAccountsArray = await retrieveBalances({customerId: id}).then(function(result) {
    
          return result.data.accounts;
        }).catch(function(error){
      
          return 'an error has occured';
        })

        
  
      
        let currentAssetArray = stateRef.state.assetsSyncedAccountsArray;
        let currentLiabilityArray = stateRef.state.liabilitiesArray;
        let individualAccounts = [];
        let pendingAccounts = [];
        let fixAccountsArray = [];
  
        for(let i = 0; i < finAccountsArray.length; i++){
         
      
            if(finAccountsArray[i].aggregationStatusCode !== 0 && finAccountsArray[i].aggregationStatusCode !== 419){
                let existingIndex =  fixAccountsArray.findIndex(obj => String(obj.institutionId) === String(finAccountsArray[i].institutionId))

                if(existingIndex === parseInt(-1)){
                    fixAccountsArray.push({institutionLoginId: finAccountsArray[i].institutionLoginId, institutionName: '',
                    institutionId: finAccountsArray[i].institutionId});
                }
               
            }
            
            
            let currentBalance = 'undefined'
            let lastPaymentAmount = 'undefined'
            let paymentMinAmount = 'undefined'
            let statementCloseBalance = 'undefined'
            let lastPaymentDate = 'undefined'
            let paymentDueDate = 'undefined'
            let accountNickname = 'undefined'
            let availableBalanceAmount = 'undefined'
            let interestYtdAmount = 'undefined'
            let interestRate = 'undefined'
  
            if(typeof finAccountsArray[i].detail !== 'undefined'){
              if(typeof finAccountsArray[i].detail.currentBalance !== 'undefined'){
               currentBalance = finAccountsArray[i].detail.currentBalance
        
                
              }
              if(typeof finAccountsArray[i].detail.interestRate !== 'undefined'){
                interestRate = finAccountsArray[i].detail.interestRate
                 
               }

            



              if(typeof finAccountsArray[i].detail.lastPaymentAmount !== 'undefined'){
                  lastPaymentAmount= finAccountsArray[i].detail.lastPaymentAmount
                   
                 }
                 if(typeof finAccountsArray[i].detail.paymentMinAmount!== 'undefined'){
                  paymentMinAmount = finAccountsArray[i].detail.paymentMinAmount
                   
                 }
                 if(typeof finAccountsArray[i].detail.statementCloseBalance !== 'undefined'){
                  statementCloseBalance = finAccountsArray[i].detail.statementCloseBalance
                   
                 }
                 if(typeof finAccountsArray[i].detail.lastPaymentDate !== 'undefined'){
                  lastPaymentDate = finAccountsArray[i].detail.lastPaymentDate
                   
                 }
  
                 if(typeof finAccountsArray[i].detail.paymentDueDate !== 'undefined'){
                  paymentDueDate = finAccountsArray[i].detail.paymentDueDate
                   
                 }
  
                 if(typeof finAccountsArray[i].detail.accountNickname !== 'undefined'){
                  accountNickname = finAccountsArray[i].detail.accountNickname
                   
                 }
  
                 if(typeof finAccountsArray[i].detail.availableBalanceAmount !== 'undefined'){
                  availableBalanceAmount = finAccountsArray[i].detail.availableBalanceAmount
                   
                 }
  
                 if(typeof finAccountsArray[i].detail.interestYtdAmount !== 'undefined'){
                  interestYtdAmount = finAccountsArray[i].detail.interestYtdAmount
                   
                 }
            }
          
          //   if(typeof finAccountsArray[i].detail.lastPaymentAmount !== 'undefined'){
          //     currentBalance = finAccountsArray[i].detail.lastPaymentAmount
          // }
        
          if(finAccountsArray[i].balance < 0){
            if(finAccountsArray[i].type === 'checking' || finAccountsArray[i].type === 'savings' || finAccountsArray[i].type === 'creditCard'){
                pendingAccounts.push({accountId: finAccountsArray[i].id, institutionId: finAccountsArray[i].institutionId, balance: finAccountsArray[i].balance,
                    accountType: finAccountsArray[i].type,logo: '', alternateLogo: '', icon:'', primaryColor: '', tile:'',
                    currentBalance: finAccountsArray[i].detail.currentBalance,
                    interestRate: finAccountsArray[i].detail.interestRate,
                    lastPaymentAmount: finAccountsArray[i].detail.lastPaymentAmount,
                    paymentMinAmount: finAccountsArray[i].detail.paymentMinAmount,
                    statementCloseBalance: finAccountsArray[i].detail.statementCloseBalance,
                    lastPaymentDate: finAccountsArray[i].detail.lastPaymentDate,
                    paymentDueDate: finAccountsArray[i].detail.paymentDueDate,
                    accountNickname: finAccountsArray[i].detail.accountNickname,
                    availableBalanceAmount: finAccountsArray[i].detail.availableBalanceAmount,
                    interestYtdAmount: finAccountsArray[i].detail.interestYtdAmount
                    
                  })
               }
            

              let existingIndex = currentLiabilityArray.findIndex(obj => String(obj.institution) === String(finAccountsArray[i].institutionId))
             
                              if(existingIndex === parseInt(-1)){
                                
                                  let liabilityParent= {
                                      //sample: finAccountsArray[i].fart,
                                      institution: finAccountsArray[i].institutionId,
                                      institutionId: finAccountsArray[i].institutionId,
                                      documentId: finAccountsArray[i].id,
                                      logo: '',
                                      alternateLogo: '',
                                      icon: '',
                                      primaryColor: '',
                                      tile: '',                                   
                                      itemId: 'Finicity',
                                      institutionLoginId: finAccountsArray[i].institutionLoginId,
                                      data:[{id: finAccountsArray[i].id,
                                           number: finAccountsArray[i].number,
                                           realAccountNumberLast4: finAccountsArray[i].realAccountNumberLast4,
                                           official_name: finAccountsArray[i].name,
                                           balance: finAccountsArray[i].balance,
                                        // balance: currentBalance,
                                           type: finAccountsArray[i].type,
                                           subtype: finAccountsArray[i].type,
                                           aggregationStatusCode: finAccountsArray[i].aggregationStatusCode,
                                           status: finAccountsArray[i].status,
                                           customerId: finAccountsArray[i].customerId,
                                           institutionId: finAccountsArray[i].institutionId,
                                           balanceDate: finAccountsArray[i].balanceDate,
                                           aggregationSuccessDate: finAccountsArray[i].aggregationSuccessDate,
                                           aggregationAttemptDate: finAccountsArray[i].aggregationAttemptDate,
                                           createdDate: finAccountsArray[i].createdDate,
                                           currency: finAccountsArray[i].currency,
                                           lastTransactionDate: finAccountsArray[i].lastTransactionDate,
                                           institutionLoginId: finAccountsArray[i].institutionLoginId,
                                           currentBalance: currentBalance,
                                           lastPaymentAmount: lastPaymentAmount,
                                           paymentMinAmount: paymentMinAmount,
                                           statementCloseBalance: statementCloseBalance,
                                           lastPaymentDate: lastPaymentDate,
                                           paymentDueDate: paymentDueDate,
                                           accountNickname: accountNickname,                                     
                                           availableBalanceAmount: availableBalanceAmount,
                                           interestYtdAmount: interestYtdAmount,
                                           interestRate: interestRate
                                         
                                           
                                          
                                          
                                          }]
                                      
                      
                                  }
                                  currentLiabilityArray.push(liabilityParent);
                              
                              }
                              else if(existingIndex !== parseInt(-1)) {
                               
                                  currentLiabilityArray[existingIndex].data.push(
                                      {id: finAccountsArray[i].id,
                                          number: finAccountsArray[i].number,
                                          realAccountNumberLast4: finAccountsArray[i].realAccountNumberLast4,
                                          official_name: finAccountsArray[i].name,
                                          balance: finAccountsArray[i].balance,
                                        //balance: currentBalance,
                                          type: finAccountsArray[i].type,
                                          subtype: finAccountsArray[i].type,
                                          aggregationStatusCode: finAccountsArray[i].aggregationStatusCode,
                                          status: finAccountsArray[i].status,
                                          customerId: finAccountsArray[i].customerId,
                                          institutionId: finAccountsArray[i].institutionId,
                                          balanceDate: finAccountsArray[i].balanceDate,
                                          aggregationSuccessDate: finAccountsArray[i].aggregationSuccessDate,
                                          aggregationAttemptDate: finAccountsArray[i].aggregationAttemptDate,
                                          createdDate: finAccountsArray[i].createdDate,
                                          currency: finAccountsArray[i].currency,
                                          lastTransactionDate: finAccountsArray[i].lastTransactionDate,
                                          institutionLoginId: finAccountsArray[i].institutionLoginId,
                                          currentBalance: currentBalance,
                                          lastPaymentAmount: lastPaymentAmount,
                                          paymentMinAmount: paymentMinAmount,
                                          statementCloseBalance: statementCloseBalance,
                                          lastPaymentDate: lastPaymentDate,
                                          paymentDueDate: paymentDueDate,
                                          accountNickname: accountNickname,                                     
                                          availableBalanceAmount: availableBalanceAmount,
                                          interestYtdAmount: interestYtdAmount
  
                                          
                                         
                                         
                                         }
                                  )
  
                              }
                          }
  
    //************************************START ASSET LOGIC ***************************************** */      
          else if(finAccountsArray[i].balance >= 0){
              if(finAccountsArray[i].type === 'checking' || finAccountsArray[i].type === 'savings'){
                individualAccounts.push({accountId: finAccountsArray[i].id, institutionId: finAccountsArray[i].institutionId })
              }

              if(finAccountsArray[i].type === 'checking' || finAccountsArray[i].type === 'savings' || finAccountsArray[i].type === 'creditCard'){
               
                let currentBalance = 'undefined'
                let interestRate = 'undefined'
                let lastPaymentAmount = 'undefined'
                let paymentMinAmount = 'undefined'
                let statementCloseBalance = 'undefined'
                let lastPaymentDate = 'undefined'
                let paymentDueDate = 'undefined'
               // paymentDueDate: finAccountsArray[i].detail.paymentDueDate
              // finAccountsArray[i].detail.accountNickname,
              let accountNickname = 'undefined'
              //finAccountsArray[i].detail.availableBalanceAmount
              let availableBalanceAmount = 'undefined'
             // finAccountsArray[i].detail.interestYtdAmount
             let interestYtdAmount = 'undefined'

                if(typeof finAccountsArray[i].detail !== 'undefined'){
                    if(typeof finAccountsArray[i].detail.balance !== 'undefined'){
                        currentBalance = finAccountsArray[i].detail.balance
                    }

                    if(typeof finAccountsArray[i].detail.interestRate !== 'undefined'){
                        interestRate = finAccountsArray[i].detail.interestRate
                    }
                    if(typeof finAccountsArray[i].detail.lastPaymentAmount !== 'undefined'){
                        lastPaymentAmount = finAccountsArray[i].detail.lastPaymentAmount
                    }

                    if(typeof finAccountsArray[i].detail.paymentMinAmount !== 'undefined'){
                       paymentMinAmount = finAccountsArray[i].detail.paymentMinAmount
                    }
                    if(typeof finAccountsArray[i].detail.statementCloseBalance !== 'undefined'){
                        statementCloseBalance = finAccountsArray[i].detail.statementCloseBalance
                    }

                    if(typeof finAccountsArray[i].detail.lastPaymentDate !== 'undefined'){
                        lastPaymentDate = finAccountsArray[i].detail.lastPaymentDate
                    }

                    if(typeof finAccountsArray[i].detail.paymentDueDate !== 'undefined'){
                       paymentDueDate = finAccountsArray[i].detail.paymentDueDate
                    }


                    if(typeof finAccountsArray[i].detail.accountNickname !== 'undefined'){
                        accountNickname = finAccountsArray[i].detail.accountNickname
                     }

                     if(typeof finAccountsArray[i].detail.availableBalanceAmount !== 'undefined'){
                        availableBalanceAmount = finAccountsArray[i].detail.availableBalanceAmount
                     }

                     if(typeof finAccountsArray[i].detail.interestYtdAmount !== 'undefined'){
                        availableBalanceAmount = finAccountsArray[i].detail.interestYtdAmount
                     }
                }
               pendingAccounts.push({accountId: finAccountsArray[i].id, institutionId: finAccountsArray[i].institutionId, balance: finAccountsArray[i].balance,
                accountType: finAccountsArray[i].type, logo: '', alternateLogo: '', icon:'', primaryColor: '', tile:'',
               // currentBalance: finAccountsArray[i].detail.currentBalance,
                currentBalance: currentBalance,
                interestRate: interestRate,
                lastPaymentAmount: lastPaymentAmount,
                paymentMinAmount: paymentMinAmount,
                statementCloseBalance: statementCloseBalance,
                lastPaymentDate: lastPaymentDate,
                paymentDueDate: paymentDueDate,
                accountNickname: accountNickname,
                availableBalanceAmount: availableBalanceAmount,
                interestYtdAmount: interestYtdAmount
            
            })
              }
           
              let existingAssetIndex = currentAssetArray.findIndex(obj => String(obj.institution) === String(finAccountsArray[i].institutionId))
              if(existingAssetIndex === parseInt(-1)){
                 let theBalance = finAccountsArray[i].type === 'checking' ? finAccountsArray[i].detail.availableBalanceAmount : finAccountsArray[i].balance
                 
                  let assetParent= {
                                      
                      institution: finAccountsArray[i].institutionId,
                      institutionId: finAccountsArray[i].institutionId,
                      documentId: finAccountsArray[i].id,
                      itemId: 'Finicity',
                      logo: '',
                      alternateLogo: '',
                      icon: '',
                      primaryColor: '',
                      tile: '', 
                      institutionLoginId: finAccountsArray[i].institutionLoginId,
                      data:[{id: finAccountsArray[i].id,
                           number: finAccountsArray[i].number,
                           realAccountNumberLast4: finAccountsArray[i].realAccountNumberLast4,
                           official_name: finAccountsArray[i].name,
                          // balance: finAccountsArray[i].balance,
                          balance: theBalance,
                           type: finAccountsArray[i].type,
                           subtype: finAccountsArray[i].type,
                           aggregationStatusCode: finAccountsArray[i].aggregationStatusCode,
                           status: finAccountsArray[i].status,
                           customerId: finAccountsArray[i].customerId,
                           institutionId: finAccountsArray[i].institutionId,
                           balanceDate: finAccountsArray[i].balanceDate,
                           aggregationSuccessDate: finAccountsArray[i].aggregationSuccessDate,
                           aggregationAttemptDate: finAccountsArray[i].aggregationAttemptDate,
                           createdDate: finAccountsArray[i].createdDate,
                           currency: finAccountsArray[i].currency,
                           lastTransactionDate: finAccountsArray[i].lastTransactionDate,
                           institutionLoginId: finAccountsArray[i].institutionLoginId,
                           currentBalance: currentBalance,
                           lastPaymentAmount: lastPaymentAmount,
                           paymentMinAmount: paymentMinAmount,
                           statementCloseBalance: statementCloseBalance,
                           lastPaymentDate: lastPaymentDate,
                           paymentDueDate: paymentDueDate,
                           accountNickname: accountNickname,                                     
                           availableBalanceAmount: availableBalanceAmount,
                           interestYtdAmount: interestYtdAmount
  
                           
                          
                          
                          }]
                      
      
                  }
                 
                  currentAssetArray.push(assetParent);
  
              }
  
              else if(existingAssetIndex !== parseInt(-1)){
  
                  currentAssetArray[existingAssetIndex].data.push(
                      {id: finAccountsArray[i].id,
                          number: finAccountsArray[i].number,
                          realAccountNumberLast4: finAccountsArray[i].realAccountNumberLast4,
                          official_name: finAccountsArray[i].name,
                          balance: finAccountsArray[i].balance,
                          type: finAccountsArray[i].type,
                          subtype: finAccountsArray[i].type,
                          aggregationStatusCode: finAccountsArray[i].aggregationStatusCode,
                          status: finAccountsArray[i].status,
                          customerId: finAccountsArray[i].customerId,
                          institutionId: finAccountsArray[i].institutionId,
                          balanceDate: finAccountsArray[i].balanceDate,
                          aggregationSuccessDate: finAccountsArray[i].aggregationSuccessDate,
                          aggregationAttemptDate: finAccountsArray[i].aggregationAttemptDate,
                          createdDate: finAccountsArray[i].createdDate,
                          currency: finAccountsArray[i].currency,
                          lastTransactionDate: finAccountsArray[i].lastTransactionDate,
                          institutionLoginId: finAccountsArray[i].institutionLoginId,
                          currentBalance: currentBalance,
                          lastPaymentAmount: lastPaymentAmount,
                          paymentMinAmount: paymentMinAmount,
                          statementCloseBalance: statementCloseBalance,
                          lastPaymentDate: lastPaymentDate,
                          paymentDueDate: paymentDueDate,
                          accountNickname: accountNickname,                                     
                          availableBalanceAmount: availableBalanceAmount,
                          interestYtdAmount: interestYtdAmount
  
                          
                         
                         
                         }
                  )
  
  
              }
  
  
          }
        }


  
        // logo={accountsForPending[i].logo} alternateLogo = {accountsForPending[i].alternateLogo} icon={accountsForPending[i].icon}
        // primaryColor={accountsForPending[i].primaryColor} tile={accountsForPending[i].tile}

        let accountNamesArray =  await getDocs(collection(db,'EuklesUsersData',stateRef.state.userUid, 'ConnectedAccounts')).then((querySnapshot) => {
          let localArray = [];
          if(querySnapshot.size > 0){
              querySnapshot.forEach((doc) => {
                  localArray.push({institutionId: doc.id, institutionName: doc.data().name, logo: doc.data().logo, alternateLogo: doc.data().alternateLogo,
                icon: doc.data().icon, primaryColor: doc.data().primaryColor, tile: doc.data().tile})
              })
          }
          return localArray;
          
          // stateRef.setState({
          //     connectedAccountsArray: currentArray
          // })
      })

  
      for(let q = 0; q < accountNamesArray.length; q++){
          if(accountNamesArray[q].logo === 'undefined' || typeof accountNamesArray[q].logo === 'undefined' || typeof accountNamesArray[q].logo === undefined){
              this.getInstitutionBranding(accountNamesArray[q].institutionId)
          }
      }

      for (let r = 0; r < pendingAccounts.length; r++){
          let existingIndex = accountNamesArray.findIndex(obj => String(obj.institutionId) === String(pendingAccounts[r].institutionId))
          if(existingIndex !== -1){
              pendingAccounts[r].logo = accountNamesArray[existingIndex].logo
              pendingAccounts[r].alternateLogo = accountNamesArray[existingIndex].alternateLogo
              pendingAccounts[r].icon = accountNamesArray[existingIndex].icon
              pendingAccounts[r].primaryColor = accountNamesArray[existingIndex].primaryColor
              pendingAccounts[r].tile = accountNamesArray[existingIndex].tile
          }
      }


      for(let w = 0; w < fixAccountsArray.length; w++){
        let index = accountNamesArray.findIndex(obj => String(obj.institutionId) === String(fixAccountsArray[w].institutionId))
        if(index !== -1){
            fixAccountsArray[w].institutionName = accountNamesArray[index].institutionName;
        }
      }

      //console.log('fixed names ', fixAccountsArray);
  
  
        for(let b = 0; b < currentLiabilityArray.length; b++){
        
            if(currentLiabilityArray[b].itemId === 'Finicity'){
             // let realName = this.getRealName(currentLiabilityArray[b].institutionId);
              //let currentArray = stateRef.state.connectedAccountsArray;
  
              let realName = '';
              let logo = '';
              let alternateLogo = '';
              let icon = '';
              let primaryColor = '';
              let tile = '';
  
          
            
           
              try{
                  let index = accountNamesArray.findIndex(obj => String(obj.institutionId) === String(currentLiabilityArray[b].institutionId))
      
                  realName = accountNamesArray[index].institutionName
                  logo = accountNamesArray[index].logo;
                  alternateLogo = accountNamesArray[index].alternateLogo;
                  icon = accountNamesArray[index].icon;
                  primaryColor = accountNamesArray[index].primaryColor;
                  tile = accountNamesArray[index].tile;
                  
              }
              catch(e){
                  console.log(e)
              }
              
          
              currentLiabilityArray[b].institution = realName;
              currentLiabilityArray[b].logo = logo;
              currentLiabilityArray[b].alternateLogo = alternateLogo;
              currentLiabilityArray[b].icon = icon;
              currentLiabilityArray[b].primaryColor = primaryColor;
              currentLiabilityArray[b].tile = tile;
            //   logo: '',
            //   alternateLogo: '',
            //   icon: '',
            //   primaryColor: '',
            //   tile: '', 

            }
            
            
        }
  
        for(let c = 0; c < currentAssetArray.length; c++){
            if(currentAssetArray[c].itemId === 'Finicity'){
              // let realName = this.getRealName(currentAssetArray[c].institutionId);
              // currentAssetArray[c].institution = realName;
              let realName = '';
              let logo = '';
              let alternateLogo = '';
              let icon = '';
              let primaryColor = '';
              let tile = '';
  
           
              try{
                  let index = accountNamesArray.findIndex(obj => String(obj.institutionId) === String(currentAssetArray[c].institutionId))
      
                  realName = accountNamesArray[index].institutionName
                  //realName = accountNamesArray[index].institutionName
                  logo = accountNamesArray[index].logo;
                  alternateLogo = accountNamesArray[index].alternateLogo;
                  icon = accountNamesArray[index].icon;
                  primaryColor = accountNamesArray[index].primaryColor;
                  tile = accountNamesArray[index].tile;
                  
              }
              catch(e){
                  console.log(e)
              }
  
              currentAssetArray[c].institution = realName;
              currentAssetArray[c].logo = logo;
              currentAssetArray[c].alternateLogo = alternateLogo;
              currentAssetArray[c].icon = icon;
              currentAssetArray[c].primaryColor = primaryColor;
              currentAssetArray[c].tile = tile;
              
              
            }
   
        }
        
          stateRef.setState({
            liabilitiesArray: currentLiabilityArray,
            assetsSyncedAccountsArray: currentAssetArray,
            finicityLoading: false,
            stateFixAccountsArray: fixAccountsArray
            
        })
        //console.log('the assets ', currentAssetArray);
      //  let finOnly = currentAssetArray.filter(obj => obj.itemId === 'Finicity');
      
        for(let r = 0; r < individualAccounts.length; r++ ){
            let realName = ''
            let index = accountNamesArray.findIndex(obj => String(obj.institutionId) === String(individualAccounts[r].institutionId))
      
                  realName = accountNamesArray[index].institutionName
                  individualAccounts[r].institutionId = realName
        }

        for(let r = 0; r < pendingAccounts.length; r++ ){
            let realName = ''
            let index = accountNamesArray.findIndex(obj => String(obj.institutionId) === String(pendingAccounts[r].institutionId))
      
                  realName = accountNamesArray[index].institutionName
                 pendingAccounts[r].institutionId = realName
        }



        stateRef.setState({
            accountsForCashFlow: individualAccounts,
            accountsForPending: pendingAccounts
        })
  
        //console.log('the accounts for pending ', pendingAccounts);
        
        
      }

      async getInstitutionBranding(id){
  
        let getBranding = httpsCallable(functions,'retrieveInstitutionBranding');

        let brandingObj = await getBranding({institutionId: id}).then((res) => {
                 
            // console.log('re ', res.data.name[0])
             return res.data.branding;
         }).catch((error) => {
             console.log(error);
             return 'error';
         })
       
        //  let checkIndex = stateRef.state.connectedAccountsArray.findIndex(obj => obj.institutionId === institutionId);
   
      
            if(brandingObj !== null && brandingObj !== 'error'){
                let docRef = doc(db,'EuklesUsersData', auth.currentUser.uid, 'ConnectedAccounts', String(id))
                await updateDoc(docRef,{logo: brandingObj.logo, icon: brandingObj.icon, alternateLogo: brandingObj.alternateLogo, primaryColor: brandingObj.primaryColor,
                tile: brandingObj.tile}).then((res) => {
                    return true;
                }).catch((e) => {
                    console.log(e)
                    return false;
                })
            }
      
             
          
     
        
      
      }

      async getFinTransactions(){
        let stateRef = this;

        stateRef.setState({
      
            finicityLoadingMessage:'',
            finicityLoading: false
         })
       

        // setTimeout(() => {
        //     stateRef.setState({
        //         finicityLoadingMessage: 'Hang tight, a couple more steps...'
        //     })
        // }, 1000);
    
     
   
        // let retrieveFinTransactions = httpsCallable(functions,'getFinTransactions');

        // let newTransactionArray = await retrieveFinTransactions({customerId: stateRef.state.finCustomerId, uid: stateRef.state.userUid}).then(function(result){

        // return result.data.newTransactionArray;
        // }).catch(function(error){

        //     return error;
        // });

        

        // if(typeof newTransactionArray[0] !== 'undefined'){
        //     this.getFinTransactionStepTwo(newTransactionArray[0])
        // }
       
        

    }

    

    async getFinTransactionStepTwo(rawTransactionArray){

   
        
        let stateRef = this;

        // stateRef.setState({
        //     finicityLoading: false,
        //     finicityLoadingMessage: 'Refreshing your accounts, please wait this may take a minute...'
        // })

        await getDocs(collection(db,'EuklesUsersData',stateRef.state.userUid, 'Transactions'))
       let recordedArray = await getDocs(collection(db,'EuklesUsersData',stateRef.state.userUid, 'Transactions')).then(function(querySnapshot){
                    
                    let localArray = [];
                    if(querySnapshot.size <= 0){
                        //show no budget for this month
                        return false;
                    }
                    else if(querySnapshot.size > 0){
                    
                        querySnapshot.forEach(function(doc){
                        
                            localArray.push(doc.id)
                    
                        })
                        return localArray;
                    }
                    }).catch((error) => {
                        console.log(error)
                    })
           
                    for(let i = 0; i < recordedArray.length; i++){
                        let existingIndex = rawTransactionArray.findIndex(obj => obj.id.toString() === recordedArray[i].toString());
                        if(existingIndex !== -1){
                          rawTransactionArray.splice(existingIndex,1);
                        }
                      }

                   

                    rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Investment Income')
                    rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Buy Other')
                      for(let i = 0; i < rawTransactionArray.length; i++){
                          rawTransactionArray[i].checked = false;
                      }
                   //   console.log('rawwe ', rawTransactionArray)
                        stateRef.setState({
                         transactionsArrayNew: rawTransactionArray,
                         finicityLoadingMessage:'',
                         finicityLoading: false
                      })
                    
           
        
            }

            async getRefreshedFinAccounts(){
              
                let stateRef = this;

    
                let retrieveBalances = httpsCallable(functions,'refreshFinAccountsRevised');
          
                await retrieveBalances({customerId: stateRef.state.finCustomerId, userUid: stateRef.state.userUid}).then(function(result) {
                   
                  return {error: false}
                }).catch(function(error){
          
                  return {error: true}
                })
              
            }

      async retrieveInstitutionName(institutionId){
        let stateRef = this;

           
        if(typeof institutionId !== 'undefined'){
        //  let checkIndex = stateRef.state.connectedAccountsArray.findIndex(obj => obj.institutionId === institutionId);
        let checkIndex = theConnectedArray.findIndex(obj => obj.institutionId === institutionId);
          if(checkIndex === -1){
          
            let getInstitutionName = httpsCallable(functions,'retrieveInstitutionName');
            let realName = await getInstitutionName({institutionId: institutionId}).then((res) => {
                 
              
                 return res.data.name[0]
             }).catch((error) => {
             
                 return 'error';
             })
      
         
            let docRef = doc(db,'EuklesUsersData', stateRef.state.userUid, 'ConnectedAccounts', String(institutionId))
            await setDoc(docRef,{name: realName})
             
          }

          this.getInstitutionBranding(institutionId)
     
        }
      
      }

      //******UI******************************* */

handleMobileMenuClickOpen(){
    this.setState({
        openMobileMenu: true
    })
}

async handleCheckTransaction(position, value){
  
    let currentArray = this.state.transactionsArrayNew;
    currentArray[position].checked = value
    this.setState({
        transactionsArrayNew: currentArray
    

    })
}

async  handleDeleteTransactions(){
    let currentArray = this.state.transactionsArrayNew;
    for(let i = 0; i < currentArray.length; i++){
        if(currentArray[i].checked){
            currentArray.splice(i,1);
        }
    }
    this.setState({
        transactionsArrayNew: currentArray
    })
}


    
    render(){
        return(
            <BudgetContextTwo.Provider  value={{...this.state,
            
               //********AUTHORIZATION METHODS******* */
               checkIfEmailIsVerified: this.checkIfEmailIsVerified,        
               createNewUserObject: this.createNewUserObject,      
               //createMonthlySavingsGoal: this.createMonthlySavingsGoal,
               handleSignInWithGoogle: this.handleSignInWithGoogle,
               handleSignWithEmailAndPassword: this.handleSignWithEmailAndPassword,
               handleSignUpWithApple: this.handleSignUpWithApple,
               handleSignInWithApple: this.handleSignInWithApple,
               handleSignInWithFacebook: this.handleSignInWithFacebook,
               handleSignUpWithFacebook: this.handleSignUpWithFacebook,
               handleSignUpWithGoogle: this.handleSignUpWithGoogle,
               getUserProfileInfo: this.getUserProfileInfo,
               logOut: this.logOut,
               processStripeCancellationDatabase: this.processStripeCancellationDatabase,
               sendVerificationCode: this.sendVerificationCode,
               signUpWithEmailAndPassword: this.signUpWithEmailAndPassword,
               submitVerificationCode: this.submitVerificationCode,
               updateIsPremiumUser: this.updateIsPremiumUser,
               updateStripeSubscriptionId: this.updateStripeSubscriptionId,
              handleDeleteTransaction: this.handleDeleteTransaction,
              handleCheckTransaction: this.handleCheckTransaction,
              handleDeleteTransactions: this.handleDeleteTransactions,
               //******AUTHORIZATION METHODS *******/
               handleSetOverSpentCount: this.handleSetOverSpentCount,
               updateSelectedMonth: this.updateSelectedMonth,
               handleDeleteSelectedTransactionsContext: this.handleDeleteSelectedTransactionsContext,

               //FINIICTY
               handleSetManualAssets: this.handleSetManualAssets,
               handleSetManualLiabilities: this.handleSetManualLiabilities,
               getFastFinAccounts: this.getFastFinAccounts,
               getRefreshedFinAccounts: this.getRefreshedFinAccounts,
               getFinTransactions: this.getFinTransactions,
               retrieveInstitutionName: this.retrieveInstitutionName,

            

               //
               getManualAssets: this.getManualAssets,
              getManualLiabilities: this.getManualLiabilities,
              handleResetAccounts: this.handleResetAccounts,


               //
               handleMobileMenuClickOpen: this.handleMobileMenuClickOpen,
               handleTransTemporary: this.handleTransTemporary,

               submitError: this.submitError,
       
               toggleIsPremiumUser: this.toggleIsPremiumUser,
               toggleFinicityId: this.toggleFinicityId,
        
            
            }}>
                   {this.props.children}
            </BudgetContextTwo.Provider>
           )
    }
}

