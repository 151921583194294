import React, {createContext,useReducer} from 'react';

const initialState = {
    actualTotals:{income:0.00,expenses:0.00},
    budgetMonthsArray:[],
    deletedTransactions:[],
    genericBudgetMonthsArray:[],
    logoDictionary:[],
    mainBudgetMonth:'',
    monthlySavingsGoal:100.00,
    monthlySavingsGoalProgress:0.5,
    newTransactions:[],
    overSpentArray:[],
    parentPodsArray: [],
    plannedTotals:{income:0.00,expenses:0.00},
    needRefreshTransactions:false,
    trackedTransactions:[],
    savingsTotals:{planned:0.00,actual:0.00},
    userData:{isPremiumUser:false,finCustomerId:'nil',budgetMethod:''},

}


function dataReducer(state,action){
    switch(action.type) {
        case 'UPDATE_PODS':
            return {...state,parentPodsArray:action.payload}

            case 'UPDATE_BUDGET_MONTHS_ARRAY':
                return{...state,budgetMonthsArray:action.payload}

                case 'UPDATE_OVERSPENT_ARRAY':
                    return{...state,overSpentArray:action.payload}
    

                case 'UPDATE_GENERIC_BUDGET_MONTHS_ARRAY':
                    return{...state,genericBudgetMonthsArray:action.payload}

        case 'UPDATE_USER_DATA':
            return {...state,userData:action.payload}

            case 'UPDATE_BUDGET_MONTH':
                return {...state,mainBudgetMonth:action.payload}

                case 'UPDATE_PLANNED_TOTALS':
                    return {...state,plannedTotals:action.payload}

                    case 'UPDATE_ACTUAL_TOTALS':
                        return {...state,actualTotals:action.payload}

                        case 'UPDATE_SAVINGS_TOTALS':
                            return {...state,savingsTotals:action.payload}

                            case 'UPDATE_MONTHLY_SAVINGS_GOAL':
                                return {...state,monthlySavingsGoal:action.payload}

                                case 'UPDATE_MONTHLY_SAVINGS_GOAL_PROGRESS':
                                return {...state,monthlySavingsGoalProgress:action.payload}

                                case 'UPDATE_NEW_TRANSACTIONS':
                                    return {...state,newTransactions:action.payload}

                                    case 'UPDATE_TRACKED_TRANSACTIONS':
                                        return {...state,trackedTransactions:action.payload}

                                        case 'UPDATE_DELETED_TRANSACTIONS':
                                            return {...state,deletedTransactions:action.payload}
                                            case 'UPDATE_LOGO_DICTIONARY':
                                                return {...state,logoDictionary:action.payload}

                                                case 'UPDATE_REFRESH_TRANSACTIONS':
                                                    return {...state,needRefreshTransactions:action.payload}
                        
                    
                

       
        default:
            return state;
    }
}

export const AltDataContext = createContext();

export const AltDataProvider = ({children}) => {
    const [state,dispatch] = useReducer(dataReducer,initialState)

    return (
        <AltDataContext.Provider value={{state,dispatch}}>
            {children}
        </AltDataContext.Provider>
    )
}