import { AltDataContext } from "contexts/AltDataContext";
import AppTextField from "components/uicomponents/AppTextField";
import Axios from 'axios';
import { Box, Grid, Divider, Backdrop, useMediaQuery,Typography,useTheme } from "@mui/material";
import BottomSpacer from "components/uicomponents/BottomSpacer";
import { BudgetContextTwo } from "contexts/BudgetContextTwo";
import BudgetEvenLogo from '../landingpage/landingimages/budgeteven.svg'
import {CardElement, useStripe, useElements,} from '@stripe/react-stripe-js';

import { Ellipsis} from 'react-spinners-css'
import FlexBetween from "components/uicomponents/FlexBetween";
import { generateRandomString} from "utils/Helpers";

import { getFunctions, httpsCallable } from "firebase/functions";
import { H3} from "components/uicomponents/Typography";
import { LoadingButton } from "@mui/lab";
import { Navigate} from "react-router-dom";
import React,{useState, useContext,useEffect} from 'react'
import toast from "react-hot-toast";
import {  useNavigate, useLocation } from "react-router-dom";
import { uploadError } from "utils/FirebaseAnalyticsMethods";
import { testMode } from "utils/GlobalConstans";
import { updateUserDocument } from "utils/UserMethods";
import { getAuth } from "firebase/auth";
import { isParameter } from "typescript";





const functions = getFunctions();

const UpdatedCheckOutInterior = () => {

const{isAuthenticated} = useContext(BudgetContextTwo)
const theme = useTheme();

const {state,dispatch} = useContext(AltDataContext)

const isDownLarge = useMediaQuery(theme.breakpoints.down('lg'));


const elements= useElements();
const location = useLocation();
const navigate = useNavigate();
const stripe = useStripe();


const [canSubmit,setCanSubmit] = useState(false)
const [firstName,setFirstName] = useState('')
const [firstNameError,setFirstNameError] = useState(false)
const [zip,setZip] = useState('')
const [zipError,setZipError] = useState(false)
const [loading, setLoading] = useState(false)
const [loadingMessage,setLoadingMessage] = useState('Please wait...')


const [stripeErrorMessage,setStripeErrorMessage] = useState('')
const [stripeLoading,setStripeLoading] = useState(false)




useEffect(() => {
  if(zip !== '' && firstName !== '' && stripe && elements){
    setCanSubmit(true)
  }
},[zip,firstName])



function checkFields(){
  if(firstName === ''){
    return setFirstNameError(true)
  }


  if(zip === ''){
    return setZipError(true)
  }

  else {
    createStripePaymentMethod()
  }



}

async function createStripePaymentMethod () {

  try{
    setStripeLoading(true)
    setStripeErrorMessage('')
     
      if(!stripe || !elements){
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type:'card',
        card: cardElement,
      });
 
      if(error){
       
       setLoading(false);
      setStripeLoading(false);
       toast.error(`There was an error processing your request. ${error.message}  `)
    
      }
      else {
      
        const paymentMethodId = paymentMethod.id;
        let userEmail = getAuth().currentUser.email
        let userUid = getAuth().currentUser.uid
        const data = {paymentId: paymentMethodId, email: userEmail, uid: userUid, name:firstName, zip: zip}
       createStripeCustomer(data);

      }
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js, createStripePaymentMethod')
  }

   }

   async function createStripeCustomer(data){

    try{

    
      let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomerTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomer'
  
       let createCustomerResult = await Axios.post(url, data).then(res => {
        return res.data;
                 
      }).catch(error => {
     
        return error;
        })
    
               if(!createCustomerResult.encounteredError){
                createStripeSubscriptionAutoMethod(createCustomerResult.customerId)
             
               }
               else if(createCustomerResult.encounteredError){
                setLoading(false);
                setStripeLoading(false);
                toast.error('There was an error processing your request.')
                setStripeErrorMessage(createCustomerResult.errorMessage)
               }
  
      
    }catch(e){
      uploadError(e.message,'UpdatedCheckOutInterior, createStripeCustomer.js')
    }
  
           
    }

 
    async function createStripeSubscriptionAutoMethod(customerId){
      //https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription
      //full auto
      let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription'
      setStripeLoading(false);
      setLoading(true)
      let userUid = getAuth().currentUser.uid
     const customerData = {customerId: customerId, uid: userUid}
           let stripeSubscription = await Axios.post(url, customerData ).then(res => {
 
            return res.data;
            }).catch(error => {
             console.log('error creating subscription')
              return error;
            })
 
            if(stripeSubscription.encounteredError){
              setStripeLoading(false);
              setLoading(false);
              setStripeErrorMessage(stripeSubscription.errorMessage)
              toast.error(`There was an error processing your request. ${stripeSubscription.errorMessage}`)
            }
            else if(!stripeSubscription.encounteredError) {
              
              toast.success('Payment successful!')
              setLoadingMessage('Upgrade successful...')
    //           stripeSubscriptionId: subscriptionId,budgetMethod:budgetMethod,currentStep:'JustUpgraded',
    // autoCurrentStep:'NeedFinicityId',
    // isPremiumUser:true,subscriptionMethod:'web'
              //await updateUserDocument({budgetMethod:'Auto',isPremiumUser:true,stripeSubscriptionId:stripeSubscription.subscriptionId})
              //dispatch({type:'UPDATE_USER_DATA',payload:{...state.userData,budgetMethod:'Auto',isPremiumUser:true,stripeSubscriptionId:stripeSubscription.subscriptionId}})
              createFinicityCustomerAuto(stripeSubscription.subscriptionId);
            
 
              
            }
 
      
 }

   async function createFinicityCustomerAuto(subscriptionId){
 
    let url = testMode ? 'createFinCustomerTest' : 'createFinCustomer'
    setLoadingMessage('Contacting Finicity...')
    let createFinMethod = httpsCallable(functions,url);
      
    let userName = generateRandomString(10)
    let customerId = await createFinMethod({username: userName}).then((res) => {
        return res.data.customerId;
    }).catch((err) => {
        
        return false;
    });
    
    if(customerId !== false){
    await updateUserDocument({finCustomerId:customerId,isPremiumUser:true,subscriptionMethod:'Web',stripeSubscriptionId:subscriptionId})
    dispatch({type:'UPDATE_USER_DATA',payload:{...state.userData,finCustomerId:customerId,isPremiumUser:true,budgetMethod:'Auto',stripeSubscriptionId:subscriptionId,
      subscriptionMethod:'Web'}})
   
      getFinUrlForUpgradeFromManualToAuto(customerId)
    }
    else {
      setLoading(false);
      toast.error('There was an error processing your request.')
    }
    } 



    async function getFinUrlForUpgradeFromManualToAuto(finCustomerId){

      let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
      let urlResult = await retrieveUrl({customerId: finCustomerId}).then(function(result){
          return result;
      }).catch(function(error){
          console.log('error generating fin url');
          return false;
      })
      
      if(urlResult.data.theUrl === 'error getting url'){

          toast.error('Budget Even has encountered an error')
      }
      else {

        let theUrl = String(urlResult.data.theUrl);
        navigate('../finicityaawb',{state:{urlToUse: theUrl,finCustomerId:finCustomerId}});
    
    }
    }

   async function byPassTest(){
     // dispatch({type:'UPDATE_USER_DATA',payload:{...state.userData,isPremiumUser:true, budgetMethod:'Auto'}})
      //await updateUserDocument({isPremiumUser:true,subscriptionMethod:'Web'})
      createFinicityCustomerAuto();
    }


if(isAuthenticated){
  if(loading){
    return (
      <div>

     <Backdrop open={loading} style={{zIndex:1400,backgroundColor:'white'}}>
            <Grid container direction='column'  justifyContent='center' alignItems='center'>
              <Grid item>
              <Ellipsis color={theme.palette.primary.main} />
              </Grid>
         <Grid item style={{paddingTop:5}}>
            <Typography style={{color:theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>{loadingMessage}</Typography>
          </Grid> 
          
            </Grid>
            </Backdrop> 
           
</div>
    )
  }
  else {

    return(
      <Box pt={2} pb={4} style={{marginLeft: isDownLarge ? 75 : 0}}>
             <Grid container direction='row' justifyContent='center'>
      <Grid item>
      <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
      </Grid>
      </Grid>

      <Grid container style={{justifyContent:'center',marginTop:25,alignSelf:'center'}}>
          <div style={{width:550, height:750,backgroundColor:'white',borderRadius:5,border: '1px solid grey'}}>  
              <div style={{width:550,height:100,alignContent:'center',backgroundColor: theme.palette.primary.main,marginBottom:15}}>

                    <Typography variant='h5' style={{textAlign:'center',color:'white',fontWeight:'bold'}}>Budget Even: Auto</Typography>
              </div>

          
              <Typography style={{color:'red',fontStyle:'italic'}}>{stripeErrorMessage}</Typography>
              <div style={{marginLeft:10,marginRight:10,marginTop:25}}>
              <Typography style={{fontWeight:500}}>Card information</Typography>
              <CardElement />
              </div>

              <div style={{margin:10}}>
              <Typography style={{fontWeight:500}}>Name on card</Typography>

             
              <AppTextField  onChange={(e) => setFirstName(e.target.value)}
            fullWidth error={firstNameError} helperText='' />

     
               </div>

               <div style={{margin:10}}>
              <Typography style={{fontWeight:500}}>Zip</Typography>

             
              <AppTextField  onChange={(e) => setZip(e.target.value)}
            error={zipError}  />

     
               </div>

               <Divider style={{marginTop:25}} />
               <Typography style={{marginLeft:10,marginRight:10,fontWeight:500,marginTop:25}}>Order Summary</Typography>


  <Typography style={{marginLeft:10,marginRight:10,marginBottom:10}}>By clicking the subscribe button below, you agree to subscribe to the Budget Even Auto mode for a monthly payment of $4.99.
  You may cancel your subscription at any time.</Typography>





  <Box paddingX={3}>
    <FlexBetween my={2}>
      <H3>Today's Total</H3>
      <H3 color="primary.main">{location.state.planType === 'Auto' ? '$4.99' : '$2.49'}</H3>
    </FlexBetween>

    <LoadingButton disabled={canSubmit ? false : true} fullWidth  onClick={() => checkFields()}  loading={stripeLoading}  variant="contained">
        Subscribe
      </LoadingButton>


    {/* <LoadingButton  fullWidth  onClick={() => byPassTest()}  loading={stripeLoading}  variant="contained">
        Test
      </LoadingButton> */}

  </Box>

          </div>
    
        </Grid>
      <BottomSpacer />
      </Box>
    )

  
}
 
}
else {
  return(
    <Navigate to='/' replace={true} />
  )
}


};

export default UpdatedCheckOutInterior;



// return (
//   <Box pt={2} pb={4} style={{marginLeft: determineMargin()}}>
   
      
//      {stripeLoading ?   <Backdrop open={true} style={{zIndex:1400,backgroundColor:'white'}}>
//         <Grid container direction='column'  justifyContent='center' alignItems='center'>
//           <Grid item>
//           <Ellipsis color={theme.palette.primary.main} />
//           </Grid>
//      <Grid item style={{paddingTop:5}}>
//         <Typography style={{color:theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>{loadingMessage}</Typography>
//       </Grid> 
      
//         </Grid>
//         </Backdrop>  : null}

//         <Grid container direction='row' justifyContent='center'>
//       <Grid item>
//       <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
//       </Grid>
//       </Grid>

//   {paymentSuccess ?  null : 
  

//   <Grid container spacing={3}>
    
//   <Grid item lg={8} md={10} sm={12} xs={12}>
//     <Card
//       sx={{
//         padding: 3,
       
//       }}
//     >
//       <CardHeader title='Payment Information'></CardHeader>
//       <Grid container spacing={3}>
//         <Grid item xs={7}>
//         <Typography style={{color:'red',fontStyle:'italic'}}>{stripeErrorMessage}</Typography>
//             <CardElement />
       
     
//            <Grid container spacing={3}>
//            <Grid item md={6} xs={12}>
//           <AppTextField label="First" onChange={(e) => setFirstName(e.target.value)}
//           fullWidth error={firstNameError} helperText='' />
//         </Grid>
//         <Grid item md={6} xs={12}>
//           <AppTextField label="Last" fullWidth onChange={(e) => setLastName(e.target.value)}
//           error={lastNameError} />
//         </Grid>
//         <Grid item md={6} xs={12}>
//           <AppTextField label="Address" fullWidth onChange={(e) => setAddress(e.target.value)}  />
//         </Grid>
//         <Grid item md={6} xs={12}>
//           <AppTextField label="City" fullWidth onChange={(e) => setCity(e.target.value)}
//            error={cityError}/>
//         </Grid>
//         <Grid item md={6} xs={12}>
//           <AppTextField label="State" fullWidth onChange={(e) => setStateTitle(e.target.value)}
//           error={stateTitleError} />
//         </Grid>
//         <Grid item md={6} xs={12}>
//           <AppTextField label="Zip" fullWidth onChange={(e) => setZip(e.target.value)}
//           error={zipError}  />
//         </Grid>

//       </Grid>
//         </Grid>
//        <Grid item xs={5}>
//           <img
//             alt=""
//             src="/static/illustration/payment-page.svg"
//             style={{
//               padding: 10,
//               display: "block",
//               marginLeft: "auto",
//             }}
//           />
//         </Grid> 
  
//       </Grid>
//     </Card>

  
//   </Grid>






//   <Grid item lg={4} md={5} sm={12} xs={12}>
//   <Card>
// <H3 paddingX={3} paddingY={2}>
//   Order Summary
// </H3>

// <Box paddingX={3}>
//   <FlexBetween my={2.5}>
//     <H5>Subtotal</H5>
//     <H5>{location.state.planType === 'Auto' ? '$4.99' : '$2.49'}</H5>
//   </FlexBetween>

// </Box>

// <Divider />

// <Box paddingX={3}>
//   <FlexBetween my={2}>
//     <H3>Total</H3>
//     <H3 color="primary.main">{location.state.planType === 'Auto' ? '$4.99' : '$2.49'}</H3>
//   </FlexBetween>

//   <Button onClick={() => checkFields()} variant="contained" fullWidth>
//     Submit
//   </Button>
// </Box>

// <Box sx={{textAlign: "center",marginTop: 5,padding: 0,}}>
//   <img src="/static/illustration/cart-page.svg" alt="" />
// </Box>
// </Card>
//   </Grid>
// </Grid>
  
  
//   }
   





//   </Box>
// )