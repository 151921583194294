



export const months =['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const allMonthsAndYears = [ 

    'January 2022',
    'February 2022',
    'March 2022',
    'April 2022',
    'May 2022',
     'June 2022',
     'July 2022',
    'August 2022',
    'September 2022',
    'October 2022',
    'November 2022',
    'December 2022',
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
     'August 2023',
     'September 2023',
     'October 2023',
     'November 2023',
     'December 2023',
     'January 2024',
     'February 2024',
     'March 2024',
     'April 2024',
     'May 2024',
     'June 2024',
     'July 2024',
      'August 2024',
      'September 2024',
      'October 2024',
      'November 2024',
      'December 2024'
    
    
    ]

export const analysisMonthsAndYears = [
    '2021',
    '2022',
    'July 2021',
    'August 2021',
    'September 2021',
    'October 2021',
    'November 2021',
    'December 2021',
    'January 2022',
    'February 2022',
    'March 2022',
    'April 2022',
    'May 2022',
    'August 2022',
    'September 2022',
    'October 2022',
    'November 2022',
    'December 2022'
]


export const logosAndPath = [
    {description:'Amazon', logo:'/static/avatar/027-man-7.svg'}
]



export const fakeTransactions = [

    {id: 114204184260, amount: -32.00, accountId: 1046782493, status:'active', description: 'Target purchase', memo: 'Debit', postedDate: 1628596800,
    transactionDate:  1628540235, createdDate: 1628659915, 
    categorization: {normalizedPayeeName: 'Target', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
    country: 'USA'}
     },

    {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Kroger debit ', memo: 'Debit', postedDate: 1628596800,
    transactionDate:  1628540235, createdDate: 1628659915, 
    categorization: {normalizedPayeeName: 'Kroger', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
    country: 'USA'}
     },
     {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Gas purchase', memo: 'Debit', postedDate: 1628596800,
     transactionDate:  1628540235, createdDate: 1628659915, 
     categorization: {normalizedPayeeName: 'Exxon Mobil', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
     country: 'USA'}
      },
      {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: `mcdonald trx`, memo: 'Debit', postedDate: 1628596800,
      transactionDate:  1628540235, createdDate: 1628659915, 
      categorization: {normalizedPayeeName: `McDonald's`, category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
      country: 'USA'}
       },
       {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Target purchase aug', memo: 'Debit', postedDate: 1628596800,
       transactionDate:  1628540235, createdDate: 1628659915, 
       categorization: {normalizedPayeeName: 'Target', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
       country: 'USA'}
        },
        {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Target purchase aug', memo: 'Debit', postedDate: 1628596800,
        transactionDate:  1628540235, createdDate: 1628659915, 
        categorization: {normalizedPayeeName: 'Target', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
        country: 'USA'}
         },
         {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Target purchase aug', memo: 'Debit', postedDate: 1628596800,
         transactionDate:  1628540235, createdDate: 1628659915, 
         categorization: {normalizedPayeeName: 'Target', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
         country: 'USA'}
          },
          {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Target purchase aug', memo: 'Debit', postedDate: 1628596800,
          transactionDate:  1628540235, createdDate: 1628659915, 
          categorization: {normalizedPayeeName: 'Target', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
          country: 'USA'}
           },
           {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Target purchase aug', memo: 'Debit', postedDate: 1628596800,
           transactionDate:  1628540235, createdDate: 1628659915, 
           categorization: {normalizedPayeeName: 'Target', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
           country: 'USA'}
            },
            {id: 11420418433260, amount: -19.00, accountId: 1046782493, status:'active', description: 'Target purchase aug', memo: 'Debit', postedDate: 1628596800,
            transactionDate:  1628540235, createdDate: 1628659915, 
            categorization: {normalizedPayeeName: 'Target', category:'Transfer', bestRepresentation:'OCULUS LFB TRF DEBIT',
            country: 'USA'}
             },


  

    {id: 114224849843, amount: -20.00, accountId: 1052060530, status:'active', description: 'Target purchase 2', memo: 'WEB INITIATED PAYMENT AT PAYPAL INST XFER ROBLOX CORP 081121', 
    postedDate: 1628650800,
    transactionDate: 1636504528, createdDate: 1628774125, 
    categorization: {normalizedPayeeName: 'Target', 
    category:'Transfer', bestRepresentation:'WEB INITIATED PAYMENT AT PAYPAL INST XFER ROBLOX CORP',
    country: 'USA' }},


    {id: 114204184263, amount: -5.38, accountId: 1046782493, status:'active', description: 'APPLE.COM/BILL', memo: 'Debit', 
    postedDate: 1628596800,
    transactionDate: 1636504528, createdDate: 1628659915,
    categorization: {normalizedPayeeName: 'Apple', 
    category:'Electronics & Software', bestRepresentation:'APPLE COM BILL DEBIT',
    country: 'USA'}
     },

     {id: 1142041842063, amount: -5.38, accountId: 1046782493, status:'active', description: 'Target Purchase 3', memo: 'Debit', 
     postedDate: 1628596800,
     transactionDate: 1636504528, createdDate: 1628659915,
     categorization: {normalizedPayeeName: 'Target', 
     category:'Electronics & Software', bestRepresentation:'APPLE COM BILL DEBIT',
     country: 'USA'}
      },

    

 



]

let incomeLineItemsArray =  [{description:'Paycheck', plannedValue: 23000.00, spentValue: 100.00, podName:'Income', checked:false, highlightColor:'white'},
{description:'Paycheck', plannedValue: 23000.00, spentValue: 0.00, podName:'Income', checked:false, highlightColor:'white'}    ];

let groceriesLineItemArray = [{description:'Groceries', plannedValue: 1000.00, spentValue: 0.00, podName:'Food', checked:false},
{description:'McDonalds', plannedValue: 100.00, spentValue: 0.00, podName:'Food', checked:false, highlightColor:'white'},
{description:'Skyline', plannedValue: 50.00, spentValue: 0.00, podName:'Food', checked:false, highlightColor:'white'}    ]

let entertainmentLineItemArray = [{description:'Misc', plannedValue: 1000.00, spentValue: 0.00, podName:'Entertainment', checked:false, highlightColor:'white'},
{description:'Dates', plannedValue: 100.00, spentValue: -1000.00, podName:'Entertainment', checked:false, highlightColor:'white'},
{description:'Betting', plannedValue: 50.00, spentValue: 0.00, podName:'Entertainment', checked:false, highlightColor:'white'}    ]

let kidsLineItemArray = [{description:'Michael', plannedValue: 1000.00, spentValue: 0.00, podName:'Kids', checked:false, highlightColor:'white'},
{description:'Carole', plannedValue: 100.00, spentValue: 0.00, podName:'Kids', checked:false, highlightColor:'white'},
{description:'Charlie', plannedValue: 50.00, spentValue: 0.00, podName:'Kids', checked:false, highlightColor:'white'}    ]

let fartLineItemArray = [{description:'Michael', plannedValue: 1000.00, spentValue: 0.00, podName:'Kids', checked:false,highlightColor:'white'},
{description:'Carole', plannedValue: 100.00, spentValue: 0.00, podName:'Kids', checked:false,highlightColor:'white'},
{description:'Charlie', plannedValue: 50.00, spentValue: 0.00, podName:'Kids', checked:false,highlightColor:'white'}    ]

export const starterIncomePod = [
    {
        title:'Income',
        data: incomeLineItemsArray
    }
]




export const fakePods = [
    {title: 'Income',
    data: incomeLineItemsArray
   
    },
    {title: 'Food',

        data: groceriesLineItemArray
    
    },
    {title: 'Entertainment',
     data: entertainmentLineItemArray
       
    },
    {
        title:'Kids',
        data: kidsLineItemArray
    },
    {
        title:'Medical',
        data: fartLineItemArray
    },
  




]





export const fakeAssets = [
        {institution: 'Personal Assets', institutionDocId:'234234', itemId:'manual', data: [
             {updateTime: '', 
                  account_id:'manual', 
                  avaialable: 40000,
                  balanceDate: '', 
                  balance: 40000,
                  lineItemDocId: '12323',
                  official_name: 'My House',
                  subtype: 'real estate', 
                  itemId: 'manual', 
                  institution: 'Personal Assets',
                  institutionLoginId: 'manual', 
                  institutionName: 'Personal Assets',
                  type:'Asset'},

                  {updateTime: '', 
                  account_id:'manual', 
                  avaialable: 4000,
                  balanceDate: '', 
                  balance: 4000,
                  lineItemDocId: '12323',
                  official_name: 'My Car',
                  subtype: 'automobile', 
                  itemId: 'manual', 
                  institution: 'Personal Assets',
                  institutionLoginId: 'manual', 
                  institutionName: 'Personal Assets',
                  type:'Asset'}
        ]}
]



export const fakeLiabilities = [
    {institution: 'Personal Loans', institutionDocId:'234234', itemId:'manual', data: [
         {updateTime: '', 
              account_id:'manual', 
              avaialable: 20000,
              balanceDate: '', 
              balance: 20000,
              lineItemDocId: '12323',
              official_name: 'My Mortgage',
              subtype: 'Mortgage', 
              itemId: 'manual', 
              institution: 'Personal Loans',
              institutionLoginId: 'manual', 
              institutionName: 'Personal Loans',
              type:'Liability'},

              {updateTime: '', 
              account_id:'manual', 
              avaialable: -42000,
              balanceDate: '', 
              balance: -42000,
              lineItemDocId: '12323',
              official_name: 'My Car',
              subtype: 'Automobile', 
              itemId: 'manual', 
              institution: 'Personal Loans',
              institutionLoginId: 'manual', 
              institutionName: 'Personal Loans',
              type:'Liability'}
    ]}
]


export const fakeCashFlowItems = [
    {dayDigit: '01', description: 'Mortgage', docId: 12345,
        one:{amount: -1387},
        two:{amount: -1387},
        three:{amount: -1387},
        four: {amount: -1387},
        five: {amount: -1387},
        six: {amount: -1387},
        seven: {amount: -1387},
        eight: {amount: -1387},
        nine: {amount: -1387},
        ten: {amount: -1387},
        eleven: {amount: -1387},
        twelve: {amount: -1387}},

        {dayDigit: '01', description: 'Credit Card Payment', docId: 12345,
        one:{amount: -2500},
        two:{amount: -2553},
        three:{amount: -2478},
        four: {amount: -2300},
        five: {amount: -2553},
        six: {amount: -2553},
        seven: {amount: -2478},
        eight: {amount: -2300},
        nine: {amount: -2300},
        ten: {amount: -2553},
        eleven: {amount: -2553},
        twelve: {amount: -2553}},

        {dayDigit: '03', description: 'ATM Withdrawal', docId: 12345,
        one:{amount: -100},
        two:{amount: -100},
        three:{amount: -100},
        four: {amount: -100},
        five: {amount: -100},
        six: {amount: -100},
        seven: {amount: -100},
        eight: {amount: -100},
        nine: {amount: -100},
        ten: {amount: -100},
        eleven: {amount: -100},
        twelve: {amount: -100}},

        {dayDigit: '08', description: 'Tuition', docId: 12345,
        one:{amount: -438},
        two:{amount: 0.00},
        three:{amount: 0},
        four: {amount: 0.00},
        five: {amount: -438},
        six: {amount: 0},
        seven: {amount: 0},
        eight: {amount: 0},
        nine: {amount: -438},
        ten: {amount: 0},
        eleven: {amount: 0},
        twelve: {amount: 0}},

        {dayDigit: '07', description: 'Paycheck', docId: 12345,
        one:{amount: 817},
        two:{amount: 817},
        three:{amount: 817},
        four: {amount: 817},
        five: {amount: 817},
        six: {amount: 817},
        seven: {amount: 817},
        eight: {amount: 817},
        nine: {amount: 817},
        ten: {amount: 817},
        eleven: {amount: 817},
        twelve: {amount: 817}},


        {dayDigit: '14', description: 'Paycheck', docId: 12345,
        one:{amount: 817},
        two:{amount: 817},
        three:{amount: 817},
        four: {amount: 817},
        five: {amount: 817},
        six: {amount: 817},
        seven: {amount: 817},
        eight: {amount: 817},
        nine: {amount: 817},
        ten: {amount: 817},
        eleven: {amount: 817},
        twelve: {amount: 817}},
    
    
        {dayDigit: '21', description: 'Paycheck', docId: 12345,
        one:{amount: 817},
        two:{amount: 817},
        three:{amount: 817},
        four: {amount: 817},
        five: {amount: 817},
        six: {amount: 817},
        seven: {amount: 817},
        eight: {amount: 817},
        nine: {amount: 817},
        ten: {amount: 817},
        eleven: {amount: 817},
        twelve: {amount: 817}},


         
        {dayDigit: '28', description: 'Paycheck', docId: 12345,
        one:{amount: 817},
        two:{amount: 817},
        three:{amount: 817},
        four: {amount: 817},
        five: {amount: 817},
        six: {amount: 817},
        seven: {amount: 817},
        eight: {amount: 817},
        nine: {amount: 817},
        ten: {amount: 817},
        eleven: {amount: 817},
        twelve: {amount: 817}},

         
        {dayDigit: '16', description: 'Auto Insurance', docId: 12345,
        one:{amount: 81},
        two:{amount: 81},
        three:{amount: 81},
        four: {amount: 81},
        five: {amount: 81},
        six: {amount: 81},
        seven: {amount: 81},
        eight: {amount: 81},
        nine: {amount: 81},
        ten: {amount: 81},
        eleven: {amount: 81},
        twelve: {amount: 81}},
        

        {dayDigit: '05', description: 'Car Payment', docId: 12345,
        one:{amount:-206},
        two:{amount: -206},
        three:{amount: -206},
        four: {amount: -206},
        five: {amount: -206},
        six: {amount: -206},
        seven: {amount: -206},
        eight: {amount: -206},
        nine: {amount: -206},
        ten: {amount: -206},
        eleven: {amount: -206},
        twelve: {amount: -206}},
    
    
        
        {dayDigit: '09', description: 'Loan Payment', docId: 12345,
        one:{amount:-20},
        two:{amount: -20},
        three:{amount: -20},
        four: {amount: -20},
        five: {amount: -20},
        six: {amount: -20},
        seven: {amount: -20},
        eight: {amount: -20},
        nine: {amount: -20},
        ten: {amount: -20},
        eleven: {amount: -20},
        twelve: {amount: -20}},

     
]

