
import React, {useEffect, useState} from 'react'
import { Card, CardContent,useTheme,Typography,CardActions,Button } from '@mui/material';
import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import { useNavigate} from 'react-router';
import { updateOnBoardingStepAndAutoStep } from 'utils/FirebaseAnalyticsMethods';

export default function BudgetRecap() {

    
    const theme = useTheme();
    const navigate = useNavigate();
  
    const [currentStep, setCurrentStep] = useState(1)

    const firstText = 'Your monthly budget, cash flow projection, and net worth balance sheet have been created.'
    const secondText = 'Login daily to have Budget Even scan and track your transactions, update your cash flow, and balance sheet.'
    const thirdText = 'Your monthly savings goal will be set to 25% of your planned income for the month.'
    const fourthText = '50% of your budget should go towards "needs" like food and housing, and the remaining 25% can go towards "wants" like entertainment.'

    
    
  useEffect(() => {
    updateOnBoardingStepAndAutoStep('budgetRecap','budgetRecap')
  },[])
    

    function handleNextStep(){
     if(currentStep === 1){
       return setCurrentStep(2)
     }
     else if(currentStep === 2){
      return setCurrentStep(3)
     }
     else if(currentStep === 3){
      return setCurrentStep(4)
     }
     else if(currentStep === 4){
      return   navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
     }

    }


        return (

            <div className="center-container">
          <Card sx={{ minWidth: 400 }}>
      <CardContent>
      <img style={{alignSelf:'center',width:200}} src={BudgetEvenLogo}  alt="Logo" />
        <Typography variant="h5" component="div">
        {currentStep === 1 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}} component="div">{firstText}</Typography> : null}
      {currentStep === 2 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{secondText}</Typography> : null}
      {currentStep === 3 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{thirdText}</Typography> : null}
      {currentStep === 4 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{fourthText}</Typography> : null}
        </Typography>
       
      </CardContent>
      <CardActions>
        <Button style={{marginLeft:'auto'}} onClick={() => handleNextStep()} size="small">Next</Button>
      </CardActions>
    </Card>
    </div>
          )


}