import { Grid } from '@mui/material'
import React from 'react'

export default function BottomSpacer() {
  return (
    <Grid container direction='column' style={{height:100}}>
    <Grid item>
  
    </Grid>
  </Grid>
  )
}
