
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BE from './graphics/BE.svg'
import { Box, List,ListItemButton, styled,useMediaQuery,Grid,Typography,useTheme} from "@mui/material";
import { BudgetContextTwo } from "contexts/BudgetContextTwo";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataTable from "icons/sidebar/DataTable";
import EmailIcon from '@mui/icons-material/Email';
import { Fragment, useState, useContext } from "react";
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import ScrollBar from "simplebar-react";
import topMenuList from "./topMenuList";
import LogoutIcon from '@mui/icons-material/Logout';


const MainMenu = styled(Box)(({
  theme
}) => ({
  width: 80,
  height: "100%",
  position: "fixed",
  left: 0,
  boxShadow: theme.shadows[2],
  zIndex: theme.zIndex.drawer + 11,
  transition: "left 0.3s ease",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: {
    left: -80
  },
  "& .simplebar-track.simplebar-vertical": {
    width: 7
  },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary
  }
}));


const StyledListItemButton = styled(ListItemButton)(() => ({

  justifyContent: "center",
  "&:hover": {
    backgroundColor: "transparent"
  }
}));





const DashboardSideBar = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("Dashboard");

  const downSm = useMediaQuery(theme => theme.breakpoints.down("md"));
  const {logOut} = useContext(BudgetContextTwo)
  const theme = useTheme();

  const handleActiveMainMenu = menuItem => () => {
    setActive(menuItem.title);

 
      switch(menuItem.title){
        case 'Dashboard':
          navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
          break;

          case 'Balance Sheet':
            navigate('/dashboard/balancesheet',{state:{needsToRefresh: false}})
            break;

            case 'Accounts':
              navigate('/dashboard/accountshome',{state:{needsToRefresh:true}})
              break;

              case 'Cash Flow':
                navigate('/dashboard/cashflow',{state:{needsToRefresh:false}})
                break;

                case 'Budget Analysis':
                  navigate('/dashboard/transactions',{state:{needsToRefresh:false}})
                  break;

                  case 'Customer Support':
                 
                      window.open('mailto:help@budgeteven.com?subject=Help')
                    
                   
                    break;
  

                  case 'Account Settings':
                    navigate('/dashboard/account-settings',{state:{needsToRefresh:false}})
                    break;

                    default:
                      navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
                      break;
      

    }
  }


  function determineIcon(title){
    switch(title){
      case 'Dashboard':
        return <DashboardIcon sx={{color: active === 'Dashboard' ? "primary.main" : "secondary.400"}} />
      
        case 'Balance Sheet':
          return <DataTable sx={{color: active === 'Balance Sheet' ? 'primary.main' : "secondary.400"}} />

          case 'Cash Flow':
            return <CurrencyExchangeIcon sx={{color: active === 'Cash Flow' ? 'primary.main' : "secondary.400"}} />

            case 'Accounts':
              return <AccountBalanceIcon sx={{color: active === 'Accounts' ? 'primary.main' : "secondary.400"}} />

              case 'Budget Analysis':
                return <PieChartIcon sx={{color: active === 'Budget Analysis' ? 'primary.main' : "secondary.400"}} />

                case 'Customer Support':
                  return <EmailIcon sx={{color: active === 'AccountSettings' ? 'primary.main' : "secondary.400"}} />

                case 'Account Settings':
                  return <SettingsIcon sx={{color: active === 'AccountSettings' ? 'primary.main' : "secondary.400"}} />

                  default:
                    return null

    }
  }

  async function handleLogOut(){
  let res = await logOut();
  if(res){
    navigate('/')
  }
  }




  const mainSideBarContent = <List sx={{height: "100%"}}>
      <StyledListItemButton disableRipple><img src={BE} width={50} alt='Budget Even' /> </StyledListItemButton>
  
      <ScrollBar style={{maxHeight: "calc(100% - 50px)"}}>
        {topMenuList.map((nav, index) => 
          <Grid key={index.toString()} container direction='column' mt={2}>
     
            <StyledListItemButton disableRipple onClick={handleActiveMainMenu(nav)}>

              {determineIcon(nav.title)}
           
              </StyledListItemButton>
        <Typography variant='caption' style={{textAlign:'center',fontWeight:'bold',color:active === nav.title ? theme.palette.primary.dialogBackground : 'grey'}}>{nav.description}</Typography>
          
          </Grid>
          )}

          <Grid container direction='column' mt={2}>
          <StyledListItemButton style={{marginTop:10}} onClick={() => handleLogOut()} disableRipple >
             <LogoutIcon style={{color:"#94A4C4"}} />

            </StyledListItemButton>
            <Typography variant='caption' style={{textAlign:'center',color:'grey',fontWeight:'bold'}}>Log Out</Typography>
            </Grid>
       
       
         
      </ScrollBar>
    </List>






if(downSm){
  return( null)

}

if(!downSm){
  return (
<Fragment>
      <MainMenu>{mainSideBarContent}</MainMenu>
      </Fragment>
  )
}
else {
  return null
}
}



export default DashboardSideBar;