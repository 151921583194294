import { AltDataContext } from 'contexts/AltDataContext'
import React,{useEffect,useContext} from 'react'
import { Backdrop,Grid,useTheme,Typography } from '@mui/material'
import { Ellipsis } from 'react-spinners-css'
import { getFunctions,httpsCallable } from 'firebase/functions'
import { useNavigate,useLocation } from 'react-router'
import { getFirestore,doc,updateDoc,setDoc,collection,where,query,getDocs } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getFastFinAccountsHelper, refreshFinAccountsHelper, uploadAccountsHelper } from 'utils/AccountMethods'
import { getNewTransactionsHelper } from 'utils/TransactionMethods'

const functions = getFunctions()
const db = getFirestore();

export default function FinicityAlreadyAuthWithBudgetLoading() {
    
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const {state,dispatch} = useContext(AltDataContext)

    useEffect(() => {
refreshFinAccounts();
    },[])

    async function refreshFinAccounts(){

       let accounts = await refreshFinAccountsHelper(state.userData.finCustomerId)
   
        //const accounts = await getFastFinAccountsHelper(state.userData.finCustomerId)
        if(accounts.length >= 1){
       
          await uploadAccountsHelper(accounts)
          navigate('/autotransactionscanning')
        }
        else {
          navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
        }
          
    }



    return(
  
        <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
           <Grid container direction='column'  justifyContent='center' alignItems='center'>
             <Grid item>
             <Ellipsis color={theme.palette.primary.main} />
             </Grid>
             <Grid item style={{paddingTop:5}}>
               <Typography style={{color:theme.palette.primary.fiverGrayText,  fontWeight:'bold'}}>Organizing your accounts...</Typography>
             </Grid> 
           </Grid>
          
          
         </Backdrop> 
          )
}
