
import { Ellipsis } from 'react-spinners-css';
import { formatTransactionDate,getTodaysEpoch,getPriorEpochByNumberOfDays } from 'utils/Helpers';
import { getAuth } from 'firebase/auth';
import { getFirestore,doc,getDoc} from 'firebase/firestore';
import { getFunctions,httpsCallable } from 'firebase/functions';
import React,{useEffect, useState} from 'react'
import { Table,TableHead,TableRow,TableCell,TableBody,useTheme,Typography,Backdrop,Grid,useMediaQuery,Box,styled,Card } from '@mui/material'
import {useLocation } from 'react-router';

const db = getFirestore();
const functions = getFunctions();

const TopAreaWrapper = styled(Card)(({
    theme
  }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexWrap: "wrap",
    [theme.breakpoints.down(700)]: {
      "& h2": {
        paddingTop: "1rem"
      },
      "& img": {
        display: "none"
      }
    }
  }));


export default function UpdatedViewAccountTransactions() {

    const theme = useTheme();
    const isDownLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isUpSmall = useMediaQuery(theme.breakpoints.up('sm'))
    const location = useLocation();
  
    const [showLoading,setShowLoading] = useState(true)
    const [transactionArray,setTransactionArray] = useState([])

 

   


    useEffect(() => {
        getLastMonthsTransactions();
    },[])

    function checkScreenSize(){
      let m = 0;
    
    
      if(isDownLarge && isUpSmall){
        m = 60;
      }
    
      if(isDownMedium){
        m = 0;
      }
      return m;
    }

     
      async function getLastMonthsTransactions(){
        let accountId = location.state.accountId
        let uid = getAuth().currentUser.uid
        let docRef = doc(db,'EuklesUsers',uid)
        const finCustomerId = await getDoc(docRef).then(doc => {
            return doc.data().finCustomerId
        }).catch(e => {
            return ''
        })
        
        let todayEpoch = getTodaysEpoch();
        let startDate = getPriorEpochByNumberOfDays(30)
    
        let retrieveFinTransactions = httpsCallable(functions,'getFinTransactionsSingleAccount');
      
  
        todayEpoch = todayEpoch * 1000
        startDate = startDate * 1000
  
  
        let newTransactionArray = await retrieveFinTransactions({customerId: finCustomerId, accountId: accountId, uid: uid, monthStartEpoch: startDate, monthEndEpoch: todayEpoch}).then(function(result){
    
          return result.data.newTransactionArray;
          }).catch(function(error){
      
              return false;
          });
  
          if(newTransactionArray !== false){
            try{
              newTransactionArray[0].sort((a,b) => b.transactionDate - a.transactionDate)
            }catch(e){
              console.log(e)
            }
            setTransactionArray(previousState => [...newTransactionArray[0]])
          }
      
       setShowLoading(false)
    
    
    }
  
   
      

    return (
            
        <Box pt={2} pb={10} style={{marginLeft: checkScreenSize()}}>
        <TopAreaWrapper>
        <Box style={{padding:25}}>
        <img src={location.state.icon} style={{height:40,width:40}}></img>
            </Box>
        </TopAreaWrapper>
     
        
           <div style={{alignSelf:'center', display:'flex',flexDirection:'row',justifyContent:'center'}}>

        
          <Table style={{alignSelf:'center',width: isDownMedium ? '100%' : 'auto',   minWidth: isDownMedium ? 300 : 900, marginTop:25}}>
        <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
          <TableRow>
          <TableCell  style={{color:'white', fontWeight:'bold'}}>Date</TableCell>
          <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Description</TableCell>
          <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Amount</TableCell>
       
          </TableRow>
    
        </TableHead>
        <TableBody style={{backgroundColor:'white', }}>


        {transactionArray.map((item,i) => {
                        return(
                            <TableRow key={i.toString()}>
                           
                                <TableCell  >
                                <Typography style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}}>{formatTransactionDate(item.transactionDate)}</Typography>
                                </TableCell>

                                <TableCell >
                               
                               
                               <Typography style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.categorization.normalizedPayeeName}</Typography>
                         
                         </TableCell>
                             
                                <TableCell >
                               
                               
                                      <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.amount)}</Typography>
                                
                                </TableCell>

                          

                      

                               
                            
                              
                            </TableRow>
                        )
                    })}
        </TableBody>
        </Table>

        </div>





        <div style={{width:'100%', height:200}}>
                  <Typography style={{color:'transparent'}}>Hello</Typography>
                </div>
   
    
              
    
          

                <Backdrop open={showLoading} style={{zIndex:1400}}>
   <Grid container direction='column'  justifyContent='center' alignItems='center'>
     <Grid item>
     <Ellipsis color={theme.palette.primary.main} />
     </Grid>
     <Grid item style={{paddingTop:5}}>
       <Typography style={{color:'white', fontWeight:'bold'}}>Retrieving Transactions...</Typography>
     </Grid>
   </Grid>
  
  
 </Backdrop> 
       
       </Box>
      )
}
