import AppTextField from "components/uicomponents/AppTextField";
import Axios from 'axios';
import { Box, Card, Grid, Button, Divider,  Backdrop, useMediaQuery,Typography,useTheme } from "@mui/material";
import { BudgetContextTwo } from "contexts/BudgetContextTwo";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { doc, getFirestore, updateDoc,getDoc, deleteDoc,collection,getDocs } from "firebase/firestore";
import { Ellipsis} from 'react-spinners-css'
import FlexBetween from "components/uicomponents/FlexBetween";
import { generateRandomString,getCurrentMonthAndYearString } from "utils/Helpers";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { H3,H5 } from "components/uicomponents/Typography";
import { Navigate} from "react-router-dom";
import React,{useState, useContext,useEffect} from 'react'
import toast from "react-hot-toast";
import {  useNavigate, useLocation } from "react-router-dom";
import { upgradeFromSemiAutoToAutoSubscription } from "utils/StripeMethods";
import UpgradeSuccessDialog from "./Dialogs/UpgradeSuccessDialog";



const db = getFirestore();
const functions = getFunctions();

const UpgradeFromManualToAutoPage = () => {

const{userUid, userEmail, updateIsPremiumUser, isAuthenticated} = useContext(BudgetContextTwo)
const theme = useTheme();
const isDownSmall= useMediaQuery(theme.breakpoints.down('sm'));
const elements= useElements();
const location = useLocation();
const navigate = useNavigate();
const stripe = useStripe();



const [address,setAddress] = useState('')
const [addressError,setAddressError] = useState(false)



const [loading, setLoading] = useState(true)
const [loadingMessage,setLoadingMessage] = useState('Please wait...')








const [firstName,setFirstName] = useState('')
const [firstNameError,setFirstNameError] = useState(false)

const [lastName,setLastName] = useState('')
const  [lastNameError,setLastNameError] = useState(false)

const [city,setCity] = useState('')
const [cityError,setCityError] = useState(false)

const [stateTitle,setStateTitle] = useState('')
const [stateTitleError,setStateTitleError] = useState(false)

const [zip,setZip] = useState('')
const [zipError,setZipError] = useState(false)


const [showUpgradeSuccess,setShowUpgradeSuccess] = useState(true)



useEffect(() => {
 
  if(address !== '' && addressError){
    setAddressError(false)
  }
},[address,addressError])

useEffect(() => {
  if(firstName !== '' && firstNameError){
    setFirstNameError(false)
  }
},[firstName,firstNameError])

useEffect(() => {
  if(lastName !== '' && lastNameError){
   setLastNameError(false)
  }
},[lastName,lastNameError])

useEffect(() => {
  if(city !== '' && cityError){
    setCityError(false)
  }
},[city,cityError])

useEffect(() => {
  if(stateTitle !== '' && stateTitleError){
    setStateTitleError(false)
  }
},[stateTitle,stateTitleError])



useEffect(() => {
  if(zip !== '' && zipError){
    setZipError(false)
  }
},[zip,zipError])


useEffect(() => {
    startUpgradeProcess();
},[])



async function startUpgradeProcess(){
  let stepOne = await upgradeFromSemiAutoToAutoSubscription()

  //return {stripeResult: stripeResult,newSubResult: newSubResult,updateResult: updateResult }
  if(stepOne.stripeResult && stepOne.newSubResult){
//getFinUrlForUpgradeFromSemiAutoToAuto()
updateUserSteps();
  }
  else {
    //setLoading(false);
    toast.error('Budget Even has encountered an error')
  }
}

async function updateUserSteps(){
  let docRef = doc(db,'EuklesUsers', userUid);
  await updateDoc(docRef,{isPremiumUser:true,budgetMethod:'Auto',currentStep:'JustUpgraded',
  autoCurrentStep:'UpgradeFromSemiAutoToAutoFirstStep'}).then(res => {
   return true;
 }).catch(err => {
   return false;
 })
 setLoading(false);
 setShowUpgradeSuccess(true)
}

async function deleteCurrentMonthBudget(){
  const uid = getAuth().currentUser.uid;
  let monthYear = getCurrentMonthAndYearString();
  console.log('month and year ', monthYear)
  let collectionRef = collection(db,'EuklesUsersData',uid,monthYear)

  let allDocs = await getDocs(collectionRef).then(querySnapshot => {
    let localArray = []

      querySnapshot.forEach(doc => {
        localArray.push(doc.id)
      })
    
    return localArray
  }).catch(e => {
    return []
  })
  
  console.log('array ', allDocs)

  for(let i = 0; i < allDocs.length; i++){
    let docRef = doc(db,'EuklesUsersData',uid,monthYear,allDocs[i])
    await deleteDoc(docRef).then(res => {
      return true
    }).catch(e => {
      return false
    })
  }
}


async function getFinUrlForUpgradeFromSemiAutoToAuto(){
  const userUidLocal = getAuth().currentUser.uid;
  let docRef = doc(db,'EuklesUsers',userUidLocal)
  let finCustomerId = await getDoc(docRef).then(doc => {
    return doc.data().finCustomerId
  }).catch(e => {
    return ''
  })
  let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
     
  let urlResult = await retrieveUrl({customerId: finCustomerId}).then(function(result){
  
      
      return result;
  }).catch(function(error){
      console.log('error generating fin url');
      return false;
  })
  

  
  if(urlResult.data.theUrl === 'error getting url'){
     //setLoading(false);
      toast.error('Budget Even has encountered an error')
  }
  else {
  //setLoading(false);
    let theUrl = String(urlResult.data.theUrl);

    //betaUploadUserInfo(finCustomerId)

    navigate('/finicityconnect',{state:{url:theUrl,finCustomerId: finCustomerId}})
   
  //  navigate('./upgradefsatap',{state:{comingFrom: location.state.comingFrom,urlToUse: theUrl,finCustomerId:finCustomerId}});



   
 
}
}




















// **********************************LOGIC FOR NEW CARD IF NEEDED***********************************


const contactStripe = async () => {

 // e.preventDefault();
 // setLoading(true);

    
     if(!stripe || !elements){
       return;
     }
     const cardElement = elements.getElement(CardElement);
     const {error, paymentMethod} = await stripe.createPaymentMethod({
       type:'card',
       card: cardElement,
     });

     if(error){
      
      setLoading(false);
   
      toast.error(`There was an error processing your request. ${error.message}  `)
     }
     else {
     
       const paymentMethodId = paymentMethod.id;
       let cusName = firstName + '' + lastName
       //const data = {paymentId: paymentMethodId, email: userEmail, uid: userUid}
       const data = {paymentId: paymentMethodId, email: userEmail, uid: userUid, name:cusName, address: address, city: city, state: stateTitle, zip: zip}
      handleStripeStepTwo(data);
    
     
    
     }
   }


   async function createFinicityCustomer(){
 

    setLoadingMessage('Contacting Finicity...')
    let createFinMethod = httpsCallable(functions,'createFinCustomer');
      
    let userName = generateRandomString(10)
    
    
    let customerId = await createFinMethod({username: userName}).then((res) => {
            
        return res.data.customerId;
    }).catch((err) => {
        
        return false;
    });
    
    
   
    if(customerId !== false){
    

      uploadUserInfo(customerId)
      handleConnectClick(customerId)
    }
    else {
      setLoading(false);
      toast.error('There was an error processing your request.')
    }
    
   
   
    
    } 

    async function createFinicityCustomerAuto(){
 

      //setLoadingMessage('Contacting Finicity...')
      let createFinMethod = httpsCallable(functions,'createFinCustomer');
        
      let userName = generateRandomString(10)
      
      
      let customerId = await createFinMethod({username: userName}).then((res) => {
              
          return res.data.customerId;
      }).catch((err) => {
          
          return false;
      });
      
      
     
      if(customerId !== false){
      
    
        //uploadUserInfo(customerId)
        let uid = getAuth().currentUser.uid;
        let monthYear = getCurrentMonthAndYearString();
        let userDocRef = doc(db,'EuklesUsers',uid)
        await updateDoc(userDocRef,{
          onBoardingSelection: 'Auto',
          budgetMethod: 'Auto',
          isPremiumUser:true,
          finCustomerId:customerId,
          noCashFlowSet: true,
          queryMonth: monthYear,
          subscriptionMethod: 'web'
        })
        handleConnectClick(customerId)
        //getFinUrl(customerId)
      }
      else {
        setLoading(false);
        toast.error('There was an error processing your request.')
      }
      
     
     
      
      } 


    async function handleConnectClick(finCustomerId){
    
      setLoading(true);
      setLoadingMessage('Connecting...')
    
         let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
     
         let urlResult = await retrieveUrl({customerId: finCustomerId}).then(function(result){
         
             
             return result;
         }).catch(function(error){
             console.log('error generating fin url');
             return false;
         })
         
       
         
         if(urlResult.data.theUrl === 'error getting url'){
            setLoading(false);
             toast.error('Budget Even has encountered an error')
         }
         else {
         setLoading(false);
           let theUrl = String(urlResult.data.theUrl);

           //betaUploadUserInfo(finCustomerId)
    
          navigate('../finicity',{state:{comingFrom: location.state.comingFrom,urlToUse: theUrl,finCustomerId:finCustomerId}});
    
    
    
          
        
       }
         
           
         
         
         
     
    }


 
    
   async function handleStripeStepTwo(data){

    //https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomer
     let createCustomerResult = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomer', data).then(res => {
  
       
  
             return res.data;
               
           }).catch(error => {
          
             return error;
             })
      
             if(!createCustomerResult.encounteredError){
              //{location.state.planType === 'Monthly' ? '$4.99' : '$49.99'}
              if(location.state.planType === 'Auto'){
                handleStripeStepThree(createCustomerResult.customerId)
              }
              else {
                //semi
                handleStripeStepThreeAnnual(createCustomerResult.customerId)
              }
           
             }
             else if(createCustomerResult.encounteredError){
              setLoading(false);
              toast.error('There was an error processing your request.')
             }
           
    }
 
    async function handleStripeStepThree(customerId){
      //https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription
      //full auto
   
      setLoading(true)
     const customerData = {customerId: customerId, uid: userUid}
           let stripeSubscription = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription', customerData ).then(res => {
 
            return res.data;
            }).catch(error => {
             console.log('error creating subscription')
              return error;
            })
 
            if(stripeSubscription.encounteredError){
           
              setLoading(false);
              toast.error(`There was an error processing your request. ${stripeSubscription.errorMessage}`)
            }
            else if(!stripeSubscription.encounteredError) {
              
              toast.success('Payment successful!')
          
              updateIsPremiumUser(customerId)
              updateStripeSubscriptionId(stripeSubscription.subscriptionId,'Auto');
              createFinicityCustomerAuto();
            
 
              
            }
 
      
 }




 async function handleStripeStepThreeAnnual(customerId){
    //Semi-Auto
    // https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionAnnualPayment
  
  const customerData = {customerId: customerId, uid: userUid}
        let stripeSubscription = await Axios.post('https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionAnnualPayment', customerData ).then(res => {

         return res.data;
         }).catch(error => {
          console.log('error creating subscription')
           return error;
         })

         if(stripeSubscription.encounteredError){
        
           setLoading(false);
           toast.error(`There was an error processing your request. ${stripeSubscription.errorMessage}`)
         }
         else if(!stripeSubscription.encounteredError) {
           
        
       
         
           updateStripeSubscriptionId(stripeSubscription.subscriptionId,'Semi-Auto');
           createFinicityCustomer();
         

           
         }

   
}

 

async function updateStripeSubscriptionId(subscriptionId,budgetMethod){
  let docRef = doc(db,'EuklesUsers', userUid);
  let update = await updateDoc(docRef,{stripeSubscriptionId: subscriptionId,budgetMethod:budgetMethod,isPremiumUser:true,subscriptionMethod:'web'}).then(res => {
    return true;
  }).catch(err => {
    return false;
  })

  return update;
}

async function uploadUserInfo(finCustomerId){
    
  let docRef = doc(db,'EuklesUsers', userUid);
   await updateDoc(docRef,{isPremiumUser:true,subscriptionMethod:'web',finCustomerId:finCustomerId}).then(res => {
    return true;
  }).catch(err => {
    return false;
  })
}

async function handleContinueToFinicityPage(){
  setLoading(true);
  setShowUpgradeSuccess(false);
  await deleteCurrentMonthBudget();
  getFinUrlForUpgradeFromSemiAutoToAuto()
}






if(isAuthenticated){
  if(loading){
    return (
      <div>
     
      
        
     <Backdrop open={loading} style={{zIndex:1400,backgroundColor:'white'}}>
            <Grid container direction='column'  justifyContent='center' alignItems='center'>
              <Grid item>
              <Ellipsis color={theme.palette.primary.main} />
              </Grid>
         <Grid item style={{paddingTop:5}}>
            <Typography style={{color:theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>{loadingMessage}</Typography>
          </Grid> 
          
            </Grid>
            </Backdrop> 
           
</div>
    )
  }

  if(showUpgradeSuccess){
    return (
      <div>
        <UpgradeSuccessDialog handleContinue={handleContinueToFinicityPage} />
      </div>
    )

  }
  else {

  
  if(isDownSmall){
    return (
      <Box pt={2} pb={4} >
            <Backdrop open={loading} style={{zIndex:1400}}>
            <Grid container direction='column'  justifyContent='center' alignItems='center'>
              <Grid item>
              <Ellipsis color={theme.palette.primary.main} />
              </Grid>
          
            </Grid>
            </Backdrop> 
          
          
         
  
 
      
   
      <Grid container spacing={3}>
      <Grid item lg={8} md={10} sm={12} xs={12}>
    
      
          <Grid container spacing={3}>
            <Grid item xs={7}>
        
                <CardElement />
           
         
               <Grid container spacing={3}>
               <Grid item md={6} xs={12}>
              <AppTextField label="First" onChange={(e) => setFirstName(e.target.value)}
              fullWidth error={firstNameError} helperText='' />
            </Grid>
            <Grid item md={6} xs={12}>
              <AppTextField label="Last" fullWidth onChange={(e) => setLastName(e.target.value)}
              error={lastNameError} />
            </Grid>
            <Grid item md={6} xs={12}>
              <AppTextField label="Address" fullWidth onChange={(e) => setAddress(e.target.value)}  />
            </Grid>
            <Grid item md={6} xs={12}>
              <AppTextField label="City" fullWidth onChange={(e) => setCity(e.target.value)}
               error={cityError}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <AppTextField label="State" fullWidth onChange={(e) => setStateTitle(e.target.value)}
              error={stateTitleError} />
            </Grid>
            <Grid item md={6} xs={12}>
              <AppTextField label="Zip" fullWidth onChange={(e) => setZip(e.target.value)}
              error={zipError}  />
            </Grid>
  
          </Grid>
            </Grid>
      
      
          </Grid>
   
  
      
      </Grid>
  
  
  
  
  
  
      <Grid item lg={4} md={5} sm={12} xs={12}>
      <Card>
    <H3 paddingX={3} paddingY={2}>
      Order Summary
    </H3>
  
    <Box paddingX={3}>
      <FlexBetween my={2.5}>
        <H5>Subtotal</H5>
        <H5>{location.state.planType === 'Auto' ? '$4.99' : '$2.49'}</H5>
      </FlexBetween>
  
    </Box>
  
    <Divider />
  
    <Box paddingX={3}>
      <FlexBetween my={2}>
        <H3>Total</H3>
        <H3 color="primary.main">{location.state.planType === 'Auto' ? '$4.99' : '$2.49'}</H3>
      </FlexBetween>
  
      <Button onClick={(e) => contactStripe(e)} variant="contained" fullWidth>
        Submit
      </Button>
    </Box>
  
    <Box
      sx={{
        textAlign: "center",
        marginTop: 5,
        padding: 0,
      }}
    >
      <img src="/static/illustration/cart-page.svg" alt="" />
    </Box>
  </Card>
      </Grid>
    </Grid>
      

  
      </Box>
    )
  }
  else {
    return (
     <Box></Box>
    )
  }
}
 
}
else {
  return(
    <Navigate to='/' replace={true} />
  )
}


};

export default UpgradeFromManualToAutoPage;
