import Icons from "icons/sidebar";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';




const index = [{
  title: "Dashboard",
  Icon: Icons.DashboardIcon,
  path: '/dashboard/budgethome',
  description:'Budget'

}, 
{
  title: "Balance Sheet",
  Icon: Icons.DataTableIcon,
  path: "/dashboard/balancesheet",
  description:'Net Worth',
},
{
  title: "Accounts",
  Icon: <AccountBalanceIcon />,
  path: "/dashboard/accountshome",
  description:'Accounts'
},
{
  title: "Cash Flow",
  Icon: <AccountBalanceIcon />,
  path: "/dashboard/cashflow",
  description:'Cash Flow',
},
{
  title: "Budget Analysis",  
  Icon: Icons.EcommerceIcon,
  path: "/dashboard/transactions",
  description:'Analysis'
}, 
{
  title: "Customer Support",  
  Icon: Icons.EcommerceIcon,
  path: "",
  description:'Help'
}, 
{
  title: "Account Settings",
  Icon: Icons.AccountSettingsIcon,
  path: "/dashboard/account-settings",
  description:'Settings'
}, 







];
export default index;