import { TableCell,TableRow,Typography } from '@mui/material';
import { AltDataContext } from 'contexts/AltDataContext';
import { formatTransactionDate } from 'utils/Helpers';
import React, { useEffect,useContext,useState } from 'react'
import { retrieveBranding } from 'utils/AccountMethods';

export default function AccountLineItem({item,handleFixInstitution,handleDeleteAccountClick,navigate,theme,position}) {

    const {state,dispatch} = useContext(AltDataContext)
    const [accountLogo,setAccountLogo] = useState('')

    useEffect(() =>{
        getBranding();
    },[])


    function camelCaseForce(title){
      
        let titleArray = title.split(" ")
        for(let i = 0; i < titleArray.length; i++){
          
          titleArray[i] = titleArray[i].toLowerCase()
          titleArray[i] = titleArray[i][0].toUpperCase() + titleArray[i].substring(1)
        }
      
        titleArray = titleArray.join(" ")
        if(titleArray === 'Creditcard'){
          titleArray = 'Credit Card'
        }
        return titleArray
       
      }
    async function getBranding(){
        let checkIndex = state.logoDictionary.findIndex(obj => obj.institutionId === item.institutionId)
        if(checkIndex !== -1){
            if(typeof state.logoDictionary[checkIndex].icon !== 'undefined'){
                if(state.logoDictionary[checkIndex].icon !== null && state.logoDictionary[checkIndex].icon !== 'nil'){
                    setAccountLogo(state.logoDictionary[checkIndex].icon)
                }
            }
        }
        else {
            const branding = await retrieveBranding(item);
        
                if(branding.icon !== 'nil' && branding.icon !== null){
                    setAccountLogo(branding.icon)
                }
            
            let currentArray = state.logoDictionary
            currentArray.push({institutionId:item.institutionId,icon:branding.icon,logo:branding.logo,title:branding.tile})
            dispatch({type:'UPDATE_LOGO_DICTIONARY',payload:currentArray})
        }
    }

    function determineIcon(){
        if(accountLogo === ''){
            return (
                <TableCell></TableCell>
            )
        }
        else {
            return (
                <img src={accountLogo} style={{height:40,width:40}}></img>
            )
        }
    }


  return (
    <TableRow >
        {determineIcon()}
            <TableCell  >
            <Typography  style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14,maxWidth:200, fontWeight:'600'}}>{camelCaseForce(item.accountNickname)}</Typography>
            </TableCell>

<TableCell >
     <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.balance)}</Typography>
</TableCell>

<TableCell >
    <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{formatTransactionDate(item.aggregationSuccessDate)}</Typography>
</TableCell>

<TableCell >
    <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.detailPaymentDueDate === '' ? 'n/a' : formatTransactionDate(item.detailPaymentDueDate)}</Typography>
</TableCell>

<TableCell >
<Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.detailLastPaymentAmount === '' ? 'n/a' :new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.detailLastPaymentAmount)} </Typography>
</TableCell>

<TableCell >
<Typography onClick={() => navigate('/dashboard/accounttransactions',{state:{accountId: item.accountId,icon:item.icon}})} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >View Transactions</Typography>
</TableCell>

<TableCell >
<Typography onClick={() => handleFixInstitution(item)} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >Update Credentials</Typography>
</TableCell>

<TableCell >
<Typography onClick={() => handleDeleteAccountClick(item,position)} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >Remove Account</Typography>
</TableCell>
</TableRow>
  )
}
