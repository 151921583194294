import { getAnalytics,logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore,doc,updateDoc,addDoc,collection } from "firebase/firestore";


export async function updateAnalytics(description){
    const analyticsLocal = getAnalytics();
    
      logEvent(analyticsLocal,description)
    
   
  
  }

  export async function updateStep(value){
    const db = getFirestore();
    let uid = ''
    try{
    uid = getAuth().currentUser.uid
    }catch(e){

    }
   
    let docRef = doc(db,'EuklesUsers',uid)
    await updateDoc(docRef,{currentStep: value})
    
   
  
  }


  export async function updateAutoStep(value){
    const db = getFirestore();
    let uid = ''
    try{
    uid = getAuth().currentUser.uid
    }catch(e){

    }
    let docRef = doc(db,'EuklesUsers',uid)
    await updateDoc(docRef,{autoCurrentStep: value})
    
   
  
  }

  export async function updateOnBoardingStepAndAutoStep(onBoardingStep,autoStep){
    const db = getFirestore();
    let uid = ''
    try{
    uid = getAuth().currentUser.uid
    }catch(e){

    }
    let docRef = doc(db,'EuklesUsers',uid)
    await updateDoc(docRef,{currentStep:onBoardingStep,autoCurrentStep: autoStep})
    
   
  
  }


  export async function uploadError(errorMessage,details){
   
  
  
    const uid =  getAuth().currentUser.uid;
    const db = getFirestore();
    let docRef = collection(db,'UpdatedWebErrors')
    let rep = await addDoc(docRef,{errorMessage:errorMessage,details:details,userUid:uid}).then(res => {
      return true;
    }).catch(e => {
      return false;
    })

  return rep
  }