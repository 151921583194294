import { Box,useTheme,AppBar,Container,useMediaQuery,styled,Typography,Toolbar,Grid,CardContent,Card } from '@mui/material';
import BottomShareCallOut from '../components/BottomShareCallOut';
import BottomSpacer from 'components/uicomponents/BottomSpacer';

import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import React,{useEffect} from 'react'
import ShareButtons from '../components/ShareButtons';
import { Small } from 'components/uicomponents/Typography';
import { useNavigate } from "react-router-dom";
import useWindowSize from 'hooks/useWindowSize';



const Wrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  padding: "0 1.5rem",
  paddingTop: "1rem"
}));


export default function HowToSaveMoney() {

    const theme = useTheme();
    const MenuList = styled("ul")(({theme}) => ({margin: 0,padding: 0,listStyle: "none",[theme.breakpoints.down("sm")]: { "& a": {display: "block",marginTop: "1rem"}}}));
  
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"))
    
    const { height} = useWindowSize()
    const navigate = useNavigate();

    const Wrapper = styled(Box)(() => ({
      backgroundImage: "url('/static/landing-page/blogpagebackground.svg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "repeat",
      position:'absolute',
      top:0,
      right:0,
      width:'100%',
      height:'100%'
    }));

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    function determineMaxWidth(){
      if(isSmall){
        return 500
      }
      else if(downMd){
        return 500
      }
      else if(isLarge){
        return 800
      }
      else {
        return 800
      }
      
    }

    function determineColumnWidth(){
      return 100
      // if(isSmall){
      //   return 500
      // }
      // else if(downMd){
      //   return 500
      // }
      // else if(isLarge){
      //   return 800
      // }
      // else {
      //   return 800
      // }
      
    }

    function todaysDate(){
      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
    
      const formattedDate = `${month}-${day}`;
      return formattedDate
    }
 
  return (


<div>


    <Wrapper>

             <AppBar position="fixed" sx={{boxShadow: 2,minHeight: 64,justifyContent: "center",backgroundColor: "white"}}>
      <Container>
        <Toolbar sx={{justifyContent: "space-between",pl:0,pr:0}}>
        <div onClick={() => navigate('./')} ><img src={BudgetEvenLogo} width={200} alt="Logo" /></div>
    
              {downMd || height < 400 ? null : <MenuList style={{display:'flex', flexDirection: 'row', padding:15}}>
      

              <Typography    onClick={() => navigate('/login')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Login</Typography>
              <Typography    onClick={() => navigate('/register')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Sign up</Typography>
             <Typography  style={{fontWeight:'bold',marginLeft:10, marginRight:10, color: theme.palette.primary.main, cursor:'pointer'}}>How it works</Typography>
          
              <Typography  style={{fontWeight:'bold',marginLeft:10, marginRight:10, color: theme.palette.primary.main, cursor:'pointer'}}>Pricing</Typography>
              <Typography to="Learn" onClick={() => navigate('/blog')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Learn</Typography>
              <Typography to="Free Tools" onClick={() => navigate('/blog')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Free Tools</Typography>
            </MenuList>}
            </Toolbar>
          </Container>
    </AppBar>

    <Grid container justifyContent='center' display='flex' flexDirection='column' alignContent='center' alignItems='center'> 
    <Grid item>
    <Typography variant='h3' style={{textAlign:"center",fontWeight:'bold',color:'#1A77CD',marginTop:100}}>How to Save Money Regularly</Typography>
    </Grid>
  
      <Grid item mt={2} style={{textAlign:'center'}}>
          <Small style={{textAlign:'center'}} justifyContent='center'  mb={3} fontSize={16} fontWeight={600} color="text.disabled">
            Follow a few simple tips to start building up your savings account
          </Small>
      </Grid>


    </Grid>

<Container style={{maxWidth:determineMaxWidth()}}>



        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Tip One: Learn to sacrifice</span>

        </div>
        <div style={{marginTop:25,fontWeight:500}}>
         Saving money is painful.&nbsp;&nbsp;Our brains are wired for immediate gratification.&nbsp;&nbsp;Add in one-click Amazon purchases, instagram product influencers, social media, and 
         the odds quickly stack up against us.&nbsp;&nbsp;
         
        </div> 

        <div style={{marginTop:25,fontWeight:500}}>
          With practice, however, you can begin to stop the emotional train towards immediate gratification by stopping yourself and asking "Do I want this? Or, do I need this?"
          Nine times out of ten, you want it but you don't need it. 
         
        </div> 

        <div style={{marginTop:25,fontWeight:500}}>
        But wait, it's Friday, on Fridays I order my really expensive coffee and I look forward to it all week!  
         
        </div> 



        <div style={{marginTop:25,fontWeight:500}}>
          No matter what item you are looking to purchase, ask yourself, "Well, what would happen if I didn't make this purchase?."  Take a minute to think about it.  
          If the answer doesn't ruin your life in some dramatic way, then you can live without it.  Sacrifice the purchase and budget that amount to go into savings.
         
        </div> 

        <div style={{marginTop:25,fontWeight:500}}>
        Not all sacrifices have to be all-in either.  For example, perhaps you want a new pair of shoes because yours are old and falling apart.  There's one pair that you 
        really want that are $75 but you know you could by another pair that you don't like as much for $40.  Make an emotional sacrifice and buy the $40 pair, and budget the $35 for savings.
        </div> 

        <div style={{marginTop:25,fontWeight:500}}>
          
         
        </div> 


     
     
        
        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Tip Two:<span >&nbsp;Set up Automatic Savings Contributions.</span></span>

        </div>

  
      
        <div style={{marginTop:10,fontWeight:500}}>
            In addition to a checking account, establish a savings account at your bank.  On payday, set up an automatic transfer of 10% of your paycheck to directly to your savings account.  
            By automating your savings contributions, you ensure that money is being saved regularly without relying on willpower or memory, promoting consistent savings habits.

            <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
            Further, by automating savings, you can better manage and plan your budget, knowing that a specific amount is already allocated for savings. The sooner money is transferred into a savings account, the sooner it starts earning interest, contributing to overall financial growth.
        </div> 
            {/* display charts */}


            <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Tip Three: <span>&nbsp;Always use coupons</span></span>

        </div>
        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
           Perhaps an obvious tip, but how often do you use coupons?  Before making a purchase or going to the store, be sure to check a few top coupon websits for deals.  
        </div> 
        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
        Maybe you only get 50 cents off, but guess what?  If you manage to save 50 cents per day, that equates to $15 per month.  $15 per month for 12 months equates to $180 in annual savings  Saving $180 for 10 years, not including interest, equates to $1,800 in savings all from coupons!
        </div> 
      
        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
           Here are a few good ones:
           <div>
              <ul>
                <li><a href='https://www.befrugal.com/' style={{color:'blue'}}>Be Frugal</a></li>
                <li><a href='https://www.bradsdeals.com/coupons' style={{color:'blue'}} >Brads Deals</a></li>
                <li><a href='https://www.coupons.com' style={{color:'blue'}}>Coupons.com</a></li>
              </ul>
           </div>

        </div> 

        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Tip Four: <span>&nbsp;Pay with a credit card and use the points!</span></span>

        </div>

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
           Probably not what you expected to hear am I right?  Well, contrary to Dave Ramsey's opinion, I suggest utilizing credit cards as long as you pay off the balance every month. 
            Do not use credit cards for purchases you will not be able to payoff the following month when the bill comes due.  The interest rates are way too high and carrying large balances can negatively impact your credit score.
        </div> 

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
            That said, if you utilize credit cards appropriately, the rewards offered by companies like Capital One can end up saving you money compared to making cash purchases.
              For a simple example, let's say two people by the exact same $10 lunch every day from Subway.  Person A pays cash every day and Person B uses a credit card that 
              offers 2% back in rewards.  So, every time Person B buys a sandwich, the cost is technially $9.80 since 2% of $10 is 20 cents.  So, would you prefer to pay $10 or $9.80?
        </div> 
        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
            Over time, rewards start to accumulate and can be redemmed thereby saving you money over the long time.  Be sure to research the right credit card for you, and if the issuer
            charges an annual fee, just make sure the reward vs. fee ratio is going to be worth it.  Perhaps I'll explore this in another post soon.  
        </div> 

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
              Also, don't buy Subway everyday...just pack your lunch lol.
        </div> 


      <div style={{marginTop:25,fontWeight:500,fontStyle:'italic'}}>
            -Patrick Lysaght, Founder and Creator of Budget Even
            <div style={{marginTop:10}}>
            <ShareButtons url='https://www.budgeteven.com' title='How To Start a Budget' />
            </div>
          
      </div>
        


                    </div>

         
        
    </Container>
  
    <BottomSpacer />
</Wrapper>

    </div>
  

 
   
  )
}