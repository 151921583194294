// src/ShareButtons.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './ShareButtons.css';
import { useTheme } from '@emotion/react';

const ShareButtons = ({ url, title }) => {
    const theme = useTheme();
  return (

    <div className="share-buttons">
      <a
        href={`mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="share-button"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="share-button"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="share-button"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
    </div>

  );
};

export default ShareButtons;
