import React from 'react'
import { Backdrop, useTheme } from '@mui/material'
import { Ellipsis} from 'react-spinners-css'


export default function LoadingScreen() {

  const theme = useTheme();

  return (
    <Backdrop style={{color:'white', backgroundColor:'white'}} open={true}>   <Ellipsis color={theme.palette.primary.main} /> </Backdrop> 
  )
}
